<template>
  <div class="app-container">
    <el-form :model="queryParams" ref="queryForm" size="small" :inline="true" v-show="showSearch" label-width="68px">
      <el-form-item label="业务名称" prop="name">
        <el-input
            v-model="queryParams.name"
            placeholder="请输入业务名称"
            clearable
            style="width: 240px"
            @keyup.enter.native="handleQuery"/>
      </el-form-item>
      <el-form-item style="float: right;">
        <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
        <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
      </el-form-item>
    </el-form>
    <el-row :gutter="10" class="mb8">
      <el-col :span="1.5">
        <el-button
            type="primary"
            plain
            icon="el-icon-plus"
            size="mini"
            @click="addButton"
        >新增</el-button>
      </el-col>
      <el-button-group class="pull-right">
        <el-tooltip class="item" effect="dark" content="搜索" placement="top">
          <el-button
              type="default"
              size="small"
              icon="el-icon-search"
              @click="showSearch = !showSearch">
          </el-button>
        </el-tooltip>
        <el-tooltip class="item" effect="dark" content="刷新" placement="top">
          <el-button
              type="default"
              size="small"
              icon="el-icon-refresh"
              @click="refreshList">
          </el-button>
        </el-tooltip>
      </el-button-group>
    </el-row>
    <el-table v-loading="loading" :data="tableData">
      <el-table-column label="所属运营商" align="center" key="businessName" prop="businessName" />
      <el-table-column label="业务名称" align="center" key="name" prop="name"  />
      <el-table-column label="业务编码" align="center" key="code" prop="code"  />
      <el-table-column label="创建时间" align="center" key="createTime" prop="createTime"  />
      <el-table-column
          prop="status"
          label="状态">
        <template slot-scope="tableData">
          <el-switch
              v-model="tableData.row.status"
              @change="clickStart(tableData.row.id,tableData.row.status)"
              active-color="#13ce66"
              inactive-color="#ff4949">
          </el-switch>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center" class-name="small-padding fixed-width">
        <template slot-scope="scope">
          <el-button
              size="mini"
              type="text"
              icon="el-icon-edit"
              @click="updateButton(scope.row)"
          >修改</el-button>
        </template>
      </el-table-column>
    </el-table>
    <pagination
        style="float: right;"
        :total="total"
        :page.sync="queryParams.pageNum"
        :limit.sync="queryParams.pageSize"
        @pagination="refreshList"
    />
    <BusinessListForm ref="BusinessListForm" @refreshDataList="refreshList"></BusinessListForm>
  </div>
</template>

<script>
  import {findBusinessListByPage,updateStatus} from "@/api/yd/businessList";
  import BusinessListForm from "./BusinessListForm";

  export default {
    name: "BusinessOperators",
    components: {BusinessListForm},
    data(){
      return{
        total: 0,
        // 选中数组
        ids: [],
        // 遮罩层
        loading: true,
        // 非多个禁用
        tableData: [],
        // 显示搜索条件
        showSearch: true,
        // 查询参数
        queryParams: {
          pageNum: 1,
          pageSize: 10,
          businessName: undefined
        },
      }
    },
    activated(){
      this.refreshList()
    },
    methods: {
      handleQuery() {
        this.refreshList();
      },
      // 重置按钮操作
      resetQuery() {
        this.queryParams.name=undefined
        this.handleQuery();
      },
      refreshList(){
        this.loading = true;
        findBusinessListByPage(this.queryParams).then(resp=>{
          this.tableData = resp.data.list;
          this.total = resp.data.total;
          this.loading = false;
        })
      },
      addButton(){
        this.$refs.BusinessListForm.init('add',null)
      },
      updateButton(row){
        this.$refs.BusinessListForm.init('edit',JSON.parse(JSON.stringify(row)))
      },
      clickStart(id,status){
        let parement={
          id: id,
          status: status
        }
        updateStatus(parement).then(resp=>{
          if (resp.success){
            this.$message({
              message: resp.msg,
              type: 'success'
            });
          }else {
            this.$message.error('修改失败');
          }
        })
      },
    },
    }
</script>

<style>

</style>