<template>
  <div class="app-container">
    <el-form :model="queryParams" ref="queryForm" size="small" :inline="true" v-show="showSearch" label-width="100px">
      <el-form-item label="手机号" prop="phone">
        <el-input
            v-model="queryParams.phone"
            placeholder="请输入手机号"
            clearable
            style="width: 240px"
            @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item style="float: right;">
        <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
        <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
      </el-form-item>
    </el-form>
    <el-row :gutter="10" class="mb8">
      <el-col :span="1.5">
        <el-button
            v-if="hasPermission('sys:black:add')"
            type="primary"
            plain
            icon="el-icon-plus"
            size="mini"
            @click="addButton"
        >新增</el-button>
      </el-col>
      <el-col :span="1.5">
        <el-button
            v-if="hasPermission('sys:black:delete')"
            type="danger"
            plain
            icon="el-icon-delete"
            size="mini"
            @click="handleDelete"
        >删除</el-button>
      </el-col>
      <el-col :span="1.5">
        <el-button  v-if="hasPermission('sys:black:down')" type="default" @click="downloadTpl()" icon="el-icon-document" size="mini">下载模板</el-button>
      </el-col>
      <el-col :span="1.5">
        <el-upload
            v-if="hasPermission('sys:black:upload')"
            class="upload-demo"
            :action=action
            accept=".xls,.xlsx"
            :on-success="uploadSuccess"
            :show-file-list="false">
          <el-button size="mini" type="primary">点击上传</el-button>
        </el-upload>
      </el-col>
    </el-row>
    <el-table v-loading="loading" :data="tableData" @selection-change="handleSelectionChange">
      <el-table-column type="selection" width="50" align="center" />
      <el-table-column label="手机号" align="center" key="phone" prop="phone" />
    </el-table>
    <pagination
        style="float: right;"
        :total="total"
        :page.sync="queryParams.pageNum"
        :limit.sync="queryParams.pageSize"
        @pagination="refreshList"
    />
    <MobileBlackForm ref="MobileBlackForm" @refreshDataList="refreshList"></MobileBlackForm>
  </div>
</template>

<script>

import MobileBlackForm from "./MobileBlackForm.vue";
import {deleteMobileBlack, findMobileBlackList} from "@/api/business/ssjy";
export default {
  name: "MobileBlackList",
  components: {MobileBlackForm},
  data(){
    return{
      action: process.env.VUE_APP_BASE_API+"/ssjy/mobileBlack/mobileBlack/import",
      total: 0,
      // 选中数组
      ids: [],
      // 遮罩层
      loading: true,
      // 非多个禁用
      tableData: [],
      // 显示搜索条件
      showSearch: true,
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        phone: undefined
      },
    }
  },
  activated(){
    if (this.hasPermission('sys:black:list')){
      this.reset()
      this.refreshList()
    }
  },
  methods: {
    addButton(){
      this.$refs.MobileBlackForm.init('add',null)
    },
    // 多选框选中数据
    handleSelectionChange(selection) {

      this.ids = selection.map(item => item.id);
    },
    downloadTpl () {
      this.$downloadGet('/ssjy/mobileBlack/mobileBlack/template')
     // window.location.href = process.env.VUE_APP_BASE_API+`/ssjy/mobileBlack/mobileBlack/template`
    },
    uploadSuccess(res, file){
      if (res.success) {
        this.$message.success({dangerouslyUseHTMLString: true, message: res.msg})
        this.refreshList()
      } else {
        this.$message.error(res.msg)
      }
    },
    handleDelete(){
      deleteMobileBlack(this.ids).then(resp=>{
        if (resp.success){
          this.$message({
            message: resp.msg,
            type: 'success'
          });
          this.refreshList()
        }else{
          this.$message.error(resp.msg);
        }
      })
    },
    reset() {
      this.ids=[],
      this.queryParams={
        pageNum: 1,
        pageSize: 10,
        phone: undefined
      }
    },
    handleQuery() {
      this.refreshList();
    },
    resetQuery() {
      this.reset()
      this.refreshList();
    },
    refreshList(){
      this.loading = true;
      findMobileBlackList(this.queryParams).then(resp=>{
        this.tableData = resp.data.list;
        this.total = resp.data.total;
        this.loading = false;
      })
    },
  },
}
</script>

<style>
.homeHeader{
  background: #4ffcff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;
  box-sizing: border-box;
}
.homeHeader .title{
  font-size: 30px;
  font-family: 微软雅黑;
  color: white;
}
.homeHeader .userInfo{
  cursor: pointer;
}
.el-dropdown-link img{
  width: 48px;
  height: 48px;
  border-radius: 24px;
  margin-left: 8px;
}
.homeWelcome{
  text-align: center;
  font-size: 30px;
  font-family: 微软雅黑;
  color: #409eff;
  padding-top: 50px;
}
.homeRouterView{
  margin-top: 10px;
}

</style>