import request from '@/utils/request'

export function findFusionListByPage(data) {
    return request({
        url: '/fusion/company/company/findListByPage',
        method: 'post',
        data: data
    })
}

export function deleteFusionList(data) {
    return request({
        url: '/fusion/company/company/deleteById',
        method: 'post',
        data: data
    })
}

export function insertFusionList(data) {
    return request({
        url: '/fusion/company/company/create',
        method: 'post',
        data: data
    })
}
export function updateFusionList(data) {
    return request({
        url: '/fusion/company/company/update',
        method: 'post',
        data: data
    })
}




export function findProductListByPage(data) {
    return request({
        url: '/fusion/product/product/findListByPage',
        method: 'post',
        data: data
    })
}

export function deleteProductList(data) {
    return request({
        url: '/fusion/product/product/deleteById',
        method: 'post',
        data: data
    })
}

export function insertProductList(data) {
    return request({
        url: '/fusion/product/product/create',
        method: 'post',
        data: data
    })
}
export function updateProductList(data) {
    return request({
        url: '/fusion/product/product/update',
        method: 'post',
        data: data
    })
}


export function insertAllocationList(data) {
    return request({
        url: '/fusion/productCompany/productCompany/create',
        method: 'post',
        data: data
    })
}


