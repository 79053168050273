<!--    工作流的外置表单的使用方式-->
<template>
  <div>
    <el-form :model="inputForm" ref="inputFormss" v-loading="loading" label-width="120px">
      <el-col :span="12">
        <el-form-item style="width: 100%" label="项目编号" prop="projectNumber">
          <el-input v-model="inputForm.projectNumber" placeholder="请填写项目编号"
          ></el-input>
        </el-form-item>
      </el-col>
    </el-form>
  </div>
</template>

<script>

export default {
  name: 'handle',
  data () {
    return {
      loading: false,
      inputForm: {
        projectNumber: '',
      },
    }
  },
  created() {
    //回显使用
    this.getList()
  },
  //接收父组件传入的业务id
  props: {
    someProp: {
      type: String
    }
  },
  methods: {
    getList(){
      //根据业务id查询表单数据
      let fromDate=this.someProp[0]
      alert(fromDate.businessId)
      this.inputForm.projectNumber="ssssssssss"
    },
    //保存业务数据
    saveForm (callback) {
      callback("8sd56sdsd");
      // this.$refs['inputForm'].validate((valid) => {
      //   if (valid) {
      //     this.$http({
      //       url: `/process-system/projectApproval/save`,
      //       method: 'post',
      //       data: this.inputForm
      //     }).then(({ data }) => {
      //       if (data && data.success) {
      //         callback(data.businessId)
      //       }
      //       loadingObj.close();
      //
      //     })
      //     loadingObj.close();
      //
      //   }
      // })
    }
  },
}
</script>

<style scoped>
</style>
