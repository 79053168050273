<template>
  <div class="app-container">
    <el-form :model="queryParams" ref="queryForm" size="small" :inline="true" v-show="showSearch" label-width="68px">
      <el-form-item label="公司名称" prop="name">
        <el-input
            v-model="queryParams.companyName"
            placeholder="请输入业务名称"
            clearable
            style="width: 240px"
            @keyup.enter.native="handleQuery"/>
      </el-form-item>
      <el-form-item style="float: right;">
        <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
        <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
      </el-form-item>
    </el-form>
    <el-row :gutter="10" class="mb8">
      <el-col :span="1.5">
        <el-button
            type="primary"
            plain
            icon="el-icon-plus"
            size="mini"
            @click="addButton"
        >新增</el-button>
      </el-col>
      <el-col :span="1.5">
        <el-button
            type="danger"
            plain
            icon="el-icon-delete"
            size="mini"
            :disabled="multiple"
            @click="handleDelete"
        >删除</el-button>
      </el-col>
      <el-button-group class="pull-right">
        <el-tooltip class="item" effect="dark" content="搜索" placement="top">
          <el-button
              type="default"
              size="small"
              icon="el-icon-search"
              @click="showSearch = !showSearch">
          </el-button>
        </el-tooltip>
        <el-tooltip class="item" effect="dark" content="刷新" placement="top">
          <el-button
              type="default"
              size="small"
              icon="el-icon-refresh"
              @click="refreshList">
          </el-button>
        </el-tooltip>
      </el-button-group>
    </el-row>
    <el-table v-loading="loading" :data="tableData" @selection-change="handleSelectionChange">
      <el-table-column type="selection" width="55" align="center" />
      <el-table-column label="公司名称" align="center" key="companyName" prop="companyName" />
      <el-table-column label="回调地址" align="center" key="callbackUrl" prop="callbackUrl"  />
      <el-table-column label="公司id" align="center" key="id" prop="id"  />
      <el-table-column label="操作" align="center" class-name="small-padding fixed-width">
        <template slot-scope="scope">
          <el-button
              size="mini"
              type="text"
              icon="el-icon-edit"
              @click="updateButton(scope.row)"
          >修改</el-button>
        </template>
      </el-table-column>
    </el-table>
    <pagination
        style="float: right;"
        :total="total"
        :page.sync="queryParams.pageNum"
        :limit.sync="queryParams.pageSize"
        @pagination="refreshList"
    />
    <CompanyForm ref="CompanyForm" @refreshDataList="refreshList"></CompanyForm>
  </div>
</template>

<script>
import {deleteCompany, findLtCompanyListByPage} from "@/api/lt/lt";
import CompanyForm from "./CompanyForm.vue";

export default {
  name: "CompanyList",
  components: {CompanyForm},
  data() {
    return {
      total: 0,
      // 选中数组
      ids: [],
      // 非多个禁用
      multiple: true,
      tableData: [],
      // 显示搜索条件
      showSearch: true,
      loading: true,
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        companyName: undefined
      },
    }
  },
  activated() {
    this.refreshList()
  },
  methods: {
    updateButton(row){
      this.$refs.CompanyForm.init('edit',row)
    },
    addButton(){
      this.$refs.CompanyForm.init('add',null)
    },
    handleDelete(){
      deleteCompany(this.ids).then(resp=>{
        if (resp.success){
          this.$message({
            message: resp.msg,
            type: 'success'
          });
          this.refreshList()
        }else{
          this.$message.error(resp.msg);
        }
      })
    },
    handleSelectionChange(selection) {
      this.ids = selection.map(item => item.id)
      this.multiple = !selection.length
    },
    handleQuery() {
      this.refreshList();
    },
    // 重置按钮操作
    resetQuery() {
      this.queryParams.companyName=undefined
      this.handleQuery();
    },
    refreshList(){
      this.loading = true;
      findLtCompanyListByPage(this.queryParams).then(resp=>{
        this.tableData = resp.data.list;
        this.total = resp.data.total;
        this.loading = false;
      })
    },
  }
}
</script>

<style>

</style>