import request from '@/utils/request'

export function listOwnProcess(query) {
    return request({
        url: '/activity/workflow/process/ownList',
        method: 'get',
        params: query
    })
}


// 取消申请
export function stopProcess(data) {
    return request({
        url: '/activity/workflow/task/stopProcess',
        method: 'post',
        data: data
    })
}


// 删除流程实例
export function delProcess(ids) {
    return request({
        url: '/activity/workflow/process/instance/' + ids,
        method: 'delete'
    })
}


export function detailProcess(query) {
    return request({
        url: '/activity/workflow/process/detail',
        method: 'get',
        params: query
    })
}