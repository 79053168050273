<template>
  <div class="app-container">
    <el-row :gutter="20">
      <!--部门数据-->
      <el-col :span="4" :xs="24">
        <div class="head-container">
          <el-input
              @keyup.enter.native="getDeptTree"
              v-model="treeQueryParams.departmentName"
              placeholder="请输入部门名称"
              clearable
              size="small"
              prefix-icon="el-icon-search"
              style="margin-bottom: 20px"
          />
        </div>
        <div class="head-container" style = "height:600px;overflow:auto;">
          <el-tree
              v-loading="treeLoading"
              element-loading-spinner="el-icon-loading"
              :data="deptOptions"
              :props="defaultProps"
              :expand-on-click-node="false"
              :filter-node-method="filterNode"
              ref="tree"
              node-key="id"
              default-expand-all
              highlight-current
              @node-click="handleNodeClick"
          />
        </div>
        <el-pagination
            small="true"
            layout="prev, pager, next"
            pager-count="3"
            @current-change="handleCurrentChange"
            :page-size="treeQueryParams.pageSize"
            :total="treeTotal">
        </el-pagination>
      </el-col>
      <!--铃音数据-->
      <el-col :span="20" :xs="24">
        <el-form :model="queryParams" ref="queryForm" size="small" :inline="true" v-show="showSearch" label-width="80px">
          <el-form-item label="铃音名称" prop="billNums">
            <el-input
                v-model="queryParams.ringName"
                placeholder="请输入铃音文件名称"
                clearable
                style="width: 240px"
                @keyup.enter.native="handleQuery"
            />
          </el-form-item>
          <el-form-item style="float: right;">
            <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
            <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
          </el-form-item>
        </el-form>

        <el-row :gutter="10" class="mb8">
          <el-col :span="1.5">
            <el-button
                v-if="hasPermission('sys:ring:upload')"
                type="primary"
                plain
                icon="el-icon-plus"
                size="mini"
                @click="submitRing"
            >上传铃音文件</el-button>
          </el-col>
        </el-row>
        <el-table v-loading="loading" :data="tableData" @selection-change="handleSelectionChange">
          <el-table-column type="selection" width="50" align="center" />
          <el-table-column label="铃音名称" align="center" key="ringName" prop="ringName" />
          <el-table-column label="铃音类型" :formatter="dataFormat" width="80" align="center" key="ringType" prop="ringType"  />
          <el-table-column label="铃音标识" align="center" key="ringId" prop="ringId" />
          <el-table-column label="铃音路径" :show-overflow-tooltip="true" align="center" key="ringFilePath" prop="ringFilePath" />
          <el-table-column label="铃音创建时间" width="160" align="center" key="time" prop="time" />
          <el-table-column label="铃音状态" :formatter="ringFormat" width="80" align="center" key="ringStatus" prop="ringStatus" />
          <el-table-column label="铃音状态描述" :show-overflow-tooltip="true" align="center" key="desc1" prop="desc1" />
          <el-table-column label="操作" align="center" class-name="small-padding fixed-width">
            <template slot-scope="scope">
              <el-button
                  v-if="hasPermission('sys:ring:view')"
                  size="mini"
                  type="text"
                  icon="el-icon-edit"
                  @click="findViwe(scope.row)"
              >查看分发地区</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
    <pagination
        style="float: right;"
        :total="total"
        :page.sync="queryParams.pageNum"
        :limit.sync="queryParams.pageSize"
        @pagination="refreshList"
    />
    <RingtoneForm ref="RingtoneForm" @refreshDataList="refreshList"></RingtoneForm>
    <RingtoneDrawerProvince ref="RingtoneDrawerProvince"></RingtoneDrawerProvince>
  </div>
</template>

<script>
  import RingtoneForm from './RingtoneForm.vue'
  import RingtoneDrawerProvince from './RingtoneDrawerProvince.vue'
  import {findringtoneList, getDeptTreeRequest} from "@/api/business/ssjy";
  export default {
    name: "Ringtone",
    components: {RingtoneDrawerProvince, RingtoneForm},
    data(){
      return{
        // 部门名称
        departmentName: undefined,
        treeLoading: true,
        // 部门树选项
        deptOptions: undefined,
        // 显示搜索条件
        showSearch: true,
        defaultProps: {
          children: "children",
          label: "label"
        },
        // 查询参数
        queryParams: {
          pageNum: 1,
          pageSize: 10,
          ringName: undefined
        },
        // 遮罩层
        loading: true,
        total: 0,
        treeTotal: 0,
        treeTotalValue: true,
        // 非多个禁用
        tableData: [],
        // 选中数组
        ids: [],
        treeQueryParams: {
          pageNum: 1,
          pageSize: 30,
          departmentName: ""
        },
      }
    },
    watch: {
      // 根据名称筛选部门树
      departmentName(val) {
        this.$refs.tree.filter(val);
      }
    },
    activated(){
      if (this.hasPermission('sys:ring:list')){
        this.reset()
        this.getDeptTree()
        this.refreshList()
      }
    },
    methods: {
      reset() {
        this.queryParams= {
          pageNum: 1,
          pageSize: 10,
          billNums: undefined,
          status: undefined,
          ecoperationId: undefined
        }
      },
      dataFormat(row, column, cellValue) {
        const status = row.ringType
        if (status==1){
          return '视频'
        }else if (status==2){
          return '音频'
        }
      },
      ringFormat(row, column, cellValue) {
        const status = row.ringStatus
        if (status=='00'){
          return '已上传'
        }else if (status=='01'){
          return '转码成功审核中'
        }else if (status=='02'){
          return '审核未通过'
        }else if (status=='03'){
          return '审核通过'
        }else if (status=='06'){
          return '分发成功'
        }else if (status=='07'){
          return '删除归档失败'
        }else if (status=='08'){
          return '转码失败'
        }
      },
      getDeptTree() { // 选择牵头部门事件
        this.treeLoading = true
        getDeptTreeRequest(this.treeQueryParams).then(resp=>{
          this.deptOptions = resp.data.treeSelects;
          this.treeTotal = resp.data.treeTotal;
          this.treeLoading = false
        })
      },
      handleCurrentChange(val) {
        console.log(`当前页: ${val}`);
        this.treeQueryParams= {
          pageNum: val,
          pageSize: 30,
          departmentName: ""
        },
            this.treeLoading = true
        getDeptTreeRequest(this.treeQueryParams).then(resp=>{
          this.deptOptions = resp.data.treeSelects;
          this.treeTotal = resp.data.treeTotal;
          this.treeLoading = false
        })
      },
      // 筛选节点
      filterNode(value, data) {

        if (!value) return true;
        return data.label.indexOf(value) !== -1;
      },
      // 节点单击事件
      async handleNodeClick(data) {

        this.queryParams.ecoperationId = data.id;
        //查询用户数据
        await this.refreshList();
      },
      refreshList(){
        this.loading = true;
        findringtoneList(this.queryParams).then(resp=>{
          this.tableData = resp.data.list;
          this.total = resp.data.total;
          this.loading = false;
        })
      },
      handleQuery() {
        this.refreshList();
      },
      resetQuery() {
        this.queryParams= {
          pageNum: 1,
          pageSize: 10,
          billNums: undefined,
          status: undefined,
          ecoperationId: undefined
        },
        this.refreshList();
      },
      handleSelectionChange(selection) {

        this.ids = selection.map(item => item.id);
      },
      submitRing(){
        this.$refs.RingtoneForm.init('add',null)
      },
      findViwe(row){
        this.$refs.RingtoneDrawerProvince.init('view', JSON.parse(JSON.stringify(row)))
      },
    }
  }
</script>

<style>
    .homeHeader{
        background: #4ffcff;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 15px;
        box-sizing: border-box;
    }
    .homeHeader .title{
        font-size: 30px;
        font-family: 微软雅黑;
        color: white;
    }
    .homeHeader .userInfo{
        cursor: pointer;
    }
    .el-dropdown-link img{
        width: 48px;
        height: 48px;
        border-radius: 24px;
        margin-left: 8px;
    }
    .homeWelcome{
        text-align: center;
        font-size: 30px;
        font-family: 微软雅黑;
        color: #409eff;
        padding-top: 50px;
    }
    .homeRouterView{
        margin-top: 10px;
    }

</style>