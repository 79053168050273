<template>
    <div>
        <el-dialog :title="title" :visible.sync="dialogVisible" width="800px" append-to-body>
          <el-form :model="inputForm" :rules="rules" ref="inputForm" :class="method === 'view' ? 'readonly' : ''" :disabled="method === 'view'"  label-width="140px">
            <el-row>
              <el-col :span="24" v-if="inputForm.parentId != 0">
                <el-form-item label="上级集团名称" prop="parentId">
                  <treeselect
                      v-model="inputForm.parentId"
                      :options="deptOptions"
                      :normalizer="normalizer"
                      placeholder="选择上级集团" />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item label="部门名称" prop="departmentName">
                  <el-input v-model="inputForm.departmentName" placeholder="部门名称" />
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="推荐人手机号" prop="referencePhone">
                  <el-input v-model="inputForm.referencePhone" placeholder="推荐人手机号" />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item label="客户经理电话" prop="contactPhone">
                  <el-input v-model="inputForm.contactPhone" placeholder="客户经理电话" />
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button type="primary" @click="submitForm">确 定</el-button>
            <el-button @click="cancel">取 消</el-button>
          </div>
        </el-dialog>
    </div>
</template>

<script>
    import Treeselect from "@riophae/vue-treeselect";
    import "@riophae/vue-treeselect/dist/vue-treeselect.css";
    import {findGroupList, insertGroup, updateGroup} from "@/api/business/ssjy";
    export default {
        name: "DepartmentsFrom",
        data(){
            return {
                //菜单头
                title: "",
                //弹出框显示与隐藏
                dialogVisible: false,
                menuStatus: true,
                method: "",
                inputForm: {},
              // 部门树选项
                deptOptions: [],
                rules: {
                departmentName: [
                  { required: true, message: "部门名称不能为空", trigger: "blur" }
                ],
                referencePhone: [
                  {
                    pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
                    message: "请输入正确的手机号码",
                    trigger: "blur"
                  }
                ],
                contactPhone: [
                  {
                    pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
                    message: "请输入正确的手机号码",
                    trigger: "blur"
                  }
                ],
                adminMsisdn: [
                  {
                    required: true,
                    pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
                    message: "请输入正确的手机号码",
                    trigger: "blur"
                  }
                ]
              }
            }
        },
        components: {
          Treeselect
        },
        methods:{
            init(method, row) {
                this.reset();
                this.method = method;
                if (method === "add") {
                    this.title = `新建部门`;
                } else if (method === "edit") {
                    this.inputForm=row;
                    this.title = "修改部门";
                }
                this.handleSelect();
                this.dialogVisible = true;
            },
          /** 转换部门数据结构 */
          normalizer(node) {
            if (node.children && !node.children.length) {
              delete node.children;
            }
            return {
              id: node.id,
              label: node.departmentName,
              children: node.children
            };
          },
          handleSelect() {
            findGroupList({}).then(resp=>{
              this.deptOptions = this.handleTree(resp.data.list, "id");
            })
          },
          submitForm: function() {
            this.$refs['inputForm'].validate((valid) => {
              if (valid){
                if (this.method=='add'){
                  insertGroup(this.inputForm).then(resp=>{
                    this.cancel()
                    if (resp.success){
                      this.$message({
                        message: resp.msg,
                        type: 'success'
                      });
                      this.$emit('refreshDataList')
                    }else {
                      this.$message.error(resp.msg);
                    }
                  })
                }else {
                  updateGroup(this.inputForm).then(resp=>{
                    this.cancel()
                    if (resp.success){
                      this.$message({
                        message: resp.msg,
                        type: 'success'
                      });
                      this.$emit('refreshDataList')
                    }else {
                      this.$message.error(resp.msg);
                    }
                  })
                }
              }
            })
          },
          // 取消按钮
          cancel() {
            this.dialogVisible = false;
            this.reset();
          },
          reset() {
            this.inputForm = {};
          },
        }
    }
</script>

<style scoped>

</style>