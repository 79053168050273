<template>
  <div class="app-container">
    <el-form :model="queryParams" ref="queryForm" :inline="true" v-show="isSearchCollapse" label-width="68px">
      <el-form-item label="模型标识" prop="modelKey">
        <el-input
            v-model="queryParams.modelKey"
            placeholder="请输入模型标识"
            clearable
            size="small"
            @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="模型名称" prop="modelName">
        <el-input
            v-model="queryParams.modelName"
            placeholder="请输入模型名称"
            clearable
            size="small"
            @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="流程分类" prop="category">
        <el-select v-model="queryParams.category" clearable placeholder="请选择" size="small">
          <el-option
              v-for="item in categoryOptions"
              :key="item.categoryId"
              :label="item.categoryName"
              :value="item.code">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
        <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
      </el-form-item>
    </el-form>
    <el-row>
      <el-button  type="primary" size="small" icon="el-icon-plus" @click="add()">新建</el-button>
      <el-button type="danger"   size="small" icon="el-icon-delete" @click="handleDelete">删除</el-button>
      <el-button-group class="pull-right">
        <el-tooltip class="item" effect="dark" content="搜索" placement="top">
          <el-button
              type="default"
              size="small"
              icon="el-icon-search"
              @click="isSearchCollapse = !isSearchCollapse">
          </el-button>
        </el-tooltip>
        <el-tooltip class="item" effect="dark" content="刷新" placement="top">
          <el-button
              type="default"
              size="small"
              icon="el-icon-refresh"
              @click="refreshList">
          </el-button>
        </el-tooltip>
      </el-button-group>
    </el-row>
    <el-table v-loading="loading" fit :data="dataList" @selection-change="selectionChangeHandle">
      <el-table-column type="selection" width="55" align="center" />
      <el-table-column label="模型标识" align="center" prop="modelKey" :show-overflow-tooltip="true" />
      <el-table-column label="模型名称" align="center" :show-overflow-tooltip="true">
        <template slot-scope="scope">
          <el-button type="text" @click="handleProcessView(scope.row)">
            <span>{{ scope.row.modelName }}</span>
          </el-button>
        </template>
      </el-table-column>
      <el-table-column label="流程分类" align="center" prop="categoryName" :formatter="categoryFormat" />
      <el-table-column label="模型版本" align="center">
        <template slot-scope="scope">
          <el-tag size="medium" >v{{ scope.row.version }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="描述" align="center" prop="description" :show-overflow-tooltip="true" />
      <el-table-column label="创建时间" align="center" prop="createTime" width="180"/>
      <el-table-column label="操作" align="center" class-name="small-padding fixed-width">
        <template slot-scope="scope">
          <el-button
              size="mini"
              type="text"
              icon="el-icon-edit"
              @click="handleUpdate(scope.row)"
          >修改</el-button>
          <el-button
              type="text"
              size="mini"
              icon="el-icon-brush"
              @click="handleDesigner(scope.row)"
          >设计</el-button>
          <el-button
              type="text"
              size="mini"
              icon="el-icon-video-play"
              @click.native="handleDeploy(scope.row)"
          >部署</el-button>
          <el-dropdown size="mini">
            <el-button size="mini" type="text" icon="el-icon-d-arrow-right">更多</el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                  icon="el-icon-view"
                  @click.native="handleProcessView(scope.row)"
              >流程图</el-dropdown-item>
              <el-dropdown-item
                  icon="el-icon-price-tag"
                  @click.native="handleHistory(scope.row)"
              >历史</el-dropdown-item>
              <el-dropdown-item
                  icon="el-icon-delete"
                  @click.native="handleDelete(scope.row)"
              >删除</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </el-table-column>
    </el-table>
    <pagination
        style="float: right;"
        :total="total"
        :page.sync="queryParams.pageNum"
        :limit.sync="queryParams.pageSize"
        @pagination="refreshList"
    />
    <flow-form ref="flowForm" @refreshList="refreshList"></flow-form>
    <design-form ref="designForm" @refreshList="refreshList"></design-form>
    <!-- 流程图 -->
    <el-dialog :title="processView.title" :visible.sync="processView.open" width="70%" append-to-body>
      <process-viewer :key="`designer-${processView.index}`" :xml="processView.xmlData" :style="{height: '400px'}" />
    </el-dialog>

    <el-dialog title="模型历史" :visible.sync="history.open" width="70%" >
      <el-table v-loading="history.loading" fit :data="historyList" @selection-change="handleSelectionChange">
        <el-table-column type="selection" width="55" align="center" />
        <el-table-column label="模型标识" align="center" prop="modelKey" :show-overflow-tooltip="true" />
        <el-table-column label="模型名称" align="center" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <el-button type="text" @click="handleProcessView(scope.row)">
              <span>{{ scope.row.modelName }}</span>
            </el-button>
          </template>
        </el-table-column>
        <el-table-column label="流程分类" align="center" prop="categoryName" :formatter="categoryFormat" />
        <el-table-column label="模型版本" align="center">
          <template slot-scope="scope">
            <el-tag size="medium" >v{{ scope.row.version }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="描述" align="center" prop="description" :show-overflow-tooltip="true" />
        <el-table-column label="创建时间" align="center" prop="createTime" width="180"/>
        <el-table-column label="操作" align="center" class-name="small-padding fixed-width">
          <template slot-scope="scope">
            <el-button
                type="text"
                size="mini"
                icon="el-icon-video-play"
                @click.native="handleDeploy(scope.row)"
            >部署</el-button>
            <el-button
                type="text"
                size="mini"
                icon="el-icon-star-off"
                @click.native="handleLatest(scope.row)"
            >设为最新</el-button>
          </template>
        </el-table-column>
      </el-table>

      <pagination
          v-show="historyTotal > 0"
          :total="historyTotal"
          :page.sync="queryHistoryParams.pageNum"
          :limit.sync="queryHistoryParams.pageSize"
          @pagination="getHistoryList"
      />
    </el-dialog>
  </div>
</template>

<script>
import {delModel,getBpmnXml,historyModel,deployModel,latestModel} from "@/api/activityWorkFlow/activity/model";
import {listAllCategory} from "@/api/activityWorkFlow/activity/category";
import FlowForm from "./FlowForm";
import DesignForm from "./DesignForm";
import ProcessViewer from '@/components/ProcessViewer'
import {getWorkFlowList} from "@/api/activityWorkFlow/activity/flow";
export default {
  name: "FlowList",
  components: {FlowForm,DesignForm,ProcessViewer},
  data() {
    return {
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      categoryOptions: [],
      dataListSelections: [],
      dataList: [],
      total: 0,
      loading: false,
      searchDates: '',
      selectCategoryName: '',
      isSearchCollapse: false,
      historyList: [],
      history: {
        open: false,
        loading: false
      },
      processView: {
        title: '',
        open: false,
        index: undefined,
        xmlData:"",
      },
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        filterText:undefined,
        modelKey:undefined,
        modelName:undefined,
        category:undefined,
      },
      historyTotal: 0,
      queryHistoryParams: {
        pageNum: 1,
        pageSize: 10,
        modelKey: null
      }
    }
  },
  activated(){
    this.getCategoryList();
    this.refreshList()
  },
  methods: {
    /** 查询流程分类列表 */
    getCategoryList() {
      listAllCategory().then(response => {
        this.categoryOptions = response.data
      })
    },
    handleSelectionChange(selection) {
      this.ids = selection.map(item => item.modelId)
      this.single = selection.length !== 1
      this.multiple = !selection.length
    },
    selectionChangeHandle(selection) {
      this.ids = selection.map(item => item.modelId)
      this.single = selection.length !== 1
      this.multiple = !selection.length
    },
    handleQuery() {
      this.refreshList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.$refs.queryForm.resetFields()
      this.handleQuery();
    },
    add () {
      this.$refs.flowForm.init('add', '')
    },
    getHistoryList() {
      this.history.loading = true;
      historyModel(this.queryHistoryParams).then(response => {
        this.historyTotal = response.data.total;
        this.historyList = response.data.list;
        this.history.loading = false;
      })
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      const modelIds = row.modelId || this.ids;
      this.$modal.confirm('是否确认删除模型编号为"' + modelIds + '"的数据项？').then(() => {

        this.loading = true;
        return delModel(modelIds);
      }).then(() => {
        this.loading = false;
        this.refreshList();
        this.$modal.msgSuccess("删除成功");
      }).finally(() => {
        this.loading = false;
      });
    },
    refreshList () {
      this.loading = true;
      getWorkFlowList(this.queryParams).then(resp=>{
        this.dataList = resp.data.list;
        this.total = resp.data.total;
        this.loading = false;
      })
    },
    handleDesigner(row) {
      this.$refs.designForm.init("form",row)
    },
    /** 部署流程 */
    handleDeploy(row) {
      this.loading = true;
      deployModel({
        modelId: row.modelId
      }).then(response => {
        this.$modal.msgSuccess(response.msg);
        // let obj = { name: 'Deploy', path: '/workflow/deploy' }
        // return this.$store.dispatch('tagsView/delCachedView', obj).then(() => {
        //   this.$router.push(obj);
        // });
      }).finally(() => {
        this.loading = false;
      })
    },
    /** 设为最新版 */
    handleLatest(row) {
      this.$modal.confirm('是否确认将此版本设为最新？').then(() => {
        this.history.loading = true;
        latestModel({
          modelId: row.modelId
        }).then(response => {
          this.history.open = false;
          this.refreshList();
          this.$modal.msgSuccess(response.msg);
        }).finally(() => {
          this.history.loading = false;
        })
      })
    },
    handleUpdate(row) {
      this.$refs.flowForm.init('edit', JSON.parse(JSON.stringify(row)))
    },
    handleProcessView(row) {
      let modelId = row.modelId;
      this.processView.title = "流程图";
      this.processView.index = modelId;
      // 发送请求，获取xml
      getBpmnXml(modelId).then(response => {
        this.processView.xmlData = response.data;
      })
      this.processView.open = true;
    },
    handleHistory(row) {
      this.history.open = true;
      this.queryHistoryParams.modelKey = row.modelKey;
      this.getHistoryList();
    },
    categoryFormat(row, column) {
      return this.categoryOptions.find(k => k.code === row.category)?.categoryName ?? '';
    },
  }
}
</script>

<style scoped>
.table {
  margin-top: 10px;
}
</style>