import request from '@/utils/request'



export function findLtCompanyAll() {
  return request({
    url: '/lt/company/company/findAll',
    method: 'post'
  })
}
export function findLtCompanyListByPage(data) {
  return request({
    url: '/lt/company/company/findListByPage',
    method: 'post',
    data: data
  })
}


export function insertCompany(data) {
  return request({
    url: '/lt/company/company/create',
    method: 'post',
    data: data
  })
}


export function updateCompany(data) {
  return request({
    url: '/lt/company/company/update',
    method: 'post',
    data: data
  })
}


export function deleteCompany(data) {
  return request({
    url: '/lt/company/company/deleteById',
    method: 'post',
    data: data
  })
}


export function findLtProductListByPage(data) {
  return request({
    url: '/lt/product/product/findListByPage',
    method: 'post',
    data: data
  })
}

export function insertProduct(data) {
  return request({
    url: '/lt/product/product/create',
    method: 'post',
    data: data
  })
}


export function updateProduct(data) {
  return request({
    url: '/lt/product/product/update',
    method: 'post',
    data: data
  })
}

export function deleteProduct(data) {
  return request({
    url: '/lt/product/product/deleteById',
    method: 'post',
    data: data
  })
}


export function findLtOrderListByPage(data) {
  return request({
    url: '/lt/order/order/findListByPage',
    method: 'post',
    data: data
  })
}