<template>
    <div>
        <el-dialog :title="title" :visible.sync="dialogVisible" width="800px" append-to-body>
          <el-table v-loading="loading" row-key="id" :data="tableData" ref="multipleTable" @selection-change="handleSelectionChange" label-width="140px">
            <el-table-column :reserve-selection="true" type="selection" align="center" width="55"/>
            <el-table-column label="产品名称" align="center" key="productName" prop="productName" />
            <el-table-column label="产品编码" align="center" key="productCode" prop="productCode"  />
          </el-table>
          <pagination
              style="float: left;"
              :total="total"
              :page.sync="queryParams.pageNum"
              :limit.sync="queryParams.pageSize"
              @pagination="refreshList"
          />
          <div slot="footer" class="dialog-footer">
            <el-button type="primary" @click="submitForm">确 定</el-button>
            <el-button @click="cancel">取 消</el-button>
          </div>
        </el-dialog>
    </div>
</template>

<script>
import {findProductListByPage, insertAllocationList} from "@/api/yd/fusion";
import de from "element-ui/src/locale/lang/de";
    export default {
        name: "AddProductForm",
        data(){
          return {
            total: 0,
              //菜单头
            title: "",
            loading: true,
            //弹出框显示与隐藏
            dialogVisible: false,
            method: "",
            // 非多个禁用
            tableData: [],
            list: [],
            queryParams: {
              pageNum: 1,
              pageSize: 10,
              companyId: undefined
            },
            productCompany:{
              productId: [],
              companyId: undefined
            }
          }
        },
        methods:{
          init(method, row) {
            this.method = method;
            this.dialogVisible = true;
            this.queryParams.companyId=row.id
            this.productCompany.companyId=row.id
            this.refreshList();
            if (method === "add") {
              this.title = `添加产品`;
            }
          },
          handleSelectionChange(val) {
            debugger
            this.productCompany.productId=val.map(item => item.id);
            debugger
          },
          refreshList(){
            this.loading = true;
            findProductListByPage(this.queryParams).then(resp=>{
              this.tableData = resp.data.list;
              this.total = resp.data.total;
              //调用接口成功后执行
              this.$refs.multipleTable.clearSelection()
              this.tableData.forEach(row=>{
                if(row.checked){
                  this.$refs.multipleTable.toggleRowSelection(row,true);
                }
              })
              this.loading = false;
            })
          },
          submitForm: function() {
            insertAllocationList(this.productCompany).then(resp => {
              this.cancel()
            })
          },
          // 取消按钮
          cancel() {
            this.dialogVisible = false;
          },
        }
    }
</script>

<style scoped>

</style>