<template>
    <div>
        <el-dialog :title="title" :visible.sync="dialogVisible" width="30%">
            <el-form :model="inputForm" :rules="rules" ref="inputForm" :class="method === 'view' ? 'readonly' : ''" :disabled="method === 'view'" label-width="120px">
                <el-row :gutter="30">
                    <el-col :span="30">
                        <el-form-item label="菜单类型" prop="type">
                            <el-radio-group v-model="type">
                                <el-radio :label="1">目录</el-radio>
                                <el-radio :label="2">菜单</el-radio>
                                <el-radio :label="3">按钮</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                    <el-col :span="20">
                        <el-form-item label="菜单名称:" prop="name">
                            <el-input
                                    v-model="inputForm.name"
                                    placeholder="请输入菜单名称"
                            ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="20">
                        <el-form-item label="所属菜单:" prop="pid">
                          <treeselect
                              v-model="inputForm.pid"
                              :options="list"
                              selectable: false
                              @select="selectNode"
                              :normalizer="normalizer"
                              placeholder="选择菜单"/>
                        </el-form-item>
                    </el-col>
                    <el-col :span="20">
                        <el-form-item label="接口URL:" prop="url">
                            <el-input
                                    v-model="inputForm.url"
                                    placeholder="请输入接口URL,目录默认/home"
                            ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="20">
                        <el-form-item label="组件:" prop="component">
                            <el-input
                                    v-model="inputForm.component"
                                    placeholder="请输入组件名称，目录默认Home"
                            ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="20">
                        <el-form-item label="图标:" prop="iconCls">
                            <el-input
                                    v-model="inputForm.iconCls"
                                    placeholder="请输入图标"
                            ></el-input>
                        </el-form-item>
                    </el-col>
                    <!--根据菜单类型动态显示-->
                    <div  v-if="type!=1">
                        <el-col :span="20">
                            <el-form-item label="授权标识:" prop="perms">
                                <el-input
                                        v-model="inputForm.perms"
                                        placeholder="请输入授权标识,如果 sys:user:list"
                                ></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="20">
                            <el-form-item label="请求方式:" prop="method">
                                <el-input
                                        v-model="inputForm.method"
                                        placeholder="请输入请求方式，如 GET、POST"
                                ></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="20">
                            <el-form-item label="按钮标识:" prop="code">
                                <el-input
                                        v-model="inputForm.code"
                                        placeholder="请输入前后端分离按钮控制标识,如果 btn-permission-list"
                                ></el-input>
                            </el-form-item>
                        </el-col>
                    </div>
                    <!--动态显示结束-->
                    <el-col :span="20">
                        <el-form-item label="排序码:" prop="orderNum">
                            <el-input
                                    v-model="inputForm.orderNum"
                                    placeholder="请输入排序码"
                            ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="20">
                        <el-form-item label="状态:" prop="menuStatus">
                            <el-switch
                                    v-model="menuStatus"
                                    active-color="#13ce66"
                                    inactive-color="#ff4949">
                            </el-switch>
                        </el-form-item>
                    </el-col>

                </el-row>
            </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="save">确 定</el-button>
        </span>
        </el-dialog>
    </div>
</template>

<script>
  import Treeselect from "@riophae/vue-treeselect";
  import "@riophae/vue-treeselect/dist/vue-treeselect.css";
  import {createMenu, getMenuTree} from "@/api/system/menu";
    export default {
        name: "MenuForm",
        data(){
            return {
                // rediro单选
                label: "",
                //菜单头
                title: "",
                //弹出框显示与隐藏
                dialogVisible: false,
                method: "",
                type: 1,  //菜单类型
                menuStatus: true,
                inputForm: {},
                rules: {},
                //输入框
                isClearable:true,      // 可清空（可选）
                isAccordion:true,      // 可收起（可选）
                list: [],
                value: true,
            }
        },
        components: {
          Treeselect
        },
        methods:{
            init(method, row) {
                this.method = method;
                this.inputForm.id = row.id;
                if (method === "add") {
                    this.title = `新建菜单`;
                    this.inputForm={}
                    this.type = 1
                } else if (method === "edit") {
                    this.title = "修改菜单";
                }
                this.dialogVisible = true;
                this.showMenu();
                if (method === "edit") {
                    // 修改或者查看
                  this.inputForm = row
                  //解决unknown问题
                  if (this.inputForm.pid===""||this.inputForm.pid===undefined){
                    this.inputForm.pid = null
                  }
                  if ("目录"==this.inputForm.type){
                    this.type=1
                  }else if("菜单"==this.inputForm.type){
                    this.type=2
                  }else if("按钮"==this.inputForm.type){
                    this.type=3
                  }
                }
            },
            showMenu(){
              getMenuTree().then(resp=>{
                this.list = resp.data
              })
            },
            // 取值
            getValue(value){
                this.inputForm.pid = value
            },
            save(){
              if (this.menuStatus!=true ){
                  this.inputForm.status=0
              } else {
                  this.inputForm.status=1
              }
              this.inputForm.type=this.type
              createMenu(this.inputForm).then(resp=>{
                if (resp.success){
                  this.$message({
                    message: resp.msg,
                    type: 'success'
                  });
                  this.dialogVisible = false
                  this.$emit('refreshDataList')
                }else {
                  this.$message.error(resp.msg);
                }
              })
            },
            selectNode(node){
              this.inputForm.pId=node.id
              this.inputForm.pName=node.name
            },
            normalizer(node) {
              if (node.children && !node.children.length) {
                delete node.children;
              }
              return {
                id: node.id,
                label: node.name,
                children: node.children,
              };
            },
        }
    }
</script>

<style>

</style>