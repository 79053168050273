<template>
    <div>
        <el-dialog :title="title" :visible.sync="visible" width="30%" append-to-body @close="cancel()">
            <el-form ref="form" :model="form" :rules="rules" label-width="80px">
              <el-form-item label="模型标识" prop="modelKey">
                <el-input v-model="form.modelKey" clearable disabled />
              </el-form-item>
              <el-form-item label="模型名称" prop="modelName">
                <el-input v-model="form.modelName" clearable :disabled="form.modelId !== undefined" />
              </el-form-item>
              <el-form-item label="流程分类" prop="category">
                <el-select v-model="form.category" placeholder="请选择" clearable style="width:100%">
                  <el-option v-for="item in categoryOptions" :key="item.categoryId" :label="item.categoryName" :value="item.code" />
                </el-select>
              </el-form-item>
              <el-form-item label="描述" prop="description">
                <el-input v-model="form.description" type="textarea" placeholder="请输入内容" maxlength="200" show-word-limit/>
              </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
              <el-button type="primary" @click="submitForm">确 定</el-button>
              <el-button @click="cancel()">取 消</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
  import {listAllCategory} from "@/api/activityWorkFlow/activity/category";
  import {saveWork, updateWork} from "@/api/activityWorkFlow/activity/flow";

  export default {
    name: "DesignForm",
    data(){
        return {
          //菜单头
          title: "",
          //弹出框显示与隐藏
          visible: false,
          form: {},
          categoryOptions: [],
          rules: {
            modelKey: [
              { required: true, message: "模型标识不能为空", trigger: "blur" }
            ],
            modelName: [
              { required: true, message: "模型名称不能为空", trigger: "blur" }
            ],
            category: [
              { required: true, message: "请选择类型", trigger: "change" }
            ],
          },
        }
    },
    components: {

    },
    methods:{
      init(method, row) {
        this.visible = true;
        if (method === "add") {
          this.title = "新增流程模型";
          this.getCategoryList();
          const dateTime = new Date().getTime();
          this.form = {
            modelKey: `Process_${dateTime}`,
            modelName: `业务流程_${dateTime}`
          }
        } else if (method === "edit") {
          this.title = "修改流程模型";
          this.form = {
            modelId: row.modelId,
            modelKey: row.modelKey,
            modelName: row.modelName,
            category: row.category,
            description: row.description
          };
        }
      },
      reset() {
        this.form = {
          modelId: undefined,
          modelKey: undefined,
          modelName: undefined,
          category: undefined,
          description: undefined
        };
      },
      /** 查询流程分类列表 */
      getCategoryList() {
        listAllCategory().then(response => {
          this.categoryOptions = response.data
        })
      },
      submitForm() {
        this.$refs["form"].validate(valid => {
          if (valid) {
            if (this.form.modelId !== undefined) {
              updateWork(this.form).then(resp => {
                if (resp.success) {
                  this.$message({
                    message: resp.msg,
                    type: 'success'
                  });
                  this.cancel()
                  this.$emit('refreshList')
                } else {
                  this.$message.error(resp.msg);
                }
              })
            } else {
              saveWork(this.form).then(resp => {
                if (resp.success) {
                  this.$message({
                    message: resp.msg,
                    type: 'success'
                  });
                  this.cancel()
                  this.$emit('refreshList')
                } else {
                  this.$message.error(resp.msg);
                }
              })
            }
          }
        });
      },
      cancel() {
        this.reset();
        // 关闭dialog
        this.visible = false
      },
    }
  }
</script>

<style scoped>

</style>