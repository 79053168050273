import request from '@/utils/request'


export function getSsjyToken(data) {
  return request({
    url: '/ssjy/loginToken/token/getToken',
    method: 'post',
    data: data
  })
}
export function ringtoneSettings(data) {
  return request({
    url: '/ssjy/ringtone/operation/ringtoneSettings',
    method: 'post',
    data: data
  })
}

export function ringtoneSettingsView(data) {
  return request({
    url: '/ssjy/ringtone/operation/ringtoneSettingsView',
    method: 'post',
    data: data
  })
}
export function findringtoneList(data) {
  return request({
    url: '/ssjy/ringtone/operation/findListByPage',
    method: 'post',
    data: data
  })
}
export function findGroupList(data) {
  return request({
    url: '/ssjy/departments/group/findList',
    method: 'post',
    data: data
  })
}
export function insertGroup(data) {
  return request({
    url: '/ssjy/departments/group/insertDept',
    method: 'post',
    data: data
  })
}

export function updateGroup(data) {
  return request({
    url: '/ssjy/departments/group/updateDept',
    method: 'post',
    data: data
  })
}

export function deleteGroup(data) {
  return request({
    url: '/ssjy/departments/group/deleteDept',
    method: 'post',
    data: data
  })
}
export function startOrCloseRingtone(params) {
  return request({
    url: '/ssjy/ringtone/operation/startOrCloseRingtone',
    method: 'post',
    params: params
  })
}
export function deleteGroupUser(data) {
  return request({
    url: '/ssjy/user/group/deleteGroupUser',
    method: 'post',
    data: data
  })
}


export function getDeptTreeRequest(params) {
  return request({
    url: '/ssjy/departments/deptTree',
    method: 'get',
    params: params
  })
}

export function findGroupListByPage(data) {
  return request({
    url: '/ssjy/user/group/findListByPage',
    method: 'post',
    data: data
  })
}

export function insertGroupUser(data) {
  return request({
    url: '/ssjy/user/group/insertGroupUser',
    method: 'post',
    data: data
  })
}

export function deleteLink(data) {
  return request({
    url: '/ssjy/api/link/deleteLink',
    method: 'post',
    data: data
  })
}

export function findLinkListByPage(data) {
  return request({
    url: '/ssjy/api/link/findListByPage',
    method: 'post',
    data: data
  })
}
export function insertLink(data) {
  return request({
    url: '/ssjy/api/link/insertlink',
    method: 'post',
    data: data
  })
}
export function findBusinessLogList(data) {
  return request({
    url: '/ssjy/log/businessLog/findListByPage',
    method: 'post',
    data: data
  })
}


export function deleteBusinessLog(data) {
  return request({
    url: '/ssjy/log/businessLog/deleteById/',
    method: 'post',
    data: data
  })
}

export function createMobileBlack(data) {
  return request({
    url: '/ssjy/mobileBlack/mobileBlack/create',
    method: 'post',
    data: data
  })
}

export function deleteMobileBlack(data) {
  return request({
    url: '/ssjy/mobileBlack/mobileBlack/deleteById',
    method: 'post',
    data: data
  })
}

export function findMobileBlackList(data) {
  return request({
    url: '/ssjy/mobileBlack/mobileBlack/findListByPage',
    method: 'post',
    data: data
  })
}

export function findRelation(data) {
  return request({
    url: '/ssjy/ringtone/operation/findRelation',
    method: 'post',
    data: data
  })
}


export function deleteCorporate(data) {
  return request({
    url: '/ssjy/corporate/corporate/deleteCorporate',
    method: 'post',
    data: data
  })
}


export function findCorporateList(data) {
  return request({
    url: '/ssjy/corporate/corporate/findList',
    method: 'post',
    data: data
  })
}


export function tripartiteUser(data) {
  return request({
    url: '/ssjy/user/tripartite/tripartiteUser',
    method: 'post',
    data: data
  })
}


export function insertCorporate(data) {
  return request({
    url: '/ssjy/corporate/corporate/insertCorporate',
    method: 'post',
    data: data
  })
}


export function updateCorporate(data) {
  return request({
    url: '/ssjy/corporate/corporate/updateCorporate',
    method: 'post',
    data: data
  })
}

export function uploadFile(data) {
  return request({
    url: '/ssjy/ringtone/operation/upload',
    headers: {'Content-Type': 'multipart/form-data'},
    method: 'post',
    data: data
  })
}


export function findAiList(data) {
  return request({
    url: '/ssjy/ai/findByPage',
    method: 'post',
    data: data
  })
}


export function findCount(data) {
  return request({
    url: '/ssjy/statistics/find/count',
    method: 'post',
    data: data
  })
}

export function findCorporate() {
  return request({
    url: '/ssjy/statistics/corporate/findAll',
    method: 'get'
  })
}


export function findUnsubscribeRate(params) {
  return request({
    url: '/ssjy/statistics/find/findUnsubscribeRate',
    method: 'post',
    params: params
  })
}












