import request from '@/utils/request'

export function findBusinessListByPage(data) {
    return request({
        url: '/yd/businessList/findListByPage',
        method: 'post',
        data: data
    })
}

export function insertBusinessList(data) {
    return request({
        url: '/yd/businessList/create',
        method: 'post',
        data: data
    })
}
export function updateBusinessList(data) {
    return request({
        url: '/yd/businessList/update',
        method: 'post',
        data: data
    })
}

export function updateStatus(data) {
    return request({
        url: '/yd/businessList/updateStatus',
        method: 'post',
        data: data
    })
}
