import request from '@/utils/request'

export function findChannelListByPage(data) {
  return request({
    url: '/yd/channel/channelList/findListByPage',
    method: 'post',
    data: data
  })
}

export function insertChannel(data) {
  return request({
    url: '/yd/channel/channelList/create',
    method: 'post',
    data: data
  })
}

export function updateChannel(data) {
  return request({
    url: '/yd/channel/channelList/update',
    method: 'post',
    data: data
  })
}
export function updateStatus(data) {
  return request({
    url: '/yd/channel/channelList/updateStatus',
    method: 'post',
    data: data
  })
}
export function deleteChannel(data) {
  return request({
    url: '/yd/channel/channelList/deleteById/',
    method: 'post',
    data: data
  })
}