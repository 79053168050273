<template>
  <div class="app-container">
    <el-form :model="queryParams" ref="queryForm" size="small" :inline="true" v-show="showSearch" label-width="68px">
      <el-form-item label="产品名称" prop="name">
        <el-input
            v-model="queryParams.productName"
            placeholder="请输入产品名称"
            clearable
            style="width: 240px"
            @keyup.enter.native="handleQuery"/>
      </el-form-item>
      <el-form-item style="float: right;">
        <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
        <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
      </el-form-item>
    </el-form>
    <el-row :gutter="10" class="mb8">
      <el-col :span="1.5">
        <el-button
            type="primary"
            plain
            icon="el-icon-plus"
            size="mini"
            @click="addButton"
        >新增</el-button>
      </el-col>
      <el-col :span="1.5">
        <el-button
            type="danger"
            plain
            icon="el-icon-delete"
            size="mini"
            :disabled="multiple"
            @click="deleteButton"
        >删除</el-button>
      </el-col>
      <el-button-group class="pull-right">
        <el-tooltip class="item" effect="dark" content="搜索" placement="top">
          <el-button
              type="default"
              size="small"
              icon="el-icon-search"
              @click="showSearch = !showSearch">
          </el-button>
        </el-tooltip>
        <el-tooltip class="item" effect="dark" content="刷新" placement="top">
          <el-button
              type="default"
              size="small"
              icon="el-icon-refresh"
              @click="refreshList">
          </el-button>
        </el-tooltip>
      </el-button-group>
    </el-row>
    <el-table v-loading="loading" :data="tableData" @selection-change="handleSelectionChange">
      <el-table-column type="selection" align="center" width="55"/>
      <el-table-column label="产品名称" align="center" key="productName" prop="productName" />
      <el-table-column label="产品编码" align="center" key="productCode" prop="productCode"  />
      <el-table-column label="渠道编码(需要我方分配)" align="center" key="channelNo" prop="channelNo"  />
      <el-table-column label="固定参数" align="center" key="methodInfo" prop="methodInfo"  />
      <el-table-column label="apiInfo产品码表值" align="center" key="apiInfo" prop="apiInfo"  />
      <el-table-column label="操作" align="center" class-name="small-padding fixed-width">
        <template slot-scope="scope">
          <el-button
              size="mini"
              type="text"
              icon="el-icon-edit"
              @click="updateButton(scope.row)"
          >修改</el-button>
          <el-button
              size="mini"
              type="text"
              icon="el-icon-delete"
              @click="deleteButton(scope.row)"
          >删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <pagination
        style="float: right;"
        :total="total"
        :page.sync="queryParams.pageNum"
        :limit.sync="queryParams.pageSize"
        @pagination="refreshList"
    />
    <ProductForm ref="ProductForm" @refreshDataList="refreshList"></ProductForm>
  </div>
</template>

<script>
import ProductForm from "./ProductForm.vue";
import { deleteProductList, findProductListByPage} from "@/api/yd/fusion";

export default {
  name: "BusinessOperators",
  components: {ProductForm},
  data(){
    return{
      total: 0,
      // 遮罩层
      loading: true,
      // 非多个禁用
      tableData: [],
      // 显示搜索条件
      showSearch: true,
      // 非多个禁用
      multiple: true,
      multipleSelection: [],
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        productName: undefined
      },
    }
  },
  activated(){
    this.refreshList()
  },
  methods: {
    handleQuery() {
      this.refreshList();
    },
    // 重置按钮操作
    resetQuery() {
      this.queryParams.productName=undefined
      this.handleQuery();
    },
    refreshList(){
      this.loading = true;
      findProductListByPage(this.queryParams).then(resp=>{
        this.tableData = resp.data.list;
        this.total = resp.data.total;
        this.loading = false;
      })
    },
    addButton(){
      this.$refs.ProductForm.init('add',null)
    },
    updateButton(row){
      this.$refs.ProductForm.init('edit',JSON.parse(JSON.stringify(row)))
    },
    handleSelectionChange(val) {
      this.multipleSelection = val.map(item => item.id);
      this.multiple = !val.length
    },
    deleteButton(row){
      let ids = []
      if (row.id!=undefined && row.id!=''){
        ids.push(row.id)
      }else {
        ids=this.multipleSelection
      }
      deleteProductList(ids).then(resp=>{
        if (resp.success){
          this.$message({
            message: resp.msg,
            type: 'success'
          });
          this.refreshList()
        }
      })
    },
  },
}
</script>

<style>

</style>