import request from '@/utils/request'
// 查询流程部署列表
export function listDeploy(query) {
    return request({
        url: '/activity/workflow/deploy/findListByPage',
        method: 'post',
        data: query
    })
}

export function listPublish(query) {
    return request({
        url: '/activity/workflow/deploy/publishList',
        method: 'post',
        data: query
    })
}
export function getBpmnXml(definitionId) {
    return request({
        url: '/activity/workflow/deploy/bpmnXml/' + definitionId,
        method: 'get'
    })
}

// 修改流程状态
export function changeState(params) {
    return request({
        url: '/activity/workflow/deploy/changeState',
        method: 'put',
        params: params
    })
}

// 删除流程部署
export function delDeploy(deployIds) {
    return request({
        url: '/activity/workflow/deploy/' + deployIds,
        method: 'delete'
    })
}