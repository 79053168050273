<template>
    <div>
        <el-row class="top">
            <el-button v-if="hasPermission('sys:dept:add')" size="small" type="primary" @click="addButton">添加</el-button>
        </el-row>
        <template>
            <el-table
                    :data="tableData"
                    v-loading="loading"
                    style="width: 100%;margin-bottom: 20px;"
                    row-key="id"
                    :tree-props="{children: 'children', hasChildren: 'hasChildren'}">
                <el-table-column
                        prop="deptNo"
                        sortable
                        width="180"
                        label="部门编码">
                </el-table-column>
                <el-table-column
                        prop="name"
                        label="部门名称">
                </el-table-column>
                <el-table-column
                        prop="pname"
                        :show-overflow-tooltip="true"
                        label="上级部门名称">
                </el-table-column>
                <el-table-column
                        prop="relationCode"
                        :show-overflow-tooltip="true"
                        label="层级关系编码">
                </el-table-column>
                <el-table-column
                        prop="managerName"
                        :show-overflow-tooltip="true"
                        label="部门经理">
                </el-table-column>
                <el-table-column
                        prop="phone"
                        label="部门经理联系电话">
                </el-table-column>
                <el-table-column
                        prop="createTime"
                        width="180"
                        label="创建时间">
                </el-table-column>
                <el-table-column label="操作" width="240">
                    <template slot-scope="scope">
                        <el-button v-if="hasPermission('sys:dept:update')" size="mini" @click="handleEdit(scope.row)">编辑</el-button>
                        <el-button v-if="hasPermission('sys:dept:delete')" size="mini" type="danger" @click="handleDelete(scope.row)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </template>
        <!-- 弹窗, 新增 / 修改 -->
        <DeptForm ref="DeptForm" @refreshDataList="refreshList"></DeptForm>
    </div>
</template>

<script>
  import DeptForm from './DeptForm'
  import {deleteDept, getDeptList} from "@/api/system/dept";
  export default {
    name: "Dept",
    data() {
      return {
        loading: true,
        tableData: [],
        orderBy: 'create_time desc'
      }
    },
    components: {
        DeptForm
    },
    activated(){
      if (this.hasPermission('sys:dept:list')) {
        this.refreshList()
      }
    },
    methods: {
      //新增角色
      addButton(){
          this.$refs.DeptForm.init('add', '')
      },
      //编辑
      handleEdit(row){
          this.$refs.DeptForm.init('edit', row)
      },
      //删除
      handleDelete(row){
        let ids = []
        ids.push(row.id)
        deleteDept(ids).then(resp=>{
          if (resp.success){
            this.$message({
              message: resp.msg,
              type: 'success'
            });
            this.refreshList()
          }
        })
      },
      //获取列表数据
      refreshList(){
        this.loading = true;
        //递归构造菜单列表树
        getDeptList().then(resp=>{
          let parent = [];
          var _self = this;
          //构建树结构数据
          resp.data.forEach(function(data) {
            if (data.pid==""||data.pid==undefined){
              data.pName = '顶级部门'
              data.children = _self.$options.methods.getChildrenss(data.id,resp.data);
              parent.push(data)
            }
          })
          this.tableData=parent
          this.loading = false;
        })
      },
      getChildrenss(pid,dataList){
          var _self = this;
          let children = [];
          dataList.forEach(function(data) {
              if (pid === data.pid){
                  data.children = _self.getChildrenss(data.id,dataList);
                  children.push(data)
              }
          })
          return children
      },
    }
  }
</script>

<style scoped>

</style>