<template>
    <div>
        <el-dialog :title="title" :visible.sync="dialogVisible" width="800px" append-to-body>
          <el-form :model="inputForm" :rules="rules" ref="inputForm" :class="method === 'view' ? 'readonly' : ''" :disabled="method === 'view'"  label-width="140px">
            <el-row>
              <el-col :span="24">
                <el-form-item label="上级集团名称" prop="id">
                  <treeselect
                      v-model="inputForm.ecoperationId"
                      :options="deptOptions"
                      :normalizer="normalizer"
                      placeholder="选择上级集团" />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <el-form-item label="链接地址" prop="linkUrl">
                  <el-input v-model="inputForm.linkUrl" placeholder="链接地址" />
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button type="primary" @click="submitForm">确 定</el-button>
            <el-button @click="cancel">取 消</el-button>
          </div>
        </el-dialog>
    </div>
</template>

<script>
    import Treeselect from "@riophae/vue-treeselect";
    import "@riophae/vue-treeselect/dist/vue-treeselect.css";
    import {findGroupList, insertLink} from "@/api/business/ssjy";
    export default {
        name: "LinkFrom",
        data(){
            return {
                //菜单头
                title: "",
                //弹出框显示与隐藏
                dialogVisible: false,
                menuStatus: true,
                method: "",
                inputForm: {},
              // 部门树选项
                deptOptions: [],
                rules: {
                  linkUrl:[{ required: true, message: "链接地址不能为空", trigger: "blur" }],
                  parentId: [{ required: true, message: "上级集团名称不能为空", trigger: "blur" }]
                }
            }
        },
        components: {
          Treeselect
        },
        methods:{
            init(method, row) {
                this.reset();
                this.method = method;
                if (method === "add") {
                    this.title = `新建链接`;
                }
                this.handleSelect();
                this.dialogVisible = true;
            },
          /** 转换部门数据结构 */
          normalizer(node) {
            if (node.children && !node.children.length) {
              delete node.children;
            }
            return {
              id: node.id,
              label: node.departmentName,
              children: node.children
            };
          },
          handleSelect() {
            findGroupList({}).then(resp=>{
              this.deptOptions = this.handleTree(resp.data.list, "id");
            })
          },
          submitForm: function() {
            this.$refs['inputForm'].validate((valid) => {
              if (valid){
                if (this.method=='add'){
                  insertLink(this.inputForm).then(resp=>{
                    this.cancel()
                    if (resp.success){
                      this.$message({
                        message: resp.msg,
                        type: 'success'
                      });
                      this.$emit('refreshDataList')
                    }else {
                      this.$message.error(resp.msg);
                    }
                  })
                }
              }
            })
          },
          // 取消按钮
          cancel() {
            this.dialogVisible = false;
            this.reset();
          },
          reset() {
            this.inputForm = {};
          },
        }
    }
</script>

<style scoped>

</style>