<template>
  <el-drawer :title="title" :visible.sync="drawer" direction="rtl" size="40%">
    <el-table  v-loading="loading" :data="tableData">
      <el-table-column label="分发省份名称" align="center" key="provinceName" prop="provinceName" />
      <el-table-column label="分发状态" :formatter="resultCode" align="center" key="deployRslt" prop="deployRslt"/>
    </el-table>
    <pagination
        style="float: right;"
        :total="total"
        :page.sync="queryParams.pageNum"
        :limit.sync="queryParams.pageSize"
        @pagination="showView"
    />
  </el-drawer>
</template>

<script>
import {findRelation} from "@/api/business/ssjy";

export default {
    name: "RingtoneDrawer",
    data(){
      return {
        tableData: [],
        ringIds: [],
        drawer: false,
        title:"",
        total: 0,
        // 遮罩层
        loading: true,
        queryParams: {
          pageNum: 1,
          pageSize: 10,
          ringId:undefined,
        },
      }
    },
    methods:{
      resultCode(row, column, cellValue) {
        const status = row.deployRslt
        if (status == '1') {
          return '失败'
        } else {
          return '成功'
        }
      },
      init(method, row) {
        this.method = method;
        if (method === "view"){
          this.title = `查看分发省份`;
          this.queryParams.ringId=row.ringId
          this.showView()
        }
        this.drawer = true;
      },
      showView(){
        this.loading = true;
        findRelation(this.queryParams).then(resp=>{
          if (resp.success){
            this.tableData = resp.data.list;
            this.total = resp.data.total;
            this.loading = false;
          }else{
            this.$message({
              message: resp.msg,
              type: 'error'
            });
            this.tableData = [];
            this.total = 0;
            this.loading = false;
          }

        })
      },
    }
}
</script>

<style scoped>

</style>