<template>
    <div>
        <el-dialog :title="title" :visible.sync="dialogVisible">
          <el-form :model="inputForm" :rules="rules" ref="inputForm" :class="method === 'view' ? 'readonly' : ''" :disabled="method === 'view'" label-width="120px">
              <el-row :gutter="15">
                  <el-col :span="12">
                    <el-form-item prop="clientId" label="客户端id" :rules="[{required: true, message:'客户端id不能为空', trigger:'blur'}]">
                          <el-input v-model="inputForm.clientId" maxlength="50" placeholder=""></el-input>
                      </el-form-item>
                  </el-col>
                  <el-col :span="12">
                      <el-form-item label="资源id" prop="resourceIds" :rules="[{required: true, message:'资源id不能为空', trigger:'blur'}]">
                          <el-input v-model="inputForm.resourceIds" maxlength="50" placeholder=""></el-input>
                      </el-form-item>
                  </el-col>
                  <el-col :span="12">
                      <el-form-item label="密钥" prop="clientSecret" :rules="[{required: true, message:'密钥不能为空', trigger:'blur'}]">
                          <el-input v-model="inputForm.clientSecret" maxlength="50" placeholder=""></el-input>
                      </el-form-item>
                  </el-col>
                  <el-col :span="12">
                      <el-form-item label="授权范围" prop="scope" :rules="[{required: true, message:'授权范围不能为空', trigger:'blur'}]">
                          <el-input v-model="inputForm.scope" maxlength="50" placeholder=""></el-input>
                      </el-form-item>
                  </el-col>
                  <el-col :span="12">
                      <el-form-item label="授权类型" prop="authorizedGrantTypes" :rules="[{required: true, message:'授权类型不能为空', trigger:'blur'}]">
                          <el-input v-model="inputForm.authorizedGrantTypes" maxlength="50" placeholder=""></el-input>
                      </el-form-item>
                  </el-col>
                  <el-col :span="12">
                      <el-form-item label="重定向地址" prop="webServerRedirectUri" :rules="[{required: true, message:'重定向地址不能为空', trigger:'blur'}]">
                          <el-input v-model="inputForm.webServerRedirectUri" maxlength="50" placeholder=""></el-input>
                      </el-form-item>
                  </el-col>
                  <el-col :span="12">
                      <el-form-item label="token时间" prop="accessTokenValidity" :rules="[{required: true, message:'token时间不能为空', trigger:'blur'}]">
                          <el-input v-model="inputForm.accessTokenValidity" maxlength="50" placeholder="" ></el-input>
                      </el-form-item>
                  </el-col>
                <el-col :span="12">
                  <el-form-item label="刷新token时间" prop="refreshTokenValidity" :rules="[{required: true, message:'刷新token时间不能为空', trigger:'blur'}]">
                    <el-input v-model="inputForm.refreshTokenValidity" maxlength="50" placeholder=""></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="状态:" prop="autoapprove">
                    <el-switch
                        v-model="inputForm.autoapprove"
                        active-color="#13ce66"
                        inactive-color="#ff4949">
                    </el-switch>
                  </el-form-item>
                </el-col>
              </el-row>
          </el-form>
          <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="save">确 定</el-button>
        </span>
        </el-dialog>
    </div>
</template>

<script>
import {saveClient, updateClient} from "@/api/system/client";

export default {
  name: "ClientForm",
  data(){
    return {
      //菜单头
      title: "",
      //弹出框显示与隐藏
      dialogVisible: false,
      method: "",
      inputForm: {},
      rules: {},
      list: [],
    }
  },
  methods:{
    init(method, row) {
      this.method = method;
      this.reset();
      if (method === "add") {
        this.title = `新建客户端`;
      } else if (method === "edit") {
        this.title = "修改客户端";
        this.inputForm = row
        if (this.inputForm.autoapprove === 'true'){
          this.inputForm.autoapprove=true
        }else {
          this.inputForm.autoapprove=false
        }
      }
      this.dialogVisible = true;
    },
    reset() {
      this.inputForm={};
      this.inputForm.autoapprove=true

    },
    save(){
        this.$refs['inputForm'].validate((valid) => {
          if (valid){
              if (this.method=='add'){
                saveClient(this.inputForm).then(resp=>{
                  if (resp.success){
                    this.dialogVisible = false
                    this.$message({
                      message: resp.msg,
                      type: 'success'
                    });
                    this.$emit('refreshDataList')
                  }else {
                    this.$message.error(resp.msg);
                  }
                })
              }else {
                updateClient(this.inputForm).then(resp=>{
                  this.dialogVisible = false
                  if (resp.success){
                    this.$message({
                      message: resp.msg,
                      type: 'success'
                    });
                    this.$emit('refreshDataList')
                  }else {
                    this.$message.error(resp.msg);
                  }
                })
              }
          }
        })
    }
  }
}
</script>

<style scoped>

</style>