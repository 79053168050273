<template>
    <div>
        <el-dialog :title="title" :visible.sync="dialogVisible" width="800px" :before-close="closeDialog" append-to-body>
          <el-form :model="inputForm" :rules="rules" ref="inputForm"
                   method="post"
                   enctype="Mutilpart/form-data"   label-width="140px">
            <el-row>
              <el-col :span="12">
                <el-form-item label="部门名称" prop="ecoperationId">
                  <treeselect
                      v-model="inputForm.ecoperationId"
                      :options="deptOptions"
                      :normalizer="normalizer"
                      placeholder="选择部门" />
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="铃音名称" prop="ringName">
                  <el-input v-model="inputForm.ringName" placeholder="请输入铃音名称" />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item label="文件类型" prop="ringType">
                  <el-select v-model="inputForm.ringType" placeholder="请选择文件类型">
                    <el-option
                        v-for="dict in typeOptions"
                        :key="dict.value"
                        :label="dict.label"
                        :value="dict.value"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12" v-if="show">
                <el-form-item label="是否提取" prop="extractAudio">
                  <el-select v-model="inputForm.extractAudio" placeholder="是否提取" clearable>
                    <el-option
                        v-for="dict in extractAudio"
                        :key="dict.value"
                        :label="dict.label"
                        :value="dict.value"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item label="铃音文件" prop="ringFile" >
                  <el-upload
                      class="upload-demo"
                      ref="upload"
                      action="/"
                      :limit="1"
                      accept=".png,.jpg"
                      :on-remove="ringRemove"
                      :on-change="ringUploadFile"
                      :auto-upload="false"
                      :file-list="inputForm.ringFile"
                      list-type="text"
                      :show-file-list="true">
                    <el-button slot="trigger" size="small" type="primary">上传文件</el-button>
                  </el-upload>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="版权声明文件" prop="copyrightFile" >
                  <el-upload
                      class="upload-demo"
                      ref="upload1"
                      action="/"
                      :limit="1"
                      accept=".png,.jpg"
                      :on-remove="copyURemove"
                      :on-change="copyUploadFile"
                      :auto-upload="false"
                      :file-list="inputForm.copyrightFile"
                      list-type="text"
                      :show-file-list="true">
                    <el-button slot="trigger" size="small" type="primary">上传文件</el-button>
                  </el-upload>
                </el-form-item>
              </el-col>
            </el-row>

          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button type="primary"  @click="submitForm">确 定</el-button>
            <el-button @click="cancel">取 消</el-button>
          </div>
        </el-dialog>
    </div>
</template>

<script>
    import Treeselect from "@riophae/vue-treeselect";
    import "@riophae/vue-treeselect/dist/vue-treeselect.css";
    import el from "element-ui/src/locale/lang/el";
    import {findGroupList, uploadFile} from "@/api/business/ssjy";
    export default {
      name: "RingtoneForm",
      data(){
        return {
          typeOptions: [{
            value: '1',
            label: '视频'
          },{
            value: '2',
            label: '音频'
          }],
          extractAudio: [{
            value: '1',
            label: '提取'
          },{
            value: '0',
            label: '不提取'
          }],
          show: false,
            //菜单头
          title: "",
          //弹出框显示与隐藏
          dialogVisible: false,
          method: "",
          inputForm: {},
          deptOptions: [],
          rules: {
            ecoperationId:[
              { required: true, message: "部门不能为空", trigger: "blur" }
            ],
            ringType:[
              { required: true, message: "铃音类型不能为空", trigger: "blur" }
            ],
            ringName:[
              { required: true, message: "铃音名称不能为空", trigger: "blur" }
            ]
          },
        }
      },
      components: {
        Treeselect
      },
      watch: {
        "inputForm.ringType": {
          handler(newVal, oldVal) {
            if (newVal==1){
              this.show=true
            }else{
              this.show=false
              this.inputForm.extractAudio=undefined
            }
          }
        }
      },
      methods:{
        init(method, row) {
          this.method = method;
          if (method === "add") {
              this.title = `上传铃音`;
          }
          this.reset();
          this.handleSelect();
          this.dialogVisible = true;
        },
        handleSelect() { // 选择牵头部门事件
          findGroupList({}).then(resp=>{
            this.deptOptions = this.handleTree(resp.data.list, "id");
          })
        },
        normalizer(node) {
          if (node.children && !node.children.length) {
            delete node.children;
          }
          return {
            id: node.id,
            label: node.departmentName,
            children: node.children
          };
        },
        // 上传文件时的改变时的方法
        ringUploadFile(file,fileList) {
          this.inputForm.ringFile = file.raw // 拿到文件的信息
        },
        copyUploadFile(file,fileList) {
          this.inputForm.copyrightFile = file.raw // 拿到文件的信息
        },
        ringRemove(file, fileList){
          this.inputForm.ringFile = undefined // 拿到文件的信息
        },
        copyURemove(file, fileList){
          this.inputForm.copyrightFile = undefined // 拿到文件的信息
        },
        submitForm() {

          this.$refs['inputForm'].validate((valid) => {
            if (valid){
              if (this.method=='add') {
                if (undefined===this.inputForm.copyrightFile||""===this.inputForm.copyrightFile){
                  this.$message({
                    message: "版权文件不能为空",
                    type: 'error'
                  });
                  return;
                }
                if (undefined===this.inputForm.ringFile||""===this.inputForm.ringFile){
                  this.$message({
                    message: "铃音文件不能为空",
                    type: 'error'
                  });
                  return;
                }
                let formdata = new FormData()
                formdata.append("ringFile", this.inputForm.ringFile)
                formdata.append("ecoperationId", this.inputForm.ecoperationId)
                formdata.append("copyrightFile", this.inputForm.copyrightFile)
                formdata.append('ringName', this.inputForm.ringName)
                formdata.append('ringType', this.inputForm.ringType)
                if(this.inputForm.extractAudio == undefined){
                  formdata.append('extractAudio', "0")
                }else {
                  formdata.append('extractAudio', this.inputForm.extractAudio)
                }
                uploadFile(formdata).then(resp=>{
                  if (resp.success){
                    this.$message({
                      message: resp.msg,
                      type: 'success'
                    });
                    this.dialogVisible = false;
                    this.$emit('refreshDataList')
                  }else {
                    this.$message.error(resp.msg);
                  }
                  /*
                  this.tableData = resp.data.list;
                  this.total = resp.data.total;
                  this.dialogVisible = false;
                  this.$emit('refreshDataList')*/
                })
              }
            }
          })
        },
        // 取消按钮
        cancel() {
          this.dialogVisible = false;
          this.reset();
          this.$refs.upload.clearFiles()
          this.$refs.upload1.clearFiles()
        },
        reset() {
          this.inputForm = {};
        },
        //关闭页面调用函数
        closeDialog(){
          this.cancel()
        }
      }
    }
</script>

<style scoped>

</style>