<template>
  <div class="main-box">
    <input data-v-1f83f806="" type="tel" id="inputPhone" placeholder="请输入你的手机号" class="van-field__control">
    <input data-v-1f83f806="" type="tel" id="inputPhone" placeholder="请输入验证码" class="van-field__control">
<!--    <el-form :model="inputForm" :rules="rules" ref="inputForm" label-width="100%">
      <el-row>
        <el-col :span="12">
          <el-input v-model="inputForm.phone" placeholder="请输入手机号" />
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-input v-model="inputForm.code" placeholder="请输入验证码"/>
        </el-col>
        <el-col :span="12">
          <el-button  placeholder="获取验证码"/>
        </el-col>
      </el-row>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button type="primary" @click="submitForm">登录并办理</el-button>
    </div>-->
  </div>
</template>

<script>

import {getSsjyToken} from "@/api/business/ssjy";

export default {
  name: "VideoRingtone",
  data(){
    return {
      inputForm: {},
      rules: {
        code:[
          { required: true, message: "验证码不能为空", trigger: "blur" }
        ],
        phone: [
          {
            pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
            message: "请输入正确的手机号码",
            trigger: "blur"
          }
        ]
      }
    }
  },
  methods:{
    youCallbackName(resource) {

    },
    submitForm() {
      getSsjyToken(this.inputForm).then(resp=>{
        if (resp.success){
          var data ={
            youCallbackName :"youCallbackName",
            channelCode :"014X0GP",
            token :resp.token,
            productId: "62500188909",
            addDepartment: "1"
          }
          var a = companyVrbtOrder_centrality(data)
          const path = window.location.href;
          const parts = path.split('?');
          const id = parts[parts.length-1];
        }else {
          this.$message({
            message: resp.msg,
            type: 'success'
          });
        }
      })
    },
  }
}
</script>

<style>

</style>