<template>
    <div>
        <el-row class="top">
            <el-button v-if="hasPermission('sys:role:add')" size="small" type="primary" @click="addButton">添加</el-button>
        </el-row>
        <template>
            <el-table
                v-loading="loading" :data="tableData" style="width: 100%">
                <el-table-column
                        prop="name"
                        label="角色名称">
                </el-table-column>
                <el-table-column
                          prop="updateTime"
                        label="更新时间">
                </el-table-column>
                <el-table-column
                        prop="description"
                        label="描述">
                </el-table-column>
                <el-table-column
                        prop="status"
                        label="状态">
                    <template slot-scope="tableData">
                        <!--@change="clickStart(tableData.row.id,tableData.row.status)"-->
                        <el-switch
                            v-if="hasPermission('sys:role:status')"
                            v-model="tableData.row.status"
                            @change="clickStart(tableData.row.id,tableData.row.status)"
                            active-color="#13ce66"
                            inactive-color="#ff4949">
                        </el-switch>
                    </template>
                </el-table-column>
                <el-table-column label="操作" width="300">
                    <template slot-scope="scope">
                        <el-button v-if="hasPermission('sys:role:addRolePermission')" size="mini" @click="addRole(scope.row)">添加赋予菜单</el-button>
                        <el-button v-if="hasPermission('sys:role:update')" size="mini" @click="handleEdit(scope.row)">编辑</el-button>
                        <el-button v-if="hasPermission('sys:role:delete')" size="mini" type="danger" @click="handleDelete(scope.row)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div class="block">
                <el-pagination
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :current-page="pageNo"
                        :page-sizes="[5, 10, 15, 20]"
                        :page-size="pageSize"
                        :total="total"
                        background
                        layout="total, sizes, prev, pager, next, jumper">
                </el-pagination>
            </div>
        </template>
        <!-- 弹窗, 新增 / 修改 -->
        <RoleForm ref="RoleForm" @refreshDataList="refreshList"></RoleForm>
        <!-- 弹窗, 菜单权限 -->
        <MenuTree ref="MenuTree" @refreshTreeList="refreshList"></MenuTree>
    </div>
</template>

<script>
import {deleteRole, getRoleList, updateStatus} from '@/api/system/role'
    import RoleForm from './RoleForm'
    import MenuTree from './MenuTree'
    import de from "element-ui/src/locale/lang/de";
    export default {
        name: "Role",
        data() {
          return {
            loading: true,
            tableData: [],
            pageNo: 1,  //前往第几页
            pageSize: 10, //每页几条数据
            total: 0,  //总条数
            orderBy: 'create_time desc'
          }
        },
        //页面加载时候调用
        activated(){
          if (this.hasPermission('sys:role:list')) {
            this.refreshList()
          }
        },
        components: {
            RoleForm,
            MenuTree
        },
        methods: {
            handleSizeChange(val) {
                this.pageSize = val
                this.refreshList();
            },
            handleCurrentChange(val) {
                this.pageNo =val
                this.refreshList();
            },
            //新增角色
            addButton(){
                this.$refs.RoleForm.init('add', '')
            },
            handleEdit(row){
                this.$refs.RoleForm.init('edit', JSON.parse(JSON.stringify(row)))
            },
            addRole(row){
                this.$refs.MenuTree.init('addTree', JSON.parse(JSON.stringify(row)))
            },
            handleDelete(row){
              deleteRole(row.id).then(resp=>{
                if (resp.success){
                  this.$modal.msgSuccess(resp.msg);
                  this.refreshList()
                }
              })
            },
            refreshList(){
              this.loading = true;
                //递归构造菜单列表树
              let params = {
                  'pageNum': this.pageNo,  //从第几行开始查
                  'pageSize': this.pageSize, //查几条数据
                  'orderBy': this.orderBy
              }
              getRoleList(params).then(resp=>{
                //修改滑块的状态
                resp.data.list.forEach(function(data) {
                  if (data.status==1){
                    data.status=true
                  }else {
                    data.status=false
                  }
                  //修改类型
                  if (data.type==1){
                    data.type="目录"
                  }else if(data.type==2){
                    data.type="菜单"
                  }else if(data.type==3){
                    data.type="按钮"
                  }
                })
                this.total = resp.data.total
                this.tableData = resp.data.list
                this.loading = false;
              })
            },
            //控制滑块的方法
            clickStart(id,status){
              if (status==true){
                  status=1
              }else {
                  status=2
              }
              let parement={
                  id: id,
                  status: status
              }
              updateStatus(parement).then(resp=>{
                if (resp.success){
                  this.$message({
                    message: resp.msg,
                    type: 'success'
                  });
                }else {
                  this.$message.error('修改失败');
                }
              })
            },
        }
    }
</script>

<style scoped>

</style>