<template>
    <div>
        <el-row class="top">
            <el-button v-if="hasPermission('sys:user:add')" size="small" type="primary"  @click="addButton">新增用户</el-button>
            <el-button v-if="hasPermission('sys:user:delete')" size="small" type="primary" @click="deleteButton">批量删除</el-button>
        </el-row>
        <template>
            <el-table v-loading="loading" :data="tableData" @selection-change="handleSelectionChange">
                <el-table-column type="selection" align="center" width="55"/>
                <el-table-column prop="username" label="用户名"/>
                <el-table-column prop="phone" :show-overflow-tooltip="true" label="手机号"/>
                <el-table-column prop="deptName" :formatter="stateFormat" label="所属公司"/>
                <el-table-column prop="realName" :formatter="stateFormat" label="真实名称"/>
                <el-table-column prop="nickName" :formatter="stateFormat" label="昵称"/>
                <el-table-column prop="email" :show-overflow-tooltip="true" label="邮箱"/>
                <el-table-column prop="sex" label="性别" width="50px"/>
                <el-table-column prop="identityNumber" label="身份证号"/>
                <el-table-column prop="createTime" :show-overflow-tooltip="true" label="创建时间"/>
                <el-table-column prop="updateTime" :show-overflow-tooltip="true" label="更新时间"/>
                <el-table-column prop="createWhere" label="注册来源" width="80px"/>
                <el-table-column prop="status" label="状态" width="50px">
                    <template slot-scope="tableData">
                        <el-switch v-if="hasPermission('sys:user:status')" v-model="tableData.row.status"
                                   @change="clickStart(tableData.row.id,tableData.row.status)"
                                active-color="#13ce66" inactive-color="#ff4949">
                        </el-switch>
                    </template>
                </el-table-column>
                <el-table-column label="操作" width="230px">
                    <template slot-scope="scope">
                        <el-button v-if="hasPermission('sys:user:role:update')" size="mini" @click="addRole(scope.row)">赋予角色</el-button>
                        <el-button v-if="hasPermission('sys:user:update')" size="mini" @click="handleEdit(scope.row)">编辑</el-button>
                        <el-button v-if="hasPermission('sys:user:delete')" size="mini" type="danger" @click="deleteButton(scope.row)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div class="block">
                <el-pagination
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :current-page="pageNo"
                        :page-sizes="[5, 10, 15, 20]"
                        :page-size="pageSize"
                        :total="total"
                        background
                        layout="total, sizes, prev, pager, next, jumper">
                </el-pagination>
            </div>
        </template>
        <!-- 弹窗, 新增 / 修改 -->
        <UserForm ref="UserForm" @refreshDataList="refreshList"></UserForm>
        <UserRoleForm ref="UserRoleForm" @refreshDataList="refreshList"></UserRoleForm>
    </div>
</template>

<script>
import {deleteUser, getUserList, updataUserStatus} from '@/api/system/user'
    import UserForm from './UserForm'
    import UserRoleForm from "./UserRoleForm";

    export default {
        name: "User",
        data() {
            return {
              loading: true,
              tableData: [],
              pageNo: 1,  //前往第几页
              pageSize: 10, //每页几条数据
              total: 0,  //总条数
              orderBy: 'create_time desc',
              multipleSelection: []
            }
        },
        //页面加载时候调用
        activated(){
          if (this.hasPermission('sys:user:list')){
            this.refreshList()
          }
        },
        components: {
            UserForm,
            UserRoleForm
        },
        methods: {
            stateFormat(row, column, cellValue) {
                if (!cellValue) return ''
                if (cellValue.length > 5) {       //最长固定显示10个字符
                    return cellValue.slice(0, 5) + '...'
                }
                return cellValue
            },
            handleSelectionChange(val) {
              this.multipleSelection = val.map(item => item.id);
            },
            handleSizeChange(val) {
                this.pageSize = val
                this.refreshList();
            },
            handleCurrentChange(val) {
                this.pageNo =val
                this.refreshList();
            },
            //新增角色
            addButton(){
                this.$refs.UserForm.init('add', '')
            },
            handleEdit(row){
                this.$refs.UserForm.init('edit', JSON.parse(JSON.stringify(row)))
            },

            addRole(row){
                this.$refs.UserRoleForm.init(row.id)
            },
            deleteButton(row){
              let ids = []
              if (row.id!=undefined && row.id!=''){
                ids.push(row.id)
              }else {
                ids=this.multipleSelection
              }
              deleteUser(ids).then(resp=>{
                if (resp.success){
                  this.$message({
                    message: resp.msg,
                    type: 'success'
                  });
                  this.refreshList()
                }
              })
            },

            refreshList(){
              this.loading = true;
                //递归构造菜单列表树
              let params = {
                  'pageNum': this.pageNo,  //从第几行开始查
                  'pageSize': this.pageSize, //查几条数据
                  'orderBy': this.orderBy
              }
              getUserList(params).then(resp=>{

                if (resp.success){
                  //修改滑块的状态
                  resp.data.list.forEach(function(data) {
                    //修改滑块状态
                    if (data.status==1){
                      data.status=true
                    }else {
                      data.status=false
                    }
                    //修改类型
                    if (data.sex==1){
                      data.sex="男"
                    }else if(data.sex==2){
                      data.sex="女"
                    }
                    //修改注册来源
                    if(data.createWhere === 1){
                      data.createWhere = "Web";
                    }
                    if(data.createWhere ===2){
                      data.createWhere = "Android";
                    }
                    if(data.createWhere === 3){
                      data.createWhere = "Ios";
                    }
                  })
                  this.total = resp.data.total
                  this.pageSize = resp.data.pageSize
                  this.tableData = resp.data.list
                  this.loading = false;
                }else {
                  this.$message.error(resp.msg);
                  this.loading = false;
                }

              })
            },
            //控制滑块的方法
            clickStart(id,status){
                if (status==true){
                    status=1
                }else {
                    status=2
                }
                let parement={
                    id: id,
                    status: status
                }
              updataUserStatus(parement).then(resp=>{
                if (resp.success){
                  this.$message({
                    message: resp.msg,
                    type: 'success'
                  });
                }else {
                  this.$message.error('修改失败');
                }

              })
            },
        }
    }
</script>

<style scoped>

</style>