<template>
  <div class="app-container">
    <el-form :model="queryParams" ref="queryForm" size="small" :inline="true" v-show="showSearch" label-width="68px">
      <el-form-item label="手机号" prop="billNums">
        <el-input
            v-model="queryParams.number"
            placeholder="请输入手机号"
            clearable
            style="width: 240px"/>
      </el-form-item>
      <el-form-item label="时间">
        <el-date-picker
            v-model="queryParams.dateList"
            style="width: 240px"
            value-format="yyyy-MM-dd"
            type="daterange"
            range-separator="-"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
        ></el-date-picker>
      </el-form-item>

      <el-form-item style="float: right;">
        <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
        <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
      </el-form-item>
    </el-form>
    <el-row :gutter="10" class="mb8">
      <el-button-group class="pull-right">
        <el-tooltip class="item" effect="dark" content="搜索" placement="top">
          <el-button
              type="default"
              size="small"
              icon="el-icon-search"
              @click="showSearch = !showSearch">
          </el-button>
        </el-tooltip>
        <el-tooltip class="item" effect="dark" content="刷新" placement="top">
          <el-button
              type="default"
              size="small"
              icon="el-icon-refresh"
              @click="refreshList">
          </el-button>
        </el-tooltip>
      </el-button-group>
    </el-row>
    <el-table v-loading="loading" :data="tableData">
      <el-table-column label="订单编码" align="center" key="orderId" prop="orderId" />
      <el-table-column label="手机号" align="center" key="number" prop="number"  />
      <el-table-column label="产品名称" align="center" key="productName" prop="productName" />
      <el-table-column label="订购时间" align="center" key="orderTime" prop="orderTime" />
    </el-table>
    <pagination
        style="float: right;"
        :total="total"
        :page.sync="queryParams.pageNum"
        :limit.sync="queryParams.pageSize"
        @pagination="refreshList"
    />
  </div>
</template>

<script>
import { findAiList} from "@/api/business/ssjy";
  export default {
    name: "AIList",
    components: {},
    data(){
      return{
        total: 0,
        // 遮罩层
        showSearch: true,
        loading: true,
        tableData: [],
        queryParams: {
          pageNum: 1,
          pageSize: 10,
          number: undefined,
          dateList: undefined
        },
      }
    },
    activated(){
      if (this.hasPermission('sys:ai:list')) {
        this.refreshList()
      }
    },
    methods: {
      // 重置按钮操作
      resetQuery() {
        this.queryParams.pageNum = 1;
        this.queryParams.pageSize = 10;
        this.queryParams.number = undefined;
        this.queryParams.dateList = undefined;
        this.handleQuery();
      },
      handleQuery() {
        this.refreshList();
      },
      refreshList(){
        this.loading = true;
        findAiList(this.queryParams).then(resp=>{
          this.tableData = resp.data.list;
          this.total = resp.data.total;
          this.loading = false;
        })
      },
    },
    }
</script>

<style>

</style>