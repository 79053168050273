<template>
  <div class="app-container">
    <el-form :model="queryParams" ref="queryForm" :inline="true" v-show="showSearch" label-width="68px">
      <el-form-item label="流程标识" prop="processKey">
        <el-input
            v-model="queryParams.processKey"
            placeholder="请输入流程标识"
            clearable
            size="small"
            @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="流程名称" prop="processName">
        <el-input
            v-model="queryParams.processName"
            placeholder="请输入流程名称"
            clearable
            size="small"
            @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="流程分类" prop="category">
        <el-select v-model="queryParams.category" clearable placeholder="请选择" size="small">
          <el-option
              v-for="item in categoryOptions"
              :key="item.categoryId"
              :label="item.categoryName"
              :value="item.code">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="提交时间">
        <el-date-picker
            v-model="dateRange"
            style="width: 240px"
            value-format="yyyy-MM-dd HH:mm:ss"
            type="daterange"
            range-separator="-"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :default-time="['00:00:00', '23:59:59']"
        ></el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
        <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
      </el-form-item>
    </el-form>

    <el-row :gutter="10" class="mb8">
      <el-col :span="1.5">
        <el-button
            type="danger"
            plain
            icon="el-icon-delete"
            size="mini"
            :disabled="multiple"
            @click="handleDelete"
        >删除</el-button>
      </el-col>
      <el-button-group class="pull-right">
        <el-tooltip class="item" effect="dark" content="搜索" placement="top">
          <el-button
              type="default"
              size="small"
              icon="el-icon-search"
              @click="showSearch = !showSearch">
          </el-button>
        </el-tooltip>
        <el-tooltip class="item" effect="dark" content="刷新" placement="top">
          <el-button
              type="default"
              size="small"
              icon="el-icon-refresh"
              @click="getList">
          </el-button>
        </el-tooltip>
      </el-button-group>
    </el-row>

    <el-table v-loading="loading" :data="ownProcessList" @selection-change="handleSelectionChange">
      <el-table-column type="selection" width="55" align="center" />
      <el-table-column label="流程编号" align="center" prop="procInsId" :show-overflow-tooltip="true"/>
      <el-table-column label="流程名称" align="center" prop="procDefName" :show-overflow-tooltip="true"/>
      <el-table-column label="流程类别" align="center" prop="category" :formatter="categoryFormat" />
      <el-table-column label="流程版本" align="center" width="80px">
        <template slot-scope="scope">
          <el-tag size="medium" >v{{ scope.row.procDefVersion }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="当前节点" align="center" prop="taskName"/>
      <el-table-column label="提交时间" align="center" prop="createTime" width="180"/>
      <el-table-column label="流程状态" align="center" width="100">
        <template slot-scope="scope">
          <el-tag type="success" v-if="scope.row.processStatus==='completed'">已完成</el-tag>
          <el-tag type="warning" v-if="scope.row.processStatus==='canceled'">已取消</el-tag>
          <el-tag type="primary" v-if="scope.row.processStatus==='running'">进行中</el-tag>
          <el-tag type="danger" v-if="scope.row.processStatus==='terminated'">已终止</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="耗时" align="center" prop="duration" width="180"/>
      <el-table-column label="操作" align="center" class-name="small-padding fixed-width">
        <template slot-scope="scope">
          <el-button
              type="text"
              size="mini"
              icon="el-icon-tickets"
              @click="handleFlowRecord(scope.row)"
          >详情</el-button>
          <el-button
              type="text"
              size="mini"
              icon="el-icon-delete"
              @click="handleDelete(scope.row)"
              v-if="scope.row.finishTime"
          >删除</el-button>
          <el-button
              type="text"
              size="mini"
              icon="el-icon-circle-close"
              @click="handleStop(scope.row)"
          >取消</el-button>
          <el-button
              type="text"
              size="mini"
              icon="el-icon-refresh-right"
              @click="handleAgain(scope.row)"
          >重新发起</el-button>
        </template>
      </el-table-column>
    </el-table>

    <pagination
        v-show="total>0"
        :total="total"
        :page.sync="queryParams.pageNum"
        :limit.sync="queryParams.pageSize"
        @pagination="getList"
    />
    <MeProcessForm ref="MeProcessForm" @refreshList="init"></MeProcessForm>
    <create-process-form ref="CreateProcessForm" @refreshList="init"></create-process-form>
  </div>
</template>

<script>
import { listOwnProcess, stopProcess, delProcess } from '@/api/activityWorkFlow/meProcess/meProcess';
import { listAllCategory } from '@/api/activityWorkFlow/activity/category';
import MeProcessForm from "@/views/activityWorkFlow/meProcess/MeProcessForm";
import CreateProcessForm from "@/views/activityWorkFlow/createProcess/CreateProcessForm.vue";
export default {
  name: "MeProcessList",
  components: {
    CreateProcessForm,
    MeProcessForm
  },
  data() {
    return {
      // 遮罩层
      loading: true,
      processLoading: true,
      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 显示搜索条件
      showSearch: true,
      // 总条数
      total: 0,
      categoryOptions: [],
      processTotal:0,
      // 我发起的流程列表数据
      ownProcessList: [],
      // 弹出层标题
      title: "",
      src: "",
      definitionList:[],
      // 日期范围
      dateRange: [],
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        processKey: undefined,
        processName: undefined,
        category: undefined
      },
      // 表单校验
      rules: {
      },
    };
  },
  activated() {
    this.init()
  },
  methods: {
    init(){
      this.getCategoryList();
      this.getList();
    },
    /** 查询流程分类列表 */
    getCategoryList() {
      listAllCategory().then(response => this.categoryOptions = response.data)
    },
    /** 查询流程定义列表 */
    getList() {
      this.loading = true;
      listOwnProcess(this.addDateRange(this.queryParams, this.dateRange)).then(response => {
        this.ownProcessList = response.data.list;
        this.total = response.data.total;
        this.loading = false;
      });
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.pageNum = 1;
      this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.$refs["queryForm"].resetFields();
      this.dateRange = [];
      this.handleQuery();
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map(item => item.procInsId);
      this.single = selection.length !== 1;
      this.multiple = !selection.length;
    },
    handleAgain(row) {
      this.$refs.CreateProcessForm.init('reStart', row)
    },
    /**  取消流程申请 */
    handleStop(row){
      const params = {
        procInsId: row.procInsId
      }
      stopProcess(params).then( res => {
        this.$modal.msgSuccess(res.msg);
        this.getList();
      });
    },
    /** 流程流转记录 */
    handleFlowRecord(row) {
      this.$refs.MeProcessForm.init('view', row,false)
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      const ids = row.procInsId || this.ids;
      this.$confirm('是否确认删除流程定义编号为"' + ids + '"的数据项?', "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function() {
        return delProcess(ids);
      }).then(() => {
        this.getList();
        this.$modal.msgSuccess("删除成功");
      })
    },
    categoryFormat(row, column) {
      return this.categoryOptions.find(k => k.code === row.category)?.categoryName ?? '';
    }
  }
};
</script>
