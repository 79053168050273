<template>
    <div>
        <el-container>
            <el-header class="homeHeader">
                <div class="title">后台管理系统</div>
                <el-dropdown @command="handleCommand" class="userInfo">
                  <span class="el-dropdown-link">
                      {{user.username}}<i><img src=""></img></i>
                  </span>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item  command="userInfo">个人中心</el-dropdown-item>
                        <el-dropdown-item  command="setting">设置</el-dropdown-item>
                        <el-dropdown-item  command="logout">注销登陆</el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
            </el-header>
            <el-container >
                <el-aside>
                    <el-menu router unique-opened>
                        <el-submenu
                            :index="index+''"
                                    v-for="(item,index) in routes" :key="index"
                                    v-if="!item.hidden">
                            <template slot="title">
                                <i :class="item.iconCls" style="color: #1accff;margin-right: 5px"></i>
                                <span class="item">{{item.name}}</span>
                            </template>
                          <el-menu-item :index="children.path" v-for="(children,indexs) in item.children" :key="indexs">
                            <i :class="children.iconCls" style="color: #1accff;margin-right: 5px"></i>
                            {{children.name}}
                          </el-menu-item>
                        </el-submenu>
                    </el-menu>
                </el-aside>
                <el-container>
                    <el-main>
                        <el-breadcrumb separator-class="el-icon-arrow-right" v-if="this.$router.currentRoute.path!='/home'">
                            <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
                            <el-breadcrumb-item>{{this.$router.currentRoute.name}}</el-breadcrumb-item>
                        </el-breadcrumb>
                        <div class="homeWelcome" v-if="this.$router.currentRoute.path=='/home'">
                            欢迎来到首页
                        </div>
                        <keep-alive>
                            <router-view class="homeRouterView"></router-view>
                        </keep-alive>
                    </el-main>
                </el-container>
            </el-container>
        </el-container>
    </div>
</template>

<script>

    import {loginOut} from "@/api/oauht/oauth";

    export default {
      name: "Home",
      data(){
        return{

        }
      },
      methods: {
          handleCommand(command) {
              if (command=='logout'){
                  this.$confirm('此操作将注销登陆, 是否继续?', '提示', {
                      confirmButtonText: '确定',
                      cancelButtonText: '取消',
                      type: 'warning'
                  }).then(() => {
                    loginOut().then(reps=>{
                      if (reps.success){
                        //清空用户信息
                        window.sessionStorage.removeItem('refreshToken')
                        window.sessionStorage.removeItem('accessToken')
                        window.sessionStorage.removeItem('user')
                        //清除菜单信息
                        this.$store.commit('initRoutes',[])
                        //跳转到登陆页
                        this.$router.replace('/')
                      }
                    })
                  }).catch(() => {
                      this.$message({
                          type: 'info',
                          message: '已取消操作'
                      });
                  });

              }else if(command=='userInfo'){

              }else if(command=='setting'){
              }
          }
      },
      computed:{
        routes() {
          return this.$store.state.routes;
        },
        user(){
          return this.$store.state.user;
        }
      }

    }
</script>

<style scoped lang="less">
    ::-webkit-scrollbar {
      display: none;
    }
    .homeHeader{
        background: rgb(48, 65, 86);
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 15px;
        box-sizing: border-box;
    }
    .homeHeader .title{
        font-size: 30px;
        font-family: 微软雅黑;
        color: white;
    }
    .homeHeader .userInfo{
        cursor: pointer;
    }
    .el-dropdown-link img{
        width: 48px;
        height: 48px;
        border-radius: 24px;
        margin-left: 8px;
    }
    .homeWelcome{
        text-align: center;
        font-size: 30px;
        font-family: 微软雅黑;
        color: #409eff;
        padding-top: 50px;
    }
    .homeRouterView{
        margin-top: 10px;
    }
    .item {
      font-size: 14px;
      color: #ffffff;
      padding: 0 20px;
      cursor: pointer;
      transition: border-color .3s, background-color .3s, color .3s;
      box-sizing: border-box;
    }
    .el-submenu .el-menu-item {
      font-size: 14px;
      background-color: #1f2d3d !important;
      color: #f6f6f8;
      height: 50px;
      line-height: 50px;
      padding: 0 45px;
      min-width: 200px;
    }
    .el-aside{
      font-size: 100px;
      height: 100vh;
      background-color: rgb(48, 65, 86);
      overflow: hidden;
    }
    .el-menu{
      background-color: rgb(48, 65, 86);
    }

    .el-submenu /deep/.el-submenu__title:hover {
      background-color: #1f2d3d !important;
    }
    //设置鼠标悬停时el-menu-item的样式
    .el-menu-item:hover{
      background-color: rgb(45, 43, 44) !important;
      color: #38B2FF !important;
      //less语法，实现鼠标悬停时icon变色
      i {
        color: #38B2FF;
      }
    }
    //设置选中el-menu-item时的样式
    .el-menu-item.is-active {
      background-color: rgb(27, 35, 47) !important;
      color: #38B2FF !important;
    }



</style>
