<template>
    <div>
        <el-dialog :title="title" :visible.sync="dialogVisible" width="30%" >
<!--     default-expanded-keys    默认展开的节点的 key 的数组       -->
<!--     default-checked-keys   	默认勾选的节点的 key 的数组-->
            <el-tree
                    v-loading="treeLoading"
                    :data="data"
                    :props="{
                        label: 'name',
                        children: 'children'
                    }"
                    :check-strictly="true"
                    show-checkbox
                    element-loading-spinner="el-icon-loading"
                    node-key="id"
                    ref="menuListTree"
                    :default-checked-keys="menuCheckedKeys">
            </el-tree>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="inputFormSubmit()">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import {createRoles, getMenuTreeAll, submitRolePermission} from "@/api/system/role";
    export default {
        name: "MenuTree",
        data(){
            return {
              treeLoading: true,
              //菜单头
              title: "菜单权限",
              //弹出框显示与隐藏
              dialogVisible: false,
              method: "",
              inputForm: {
                  permissionIds:[]
              },
              menuCheckedKeys: [],
              data: [],
            }
        },
        methods:{
            init(method, row) {
              this.treeLoading = true
              this.menuCheckedKeys = []
              this.method = method;
              this.inputForm.id = row.id;
              this.inputForm.name = row.name;
              if (method === "addTree") {
                getMenuTreeAll({id:row.id}).then(resp=>{
                  if (resp.success){
                    this.data = resp.data.tree
                    this.menuCheckedKeys = resp.data.select
                    this.treeLoading = false
                  }else {
                    this.$message.error(resp.msg);
                  }
                })
              }
              this.dialogVisible = true;
            },
            inputFormSubmit(){
              //选中的id数据
              this.inputForm.permissionIds = this.$refs.menuListTree.getCheckedKeys();
              submitRolePermission(this.inputForm).then(resp=>{
                if (resp.success){
                  this.$message({
                    message: resp.msg,
                    type: 'success'
                  });
                  this.$emit('refreshTreeList')
                  this.dialogVisible = false
                }else {
                  this.$message.error(resp.msg);
                }
              })
            }

        }
    }
</script>

<style scoped>

</style>