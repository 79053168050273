<template>
  <div>
    <el-dialog :title="title" :visible.sync="dialogVisible" width="800px" append-to-body>
      <el-form :model="inputForm" :rules="rules" ref="inputForm" :class="method === 'view' ? 'readonly' : ''" :disabled="method === 'view'"  label-width="140px">
        <el-row>
          <el-col :span="24">
            <el-form-item label="手机号" prop="phone">
              <el-input v-model="inputForm.phone" placeholder="请输入黑名单手机号" />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm">确 定</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {createMobileBlack} from "@/api/business/ssjy";

export default {
  name: "MobileBlackForm",
  data(){
    return {
      //菜单头
      title: "",
      //弹出框显示与隐藏
      dialogVisible: false,
      method: "",
      inputForm: {},
      rules: {
        phone:[{ required: true, message: "黑名单手机号不能为空", trigger: "blur" }]
      }
    }
  },
  methods:{
    init(method, row) {
      this.reset();
      this.method = method;
      if (method === "add") {
        this.title = `新建黑名单`;
      }
      this.dialogVisible = true;
    },
    submitForm: function() {
      this.$refs['inputForm'].validate((valid) => {
        if (valid){
          if (this.method=='add'){
            createMobileBlack(this.inputForm).then(resp=>{
              this.cancel()
              if (resp.success){
                this.$message({
                  message: resp.msg,
                  type: 'success'
                });
                this.$emit('refreshDataList')
              }else {
                this.$message.error(resp.msg);
              }
            })
          }
        }
      })
    },
    // 取消按钮
    cancel() {
      this.dialogVisible = false;
      this.reset();
    },
    reset() {
      this.inputForm = {};
    },
  }
}
</script>

<style scoped>

</style>