<template>
    <div>
        <el-row class="top">
            <el-button v-if="hasPermission('sys:client:add')" size="small" type="primary"  @click="addButton">新增客户端</el-button>
        </el-row>
        <template>
            <el-table v-loading="loading" :data="tableData">
                <el-table-column type="selection" align="center" width="55"/>
                <el-table-column prop="clientId" label="客户端id"/>
                <el-table-column prop="resourceIds" label="资源id"/>
                <el-table-column prop="clientSecret" :show-overflow-tooltip="true" label="密钥"/>
                <el-table-column prop="scope" label="授权范围"/>
                <el-table-column prop="authorizedGrantTypes" :show-overflow-tooltip="true" label="授权类型"/>
                <el-table-column prop="webServerRedirectUri" :show-overflow-tooltip="true" label="重定向地址"/>
                <el-table-column prop="accessTokenValidity" label="token时间"/>
                <el-table-column prop="refreshTokenValidity" label="刷新token时间"/>
                <el-table-column prop="autoapprove" label="是否自动授权"/>
                <el-table-column label="操作" width="230px">
                    <template slot-scope="scope">
                        <el-button v-if="hasPermission('sys:client:update')" size="mini" @click="handleEdit(scope.row)">编辑</el-button>
                        <el-button v-if="hasPermission('sys:client:delete')" size="mini" type="danger" @click="deleteButton(scope.row)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div class="block">
                <el-pagination
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :current-page="pageNo"
                        :page-sizes="[5, 10, 15, 20]"
                        :page-size="pageSize"
                        :total="total"
                        background
                        layout="total, sizes, prev, pager, next, jumper">
                </el-pagination>
            </div>
        </template>
      <ClientForm ref="ClientForm" @refreshDataList="refreshList"></ClientForm>
    </div>
</template>

<script>
  import ClientForm from './ClientForm.vue'
  import {deleteClient, getClientList} from "@/api/system/client";
  export default {
    name: "Client",
    components: {ClientForm},
    data() {
        return {
          loading: true,
          tableData: [],
          pageNo: 1,  //前往第几页
          pageSize: 10, //每页几条数据
          total: 0,  //总条数
          orderBy: 'create_time desc'
        }
      },
      //页面加载时候调用
    activated(){
      if (this.hasPermission('sys:client:list')){
        this.refreshList()
      }
    },
    methods: {
      //新增角色
      addButton(){
        this.$refs.ClientForm.init('add', '')
      },
      handleEdit(row){
        this.$refs.ClientForm.init('edit', JSON.parse(JSON.stringify(row)))
      },
      handleSizeChange(val) {
        this.pageSize = val
        this.refreshList();
      },
      handleCurrentChange(val) {
        this.pageNo =val
        this.refreshList();
      },
      deleteButton(row){
        let ids = []
        if (row.id!=undefined && row.id!=''){
          ids.push(row.id)
        }
        deleteClient(ids).then(resp=>{
          if (resp.success){
            this.$message({
              message: resp.msg,
              type: 'success'
            });
            this.refreshList()
          }
        })
      },
      refreshList(){
        this.loading = true;
          //递归构造菜单列表树
        let params = {
            'pageNum': this.pageNo,  //从第几行开始查
            'pageSize': this.pageSize, //查几条数据
            'orderBy': this.orderBy
        }
        getClientList(params).then(resp=>{
          this.total = resp.data.total
          this.pageSize = resp.data.pageSize
          this.tableData = resp.data.list
          this.loading = false;
        })
      },
    }
  }
</script>

<style scoped>

</style>