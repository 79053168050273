import request from '@/utils/request'

export function getUserList(data) {
    return request({
        url: '/system/user/sysUser/findListByPage',
        method: 'post',
        data: data
    })
}

export function updataUserStatus(data) {
    return request({
        url: '/system/user/sysUser/updataUserStatus',
        method: 'post',
        data: data
    })
}

export function deleteUser(data) {
    return request({
        url: '/system/user/sysUser/deleteById',
        method: 'post',
        data: data
    })
}

export function saveUser(data) {
    return request({
        url: '/system/user/sysUser/create',
        method: 'post',
        data: data
    })
}

export function updateUser(data) {
    return request({
        url: '/system/user/sysUser/update',
        method: 'post',
        data: data
    })
}