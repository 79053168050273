<template>
  <el-drawer ref="drawerRefName" :title="title" :visible.sync="drawer" direction="rtl" size="40%">
    <el-col :span="20" :xs="24">
      <el-form :model="queryParams" ref="queryForm" size="small" :inline="true" label-width="80px">
        <el-form-item style="float: left;">
          <el-button type="primary" icon="el-icon-search" size="mini" @click="submitForm">提交</el-button>
        </el-form-item>
      </el-form>
    </el-col>
    <el-table  :row-key="(row) => { return row.id }" ref="tableData"  v-loading="loading" :data="tableData" @selection-change="handleSelectionChange">
      <el-table-column :reserve-selection="true" type="selection" width="50" align="center" />
      <el-table-column label="铃音名称" align="center" key="ringName" prop="ringName" />
      <el-table-column label="铃音类型" :formatter="resultCode" align="center" key="ringType" prop="ringType"/>
      <el-table-column label="铃音id" align="center" key="ringId" prop="ringId"/>
    </el-table>
    <pagination
        style="float: right;"
        :total="total"
        :page.sync="queryParams.pageNum"
        :limit.sync="queryParams.pageSize"
        @pagination="refreshList"
    />
  </el-drawer>
</template>

<script>
import {findringtoneList} from "@/api/business/ssjy";

export default {
    name: "RingtoneDrawer",
    data(){
      return {
        tableData: [],
        ringIds: [],
        drawer: false,
        title:"",
        total: 0,
        // 遮罩层
        loading: true,
        queryParams: {
          pageNum: 1,
          pageSize: 10,
          ringName: undefined
        },
      }
    },
    methods:{
      resultCode(row, column, cellValue) {
        const status = row.ringType
        if (status == '1') {
          return '视频'
        } else {
          return '音频'
        }
      },
      init(method, ringType,ecoperationId) {
        this.method = method;
        if (method === "select") {
            this.title = `铃音列表`;
            this.queryParams.ringType=ringType
            this.queryParams.ecoperationId=ecoperationId
        }
        this.refreshList()
        this.drawer = true;
      },
      handleSelectionChange(selection) {
        this.ringIds = selection.map(item => item.ringId);
      },
      refreshList(){
        this.loading = true;
        findringtoneList(this.queryParams).then(resp=>{
          this.tableData = resp.data.list;
          this.total = resp.data.total;
          this.loading = false;
        })
      },
      submitForm: function() {

        let flag = false
        this.ringIds.forEach(item=>{
          if (item==undefined||item==""){
            flag = true
          }
        })
        if (flag){
          this.$message({
            message: "选择的数据包含铃音id为空的数据,请刷新等待远程审核",
            type: 'error'
          });
        }else {
          this.$emit('childEvent',this.ringIds)
          this.$refs.drawerRefName.closeDrawer()
        }

      },
    }
}
</script>

<style scoped>

</style>