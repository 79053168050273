<template>
    <div>
        <el-dialog :title="title" :visible.sync="dialogVisible" width="800px" append-to-body>
          <el-form :model="inputForm" :rules="rules" ref="inputForm" :disabled="method === 'view'"  label-width="140px">
            <el-row>
              <el-col :span="12">
                <el-form-item label="产品名称" prop="productName">
                  <el-input v-model="inputForm.productName" placeholder="请输入产品名称" />
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="产品价格" prop="productPrice">
                  <el-input v-model="inputForm.productPrice" placeholder="请输入产品价格" />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item label="产品id" prop="productId">
                  <el-input v-model="inputForm.productId" placeholder="请输入产品id" />
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="代理的公司id" prop="companyId">
                  <el-input v-model="inputForm.companyId" placeholder="请输入代理公司id" />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item label="代理的公司名称" prop="companyName">
                  <el-input v-model="inputForm.companyName" placeholder="代理的公司名称" />
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button type="primary" @click="submitForm">确 定</el-button>
            <el-button @click="cancel">取 消</el-button>
          </div>
        </el-dialog>
    </div>
</template>

<script>
import {insertProduct, updateProduct} from "@/api/lt/lt";

    export default {
        name: "ProductForm",
        data(){
          return {
              //菜单头
            title: "",
            //弹出框显示与隐藏
            dialogVisible: false,
            method: "",
            inputForm: {},
            rules: {
              productId:[
                { required: true, message: "产品id不能为空", trigger: "blur" }
              ],
              companyId:[
                { required: true, message: "代理产品公司id不能为空", trigger: "blur" }
              ],
              companyName:[
                { required: true, message: "代理产品公司名称不能为空", trigger: "blur" }
              ],
              productName:[
                { required: true, message: "产品名称不能为空", trigger: "blur" }
              ],
            },
          }
        },
        components: {

        },
        methods:{
          init(method, row) {
            this.method = method;
            this.dialogVisible = true;
            if (method === "add") {
              this.inputForm={}
              this.title = `新建公司`;
            } else if (method === "edit") {
              this.title = "修改公司";
              this.inputForm = row
            }
          },
          submitForm: function() {
            this.$refs['inputForm'].validate((valid) => {
              if (valid){
                if (this.method=='add') {
                  insertProduct(this.inputForm).then(resp => {
                    if (resp.success) {
                      this.$message({
                        message: resp.msg,
                        type: 'success'
                      });
                      this.cancel()
                      this.$emit('refreshDataList')
                    } else {
                      this.$message.error(resp.msg);
                    }
                  })
                }else {
                  updateProduct(this.inputForm).then(resp => {
                    if (resp.success) {
                      this.$message({
                        message: resp.msg,
                        type: 'success'
                      });
                      this.cancel()
                      this.$emit('refreshDataList')
                    } else {
                      this.$message.error(resp.msg);
                    }
                  })
                }
              }
            })
          },
          // 取消按钮
          cancel() {
            this.dialogVisible = false;
          },
        }
    }
</script>

<style scoped>

</style>