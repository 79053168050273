<template>
    <div>
        <el-row class="top">
            <el-button v-if="hasPermission('sys:log:delete')" size="small" type="primary" @click="handleDelete">批量删除</el-button>
        </el-row>
        <template>
            <el-table
                  v-loading="loading"
                    :data="tableData"
                    border
                    style="width: 100%"
                    @selection-change="handleSelectionChange">
                <el-table-column
                        type="selection"
                        width="55">
                </el-table-column>
                <el-table-column
                        prop="userName"
                        label="账号">
                </el-table-column>
                <el-table-column
                    prop="ip"
                    :show-overflow-tooltip="true"
                    label="IP地址">
                </el-table-column>
              <el-table-column
                  prop="operation"
                  :show-overflow-tooltip="true"
                  label="操作">
              </el-table-column>
              <el-table-column
                  prop="duration"
                  label="代码执行时长">
              </el-table-column>
                <el-table-column
                        prop="params"
                        :show-overflow-tooltip="true"
                        label="请求参数">
                </el-table-column>
                <el-table-column
                        prop="createTime"
                        label="创建时间">
                </el-table-column>
                <el-table-column label="操作">
                    <template slot-scope="scope">
                        <el-button size="mini" type="danger" @click="handleDelete(scope.row)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div class="block">
                <el-pagination
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :current-page="pageNo"
                        :page-sizes="[5, 10, 15, 20]"
                        :page-size="pageSize"
                        :total="total"
                        background
                        layout="total, sizes, prev, pager, next, jumper">
                </el-pagination>
            </div>
        </template>
    </div>
</template>

<script>
import {deleteLog, getLogList} from "@/api/system/log";

    export default {
        name: "Log",
        data() {
            return {
                multipleSelection: [], // 复选框
                tableData: [],  //数据
                loading: true,
                pageNo: 1,  //前往第几页
                pageSize: 10, //每页几条数据
                total: 0,  //总条数
                orderBy: 'create_time desc'
            }
        },
        //页面加载时候调用
        activated(){
            if (this.hasPermission('sys:log:list')) {
                this.refreshList()
            }
        },
        methods: {
            //获取列表数据
            refreshList(){
                this.loading = true;
                //递归构造菜单列表树
                let params = {
                    'pageNum': this.pageNo,  //从第几行开始查
                    'pageSize': this.pageSize, //查几条数据
                    'orderBy': this.orderBy
                }
              getLogList(params).then(resp=>{
                this.total = resp.data.total
                this.tableData = resp.data.list
                this.loading = false;
              })
            },
            //分页
            handleSizeChange(val) {
                this.pageSize = val
                this.refreshList();
            },
            //分页
            handleCurrentChange(val) {
                this.pageNo =val
                this.refreshList();
            },
            //单条删除
            handleDelete(row){
              let ids = []
              if (row.id!=undefined && row.id!=''){
                ids.push(row.id)
              }else {
                ids=this.multipleSelection
              }
              deleteLog(ids).then(resp=>{
                if (resp.success){
                  this.$message({
                    message: resp.msg,
                    type: 'success'
                  });
                  this.refreshList()
                }
              })
            },
            //复选框
            handleSelectionChange(val) {
                var _this = this
                val.forEach(function (item) {
                    _this.multipleSelection.push(item.id)
                })
            },
        }
    }
</script>

<style scoped>

</style>