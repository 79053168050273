<template>

  <el-drawer  ref="drawerRefName" :title="title" :visible.sync="drawer" direction="rtl" size="80%">
    <el-form size="small" :inline="true"  label-width="100px">
      <el-form-item label="创建时间" prop="time">
        <el-date-picker
            v-model="queryParams.dateList"
            style="width: 240px"
            value-format="yyyy-MM-dd"
            type="daterange"
            range-separator="-"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
        ></el-date-picker>
      </el-form-item>

      <el-form-item label="手机号" prop="billNums">
        <el-input
            v-model="queryParams.billNums"
            placeholder="请输入手机号"
            clearable
            style="width: 240px"
            @keyup.enter.native="handleQuery"/>
      </el-form-item>
      <el-form-item label="用户状态" prop="userStatus">
        <el-select v-model="queryParams.userStatus">
          <el-option label="归档成功" value="02" />
          <el-option label="订购待确认" value="99" />
          <el-option label="添加处理中" value="00" />
          <el-option label="添加待归档" value="01" />
        </el-select>
      </el-form-item>

      <el-form-item label="订购状态" prop="state">
        <el-select v-model="queryParams.state">
          <el-option label="订购成功" value="000000" />
          <el-option label="订购失败" value="000001" />
        </el-select>
      </el-form-item>
      <el-form-item style="float: right;">
        <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
        <el-button type="primary" size="mini" @click="downloadTpl">导出</el-button>
        <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
      </el-form-item>
    </el-form>
    <el-table  :row-key="(row) => { return row.id }" ref="tableData"  v-loading="loading" :data="tableData">
      <el-table-column label="手机号码" align="center" key="billNums" prop="billNums" />
      <el-table-column label="用户状态" :formatter="userCode" align="center" key="userStatus" prop="userStatus"  />
      <el-table-column label="地市名称" align="center" key="locationName" prop="locationName" />
      <el-table-column label="省份名称" align="center" key="provinceName" prop="provinceName" />
      <el-table-column label="结果描述" align="center" key="desc1" prop="desc1" />
      <el-table-column label="数据状态" :formatter="dataFormat" align="center" key="type" prop="type" />
      <el-table-column label="创建日期"  align="center" key="time" prop="time" />
    </el-table>
    <pagination
        style="float: right;"
        :total="total"
        :page.sync="queryParams.pageNum"
        :limit.sync="queryParams.pageSize"
        @pagination="showView"
    />
  </el-drawer>

</template>

<script>
import {tripartiteUser} from "@/api/business/ssjy";

export default {
  name: "TripartiteCompanyDrawerViem",
  data(){
    return {
      tableData: [],
      ringIds: [],
      drawer: false,
      title:"",
      total: 0,
      // 遮罩层
      loading: true,
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        ecoperationId:undefined,
        dateList: [],
        userStatus: undefined,
        billNums: undefined,
        state: undefined,
      },
    }
  },
  methods:{
    init(method, row) {
      this.method = method;
      if (method === "find"){
        this.title = `查看用户`;
        this.queryParams.corporateId=row.id
        this.showView()
      }
      this.drawer = true;
    },
    handleQuery() {
      this.showView();
    },
    resetQuery() {
      this.queryParams.pageNum=1;
      this.queryParams.pageSize=10;
      this.queryParams.dateList=[];
      this.queryParams.userStatus=undefined;
      this.queryParams.billNums=undefined;
      this.queryParams.state=undefined;
      this.showView();

    },
    dataFormat(row, column, cellValue) {
      const status = row.type
      if (status==1){
        return '添加成员'
      }else if (status==2){
        return '删除成员'
      }
    },
    userCode(row, column, cellValue) {
      const status = row.userStatus
      if (status=='99'){
        return '订购待确认'
      }else if (status=='00'){
        return '添加处理中'
      }else if (status=='01'){
        return '添加待归档'
      }else if (status=='02'){
        return '添加归档成功'
      }else if (status=='03'){
        return '添加归档失败'
      }else if (status=='80'){
        return '当前系统新增'
      }
    },
    showView(){
      this.loading = true;
      tripartiteUser(this.queryParams).then(resp=>{
        this.tableData = resp.data.list;
        this.total = resp.data.total;
        this.loading = false;
      })
    },
    downloadTpl () {
      this.$downloadPost('/ssjy/user/tripartite/template',this.queryParams)
      // window.location.href = process.env.VUE_APP_BASE_API+`/ssjy/mobileBlack/mobileBlack/template`

    },
  }
}
</script>

<style rel="stylesheet/scss" lang="scss">
.el-drawer{

  overflow: scroll
}

</style>