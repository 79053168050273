import request from '@/utils/request'

// 查询流程列表
export function listProcess(query) {
    return request({
        url: '/activity/workflow/process/list',
        method: 'get',
        params: query
    })
}
export function getFlowBpmnXml(processDefId) {
    return request({
        url: '/activity/workflow/process/bpmnXml/' + processDefId,
        method: 'get'
    })
}


export function getProcessForm(query) {
    return request({
        url: '/activity/workflow/process/getProcessForm',
        method: 'get',
        params: query
    })
}

export function startProcess(processDefId, data) {
    return request({
        url: '/activity/workflow/process/start/' + processDefId,
        method: 'post',
        data: data
    })
}