<template>
    <div>
        <el-dialog :title="title" :visible.sync="dialogVisible" width="800px" append-to-body>
          <el-form :model="inputForm" :rules="rules" ref="inputForm" :disabled="method === 'view'"  label-width="140px">
            <el-row>
              <el-col :span="12">
                <el-form-item label="部门名称" prop="ecoperationId">
                  <treeselect
                      v-model="inputForm.ecoperationId"
                      :options="deptOptions"
                      :normalizer="normalizer"
                      placeholder="选择部门" />
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="用户手机号" prop="billNums">
                  <el-input v-model="inputForm.billNums" placeholder="请输入用户手机号多个使用,号分割" />
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button type="primary" @click="submitForm">确 定</el-button>
            <el-button @click="cancel">取 消</el-button>
          </div>
        </el-dialog>
    </div>
</template>

<script>
    import Treeselect from "@riophae/vue-treeselect";
    import "@riophae/vue-treeselect/dist/vue-treeselect.css";
    import {findGroupList, insertGroupUser} from "@/api/business/ssjy";
    export default {
        name: "GroupUserFrom",
        data(){
          return {
              //菜单头
            title: "",
            //弹出框显示与隐藏
            dialogVisible: false,
            method: "",
            inputForm: {},
            deptOptions: [],
            rules: {
              ecoperationId:[
                { required: true, message: "部门不能为空", trigger: "blur" }
              ],
              billNums:[
                { required: true, message: "用户手机号不能为空", trigger: "blur" }
              ],
            },
          }
        },
        components: {
          Treeselect
        },
        methods:{
          init(method, row) {
              this.method = method;
              if (method === "add") {
                  this.title = `新建用户`;
              }
              this.handleSelect();
              this.dialogVisible = true;
          },
          handleSelect() { // 选择牵头部门事件
            findGroupList({}).then().then(resp=>{
              this.deptOptions = this.handleTree(resp.data.list, "id");
            })
          },
          normalizer(node) {
            if (node.children && !node.children.length) {
              delete node.children;
            }
            return {
              id: node.id,
              label: node.departmentName,
              children: node.children
            };
          },
          submitForm: function() {

            this.$refs['inputForm'].validate((valid) => {
              if (valid){
                if (this.method=='add') {
                  insertGroupUser( this.inputForm).then(resp => {
                    this.cancel()
                    if (resp.success) {
                      this.$message({
                        message: resp.msg,
                        type: 'success'
                      });
                      this.$emit('refreshDataList')
                    } else {
                      this.$message.error(resp.msg);
                    }
                  })
                }
              }
            })
          },
          // 取消按钮
          cancel() {
            this.dialogVisible = false;
            this.reset();
          },
          reset() {
            this.inputForm = {};
          },
        }
    }
</script>

<style scoped>

</style>