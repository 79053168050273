<template>
    <div>
        <el-dialog :title="title" :visible.sync="dialogVisible" width="30%">
            <el-form :model="inputForm" :rules="rules" ref="inputForm" :class="method === 'view' ? 'readonly' : ''" :disabled="method === 'view'" label-width="120px">
                <el-row :gutter="30">
                    <el-col :span="20">
                        <el-form-item label="角色名称:" prop="name">
                            <el-input
                                    v-model="inputForm.name"
                                    placeholder="请输入角色名称"
                            ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="20">
                        <el-form-item label="描述:" prop="description">
                            <el-input
                                    v-model="inputForm.description"
                                    placeholder="请输入角色描述"
                            ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="20">
                        <el-form-item label="状态:" prop="menuStatus">
                            <el-switch
                                    v-model="menuStatus"
                                    active-color="#13ce66"
                                    inactive-color="#ff4949">
                            </el-switch>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="save">确 定</el-button>
        </span>
        </el-dialog>
    </div>
</template>

<script>
    import {createRoles} from "@/api/system/role";
    export default {
        name: "RoleForm",
        data(){
            return {
                //菜单头
                title: "",
                //弹出框显示与隐藏
                dialogVisible: false,
                menuStatus: true,
                method: "",
                inputForm: {},
                rules: {}
            }
        },
        components: {
        },
        methods:{
            init(method, row) {
                this.method = method;
                this.inputForm.id = row.id;
                if (method === "add") {
                    this.title = `新建角色`;
                    this.inputForm={};
                    this.menuStatus=true
                } else if (method === "edit") {
                    this.title = "修改角色";
                } else if (method === "view") {
                    this.title = "查看详细";
                }
                this.dialogVisible = true;
                if (method === "edit" || method === "view") {
                    // 修改或者查看
                  if (row.status==true){
                    this.menuStatus=true
                  }
                  if (row.status==false){
                    this.menuStatus=false
                  }
                  this.inputForm = row
                }
            },
            save(){
              this.dialogVisible = false
              if (this.menuStatus!=true ){
                  this.inputForm.status=2
              } else {
                  this.inputForm.status=1
              }
              createRoles(this.inputForm).then(resp=>{
                if (resp.success){
                  this.$message.success(resp.msg)
                  this.$emit('refreshDataList')
                }else {
                  this.$message.error(resp.msg);
                }
              })
            }
        }

    }
</script>

<style>

</style>