<template>
    <div>
        <el-dialog :title="title" :visible.sync="dialogVisible" width="30%">
            <el-form :model="inputForm" ref="inputForm" :class="method === 'view' ? 'readonly' : ''" :disabled="method === 'view'" label-width="120px">
                <el-row :gutter="30">
                    <el-col :span="20">
                        <el-form-item label="部门名称" prop="name">
                            <el-input
                                    v-model="inputForm.name"
                                    placeholder="请输入部门名称"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="20">
                        <el-form-item label="所属部门" prop="pName">
                          <treeselect
                              v-model="inputForm.pid"
                              :options="list"
                              selectable: false
                              @select="selectNode"
                              :normalizer="normalizer"
                              placeholder="选择部门"/>
<!--                            <DeptTree :valuedata="{pId:inputForm.pid,pidName:inputForm.pName}" :props="props" :options="list" :clearable="isClearable" :accordion="isAccordion" @getValue="getValue($event)"/>
                        --></el-form-item>
                    </el-col>
                    <el-col :span="20">
                        <el-form-item label="部门经理名称" prop="managerName">
                            <el-input
                                    v-model="inputForm.managerName"
                                    placeholder="请输入部门经理名称"
                            ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="20">
                        <el-form-item label="部门经理手机号" prop="phone">
                            <el-input
                                    v-model="inputForm.phone"
                                    placeholder="请输入部门经理手机号"
                            ></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="save">确 定</el-button>
        </span>
        </el-dialog>
    </div>
</template>

<script>
    import Treeselect from "@riophae/vue-treeselect";
    import "@riophae/vue-treeselect/dist/vue-treeselect.css";
    import {getDeptTreeAll, submitDept, updateDept} from "@/api/system/dept";
    export default {
        name: "DeptForm",
        data(){
            return {
                //菜单头
                title: "",
                //弹出框显示与隐藏
                dialogVisible: false,
                method: "",
                inputForm: {},
                //输入框
                isClearable:true,      // 可清空（可选）
                isAccordion:true,      // 可收起（可选）
                props:{                // 配置项（必选）
                    value: 'id',
                    label: 'title',
                    children: 'children',
                    disabled:true
                },
                list: [],
                value: true,
            }
        },
        components: {
          Treeselect,
        },
        methods: {
            init(method, row) {
                this.method = method;
                this.inputForm.id = row.id;
                if (method === "add") {
                  this.title = `新建菜单`;
                  this.inputForm={}
                } else if (method === "edit") {
                  this.title = "修改菜单";
                  this.inputForm = row
                  //解决unknown问题
                  if (this.inputForm.pid===""||this.inputForm.pid===undefined){
                    this.inputForm.pid = null
                  }
                } else if (method === "view") {
                    this.title = "查看详细";
                  this.inputForm = row
                }
                this.dialogVisible = true;
                this.showDept();
            },
            showDept(){
              getDeptTreeAll().then(resp=>{
                this.list = resp.data
              })
            },
            // 取值
            getValue(value){
                this.inputForm.pid = value
            },
            save(){
                if (this.method=='add'){
                  submitDept(this.inputForm).then(resp=>{
                    if (resp.success){
                      this.$message({
                        message: resp.msg,
                        type: 'success'
                      });
                      this.dialogVisible = false
                      this.$emit('refreshDataList')
                    }else {
                      this.$message.error(resp.msg);
                    }
                  })
                }else {
                  updateDept(this.inputForm).then(resp=>{
                    if (resp.success){
                      this.$message({
                        message: resp.msg,
                        type: 'success'
                      });
                      this.dialogVisible = false
                      this.$emit('refreshDataList')
                    }else {
                      this.$message.error(resp.msg);
                    }
                  })
                }
            },
          selectNode(node){
            this.inputForm.pId=node.id
            this.inputForm.pName=node.name
          },
          normalizer(node) {

            if (node.children && !node.children.length) {
              delete node.children;
            }
            return {
              id: node.id,
              label: node.name,
              children: node.children,
            };
          },
        }
    }
</script>

<style scoped>

</style>