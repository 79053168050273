<template>
  <div>
    <el-dialog :title="title" :visible.sync="dialogVisible" width="800px" append-to-body>
      <el-form :model="inputForm" :rules="rules" ref="inputForm" :disabled="method === 'view'"  label-width="140px">
        <el-row>
          <el-col :span="12">
            <el-form-item label="产品名称" prop="productName">
              <el-input v-model="inputForm.productName" placeholder="请输入产品名称" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="产品编码" prop="productCode">
              <el-input v-model="inputForm.productCode" placeholder="产品编码" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="渠道编码" prop="channelNo">
              <el-input v-model="inputForm.channelNo" placeholder="渠道编码(需要我方分配)" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="固定参数" prop="methodInfo">
              <el-input v-model="inputForm.methodInfo" placeholder="固定参数" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="apiInfo产品码表" prop="apiInfo">
              <el-input v-model="inputForm.apiInfo" placeholder="产品码表" />
            </el-form-item>
          </el-col>

        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm">确 定</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { insertProductList, updateProductList} from "@/api/yd/fusion";

export default {
  name: "CompanyForm",
  data(){
    return {
      //菜单头
      title: "",
      //弹出框显示与隐藏
      dialogVisible: false,
      method: "",
      inputForm: {},
      list: [],
      rules: {},
    }
  },
  methods:{
    init(method, row) {
      this.method = method;
      this.dialogVisible = true;
      if (method === "add") {
        this.inputForm={}
        this.title = `新建主体`;
      } else if (method === "edit") {
        this.title = "修改主体";
        this.inputForm = row
      }
    },
    submitForm: function() {
      this.$refs['inputForm'].validate((valid) => {
        if (valid){
          if (this.method=='add') {
            insertProductList(this.inputForm).then(resp => {
              this.cancel()
              if (resp.success) {
                this.$message({
                  message: resp.msg,
                  type: 'success'
                });
                this.$emit('refreshDataList')
              } else {
                this.$message.error(resp.msg);
              }
            })
          }else {
            updateProductList(this.inputForm).then(resp => {
              this.cancel()
              if (resp.success) {
                this.$message({
                  message: resp.msg,
                  type: 'success'
                });
                this.$emit('refreshDataList')
              } else {
                this.$message.error(resp.msg);
              }
            })
          }
        }
      })
    },
    // 取消按钮
    cancel() {
      this.dialogVisible = false;
    },
  }
}
</script>

<style scoped>

</style>