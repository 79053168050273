<template>
    <div>
        <el-dialog  :title="title" :visible.sync="dialogVisible">
            <el-table
                v-loading="loading"
                    :data="tableData"
                    ref="multipleTable"
                    style="width: 100%"
                    :row-key="getRowKey"
                    @selection-change="handleSelectionChange">
                <el-table-column
                        type="selection"
                        width="55">
                </el-table-column>
                <el-table-column
                        prop="name"
                        label="角色名称">
                </el-table-column>
                <el-table-column
                        prop="description"
                        label="描述">
                </el-table-column>
                <el-table-column
                        prop="status"
                        label="状态">
                    <template slot-scope="tableData">
                        <el-switch
                                v-model="tableData.row.status"
                                disabled="disabled"
                                active-color="#13ce66"
                                inactive-color="#ff4949">
                        </el-switch>
                    </template>
                </el-table-column>
            </el-table>
            <div class="block">
                <el-pagination
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :current-page="pageNo"
                        :page-sizes="[5, 10, 15, 20]"
                        :page-size="pageSize"
                        :total="total"
                        background
                        layout="total, sizes, prev, pager, next, jumper">
                </el-pagination>
            </div>
            <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="save">确 定</el-button>
        </span>
        </el-dialog>
    </div>
</template>

<script>
import {getRoleList, getUserByRoles, saveUserRole} from "@/api/system/role";

    export default {
        name: "UserRoleForm",
        data(){
            return {
              loading: true,
                roles: [],
                multipleSelection: [],
                tableData: [],
                //菜单头
                title: "",
                //弹出框显示与隐藏
                dialogVisible: false,
                method: "",
                inputForm: {},
                pageNo: 1,  //前往第几页
                pageSize: 10, //每页几条数据
                total: 0,  //总条数
                orderBy: 'create_time asc',
                selectUserId:[]
            }
        },
        methods:{
            init(id) {
                this.inputForm.userId = id;
                this.dialogVisible = true;
                //清空复选框的选中状态
                this.multipleSelection.splice(0, this.multipleSelection.length)
                //查询角色列表数据
                this.refreshList()
            },
            refreshList(){
                //递归构造菜单列表树
                let params = {
                    'pageNum': this.pageNo,  //从第几行开始查
                    'pageSize': this.pageSize, //查几条数据
                    'orderBy': this.orderBy
                }
              this.loading = true;
              getRoleList(params).then(resp=>{
                //修改滑块的状态
                resp.data.list.forEach(function(data) {
                  if (data.status==1){
                    data.status=true
                  }else {
                    data.status=false
                  }
                  //修改类型
                  if (data.type==1){
                    data.type="目录"
                  }else if(data.type==2){
                    data.type="菜单"
                  }else if(data.type==3){
                    data.type="按钮"
                  }
                })
                this.total = resp.data.total
                this.pageSize = resp.data.pageSize
                this.tableData = resp.data.list
                //调用回显用户拥有的角色
                getUserByRoles(this.inputForm.userId).then(resp=>{
                  this.selectUserId = resp.data.roleIds;
                  if (this.selectUserId.length>0){
                    for (var i = 0; i < this.selectUserId.length; i++) {
                      for (var j = 0; j < this.tableData.length; j++) {
                        if (this.selectUserId[i] === this.tableData[j].id) {//items里面包含有的都回显打勾
                          this.$refs.multipleTable.toggleRowSelection(//回显打勾核心，toggleRowSelection的第一个参数是需要打勾的那一行数据，第二个参数表示是否选中，传true
                              this.tableData[j],
                              true
                          );
                        }
                      }
                    }
                  }
                  this.loading = false;
                })
              })
            },
            getRowKey(row) {
                return row.id;
            },
            //分页用到的方法
            handleSizeChange(val) {
                this.pageSize = val
                //清空复选框的选中状态
                this.multipleSelection.splice(0, this.multipleSelection.length)
                this.refreshList();
            },
            //分页用到的方法
            handleCurrentChange(val) {
                this.pageNo =val
                //清空复选框的选中状态
                this.multipleSelection.splice(0, this.multipleSelection.length)
                this.refreshList();
            },
            //复选框用到的方法
            handleSelectionChange(val) {
                var _this = this
                _this.multipleSelection.splice(0, this.multipleSelection.length)
                val.forEach(function (item) {
                    _this.multipleSelection.push(item.id)
                })
            },
            save(){
              this.inputForm.roleIds = this.multipleSelection
              saveUserRole(this.inputForm).then(resp=>{
                if (resp.success){
                  this.$message({
                    message: resp.msg,
                    type: 'success'
                  });
                  this.dialogVisible = false
                  this.$emit('refreshDataList')
                }
              })
            }
        }

    }
</script>

<style scoped>

</style>