<template>
    <div>
        <el-dialog :title="title" :visible.sync="designerOpen" append-to-body fullscreen>
          <process-designer
              :key="designerOpen"
              style="border:1px solid rgba(0, 0, 0, 0.1);"
              ref="modelDesigner"
              v-loading="designerData.loading"
              :bpmnXml="designerData.bpmnXml"
              :designerForm="designerData.form"
              @save="onSaveDesigner"
          />
        </el-dialog>
    </div>
</template>

<script>
import ProcessDesigner from '@/components/ProcessDesigner';
import {getBpmnXml, saveModel} from "@/api/activityWorkFlow/activity/flow";
    export default {
      name: "DesignForm",
      components: {
        ProcessDesigner,
      },
      data(){
          return {
            title:  "",
            //弹出框显示与隐藏
            designerOpen: false,
            designerData: {
              loading: false,
              bpmnXml: '',
              modelId: null,
              form: {
                processName: null,
                processKey: null
              }
            },
          }
      },
      methods:{
        init(method, row) {
          this.title="流程设计 - " + row.modelName;
          this.designerData.modelId = row.modelId;
          this.designerData.form = {
            processName: row.modelName,
            processKey: row.modelKey
          }
          if (row.modelId) {
            this.designerData.loading = true;
            getBpmnXml({modelId:row.modelId}).then(resp=>{
              this.designerData.loading = false;
              this.designerOpen = true
              this.designerData.bpmnXml = resp.data || '';
            })
          }
        },
        onSaveDesigner(bpmnXml) {
          this.bpmnXml = bpmnXml;
          let dataBody = {
            modelId: this.designerData.modelId,
            bpmnXml: this.bpmnXml
          }
          this.$confirm("是否将此模型保存为新版本？", "提示", {
            distinguishCancelAndClose: true,
            confirmButtonText: '是',
            cancelButtonText: '否'
          }).then(() => {
            this.confirmSave(dataBody, true)
          }).catch(action => {
            if (action === 'cancel') {
              this.confirmSave(dataBody, false)
            }
          })
        },
        confirmSave(body, newVersion) {
          this.designerData.loading = true;
          saveModel(Object.assign(body, {newVersion: newVersion})).then(resp => {
            if (resp.success) {
              this.designerOpen = false;
              this.$emit('refreshList')
            } else {
              this.$message.error(resp.msg);
            }
          }).finally(() => {
            this.designerData.loading = false;
          })
        },
      }
    }
</script>

<style scoped>

</style>