import request from '@/utils/request'

export function getLogList(data) {
    return request({
        url: '/system/log/sysLog/logs',
        method: 'post',
        data: data
    })
}

export function deleteLog(data) {
    return request({
        url: '/system/log/sysLog/deletedLog',
        method: 'post',
        data: data
    })
}