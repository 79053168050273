<template>
  <div class="app-container">
    <el-form :model="queryParams" ref="queryForm" size="small" :inline="true" v-show="showSearch" label-width="68px">
      <el-form-item label="公司名称" prop="name">
        <el-input
            v-model="queryParams.companyName"
            placeholder="请输入公司名称"
            clearable
            style="width: 240px"
            @keyup.enter.native="handleQuery"/>
      </el-form-item>
      <el-form-item style="float: right;">
        <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
        <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
      </el-form-item>
    </el-form>
    <el-row :gutter="10" class="mb8">
      <el-col :span="1.5">
        <el-button
            type="primary"
            plain
            icon="el-icon-plus"
            size="mini"
            @click="addButton"
        >新增</el-button>
      </el-col>
      <el-col :span="1.5">
        <el-button
            type="danger"
            plain
            icon="el-icon-delete"
            size="mini"
            :disabled="multiple"
            @click="deleteButton"
        >删除</el-button>
      </el-col>
      <el-button-group class="pull-right">
        <el-tooltip class="item" effect="dark" content="搜索" placement="top">
          <el-button
              type="default"
              size="small"
              icon="el-icon-search"
              @click="showSearch = !showSearch">
          </el-button>
        </el-tooltip>
        <el-tooltip class="item" effect="dark" content="刷新" placement="top">
          <el-button
              type="default"
              size="small"
              icon="el-icon-refresh"
              @click="refreshList">
          </el-button>
        </el-tooltip>
      </el-button-group>
    </el-row>
    <el-table v-loading="loading" :data="tableData" @selection-change="handleSelectionChange">
      <el-table-column type="selection" align="center" width="55"/>
      <el-table-column label="公司名称" align="center" key="companyName" prop="companyName" />
      <el-table-column label="发送验证码接口地址" align="center" key="smsUrl" prop="smsUrl"  />
      <el-table-column label="检验验证码接口地址" align="center" key="checkCodeUrl" prop="checkCodeUrl"  />
      <el-table-column label="订购的接口地址" align="center" key="orderUrl" prop="orderUrl"  />
      <el-table-column label="请求方式" align="center" key="requestMethod" prop="requestMethod"  />
      <el-table-column label="发送随机码" align="center" key="methodInfoSendCode" prop="methodInfoSendCode"  />
      <el-table-column label="校验随机码" align="center" key="methodInfoCheckCode" prop="methodInfoCheckCode"  />
      <el-table-column label="办理业务" align="center" key="methodInfoOrderCode" prop="methodInfoOrderCode"  />
      <el-table-column label="每日订单量" align="center" key="count" prop="count"  />
      <el-table-column label="最大订单量" align="center" key="maxCount" prop="maxCount"  />
      <el-table-column label="操作" width="200" align="center" class-name="small-padding fixed-width">
        <template slot-scope="scope">
          <el-button size="mini"
                     type="text"
                     icon="el-icon-circle-plus-outline"
                     @click="addProduct(scope.row)"
          >添加产品</el-button>
          <el-button
              size="mini"
              type="text"
              icon="el-icon-edit"
              @click="updateButton(scope.row)"
          >修改</el-button>
          <el-button
              size="mini"
              type="text"
              icon="el-icon-delete"
              @click="deleteButton(scope.row)"
          >删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <pagination
        style="float: right;"
        :total="total"
        :page.sync="queryParams.pageNum"
        :limit.sync="queryParams.pageSize"
        @pagination="refreshList"
    />
    <CompanyForm ref="CompanyForm" @refreshDataList="refreshList"></CompanyForm>
    <AddProductForm ref="AddProductForm" @refreshDataList="refreshList"></AddProductForm>
  </div>
</template>

<script>
  import CompanyForm from "./CompanyForm.vue";
  import AddProductForm from "./AddProductForm.vue";
  import {deleteFusionList, findFusionListByPage} from "@/api/yd/fusion";

  export default {
    name: "BusinessOperators",
    components: {CompanyForm,AddProductForm},
    data(){
      return{
        total: 0,
        // 遮罩层
        loading: true,
        // 非多个禁用
        tableData: [],
        // 显示搜索条件
        showSearch: true,
        // 非多个禁用
        multiple: true,
        multipleSelection: [],
        // 查询参数
        queryParams: {
          pageNum: 1,
          pageSize: 10,
          companyName: undefined
        },
      }
    },
    activated(){
      this.refreshList()
    },
    methods: {
      handleQuery() {
        this.refreshList();
      },
      // 重置按钮操作
      resetQuery() {
        this.queryParams.companyName=undefined
        this.handleQuery();
      },
      refreshList(){
        this.loading = true;
        findFusionListByPage(this.queryParams).then(resp=>{
          this.tableData = resp.data.list;
          this.total = resp.data.total;
          this.loading = false;
        })
      },
      addButton(){
        this.$refs.CompanyForm.init('add',null)
      },
      updateButton(row){
        this.$refs.CompanyForm.init('edit',JSON.parse(JSON.stringify(row)))
      },
      addProduct(row){
        this.$refs.AddProductForm.init('add',JSON.parse(JSON.stringify(row)))
      },
      handleSelectionChange(val) {
        this.multipleSelection = val.map(item => item.id);
        this.multiple = !val.length
      },
      deleteButton(row){
        let ids = []
        if (row.id!=undefined && row.id!=''){
          ids.push(row.id)
        }else {
          ids=this.multipleSelection
        }
        deleteFusionList(ids).then(resp=>{
          if (resp.success){
            this.$message({
              message: resp.msg,
              type: 'success'
            });
            this.refreshList()
          }
        })
      },
    },
    }
</script>

<style>

</style>