import request from '@/utils/request'

export function findBusinessOperatorsByPage(data) {
    return request({
        url: '/yd/business/operators/findListByPage',
        method: 'post',
        data: data
    })
}

export function insertBusinessOperatorsByPage(data) {
    return request({
        url: '/yd/business/operators/create',
        method: 'post',
        data: data
    })
}
export function updateBusinessOperatorsByPage(data) {
    return request({
        url: '/yd/business/operators/update',
        method: 'post',
        data: data
    })
}
export function findListAll(params) {
    return request({
        url: '/yd/business/operators/findListAll',
        method: 'get',
        params: params
    })
}

