<template>
  <el-drawer ref="drawerRefName" :title="title" :visible.sync="drawer" direction="rtl" size="40%">
    <el-table  :row-key="(row) => { return row.id }" ref="tableData"  v-loading="loading" :data="tableData">
      <el-table-column :reserve-selection="true" type="selection" width="50" align="center" />
<!--      <el-table-column label="铃音名称" align="center" key="ringName" prop="ringName" />-->
      <el-table-column label="铃音类型" :formatter="resultCode" align="center" key="ringType" prop="ringType"/>
      <el-table-column label="铃音id" align="center" key="ringId" prop="ringId"/>
    </el-table>
    <pagination
        style="float: right;"
        :total="total"
        :page.sync="queryParams.pageNum"
        :limit.sync="queryParams.pageSize"
        @pagination="showView"
    />
  </el-drawer>
</template>

<script>
import {ringtoneSettingsView} from "@/api/business/ssjy";

export default {
    name: "RingtoneDrawer",
    data(){
      return {
        tableData: [],
        ringIds: [],
        drawer: false,
        title:"",
        total: 0,
        // 遮罩层
        loading: true,
        queryParams: {
          pageNum: 1,
          pageSize: 10,
          ecoperationId:undefined,
        },
      }
    },
    methods:{
      resultCode(row, column, cellValue) {
        const status = row.ringType
        if (status == '1') {
          return '视频'
        } else {
          return '音频'
        }
      },
      init(method, row) {
        this.method = method;
        if (method === "find"){
          this.title = `查看铃音`;
          this.queryParams.ecoperationId=row.id
          this.showView()
        }
        this.drawer = true;
      },
      showView(){
        this.loading = true;
        ringtoneSettingsView(this.queryParams).then(resp=>{
          this.tableData = resp.data.list;
          this.total = resp.data.total;
          this.loading = false;
        })
      },
    }
}
</script>

<style scoped>

</style>