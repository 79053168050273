<template>
  <div class="app-container">
    <el-row :gutter="20">
      <!--部门数据-->
      <el-col :span="4" :xs="24">
        <div class="head-container">
          <el-input
              @keyup.enter.native="getDeptTree"
              v-model="treeQueryParams.departmentName"
              placeholder="请输入部门名称"
              clearable
              size="small"
              prefix-icon="el-icon-search"
              style="margin-bottom: 20px"
          />
        </div>
        <div class="head-container" style = "height:600px;overflow:auto;">
          <el-tree
              v-loading="treeLoading"
              element-loading-spinner="el-icon-loading"
              :data="deptOptions"
              :props="defaultProps"
              :expand-on-click-node="false"
              :filter-node-method="filterNode"
              ref="tree"
              node-key="id"
              default-expand-all
              highlight-current
              @node-click="handleNodeClick"
          />
        </div>
        <el-pagination
            small="true"
            layout="prev, pager, next"
            pager-count="3"
            @current-change="handleCurrentChange"
            :page-size="treeQueryParams.pageSize"
            :total="treeTotal">
        </el-pagination>
      </el-col>
      <!--用户数据-->
      <el-col :span="20" :xs="24">
        <el-row :gutter="10" class="mb8">
          <el-col :span="1.5">
            <el-button
                v-if="hasPermission('sys:link:add')"
                type="primary"
                plain
                icon="el-icon-plus"
                size="mini"
                @click="addButton"
            >新增</el-button>
          </el-col>
        </el-row>
        <el-table v-loading="loading" :data="tableData">
          <el-table-column label="部门id" align="center" key="ecoperationId" prop="ecoperationId" />
          <el-table-column label="链接地址" align="center" key="linkUrl" prop="linkUrl"  />
<!--          <el-table-column label="点击量" align="center" key="hits" prop="hits" />-->
          <el-table-column label="操作" align="center" class-name="small-padding fixed-width">
            <template slot-scope="scope">
              <el-button
                  v-if="hasPermission('sys:link:delete')"
                  size="mini"
                  type="text"
                  icon="el-icon-delete"
                  @click="deleteButton(scope.row)"
              >删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
    <pagination
        style="float: right;"
        :total="total"
        :page.sync="queryParams.pageNum"
        :limit.sync="queryParams.pageSize"
        @pagination="refreshList"
    />
    <LinkFrom ref="LinkFrom" @refreshDataList="refreshList"></LinkFrom>
  </div>
</template>

<script>
import LinkFrom from "./LinkFrom.vue";
import {deleteLink, findLinkListByPage, getDeptTreeRequest} from "@/api/business/ssjy";
  export default {
    name: "Link",
    components: {LinkFrom},
    data(){
      return{
        // 部门名称
        treeLoading: true,
        departmentName: undefined,
        deptOptions: undefined,
        total: 0,
        treeTotal: 0,
        treeTotalValue: true,
        // 遮罩层
        loading: true,
        // 非多个禁用
        tableData: [],
        defaultProps: {
          children: "children",
          label: "label"
        },
        // 查询参数
        queryParams: {
          pageNum: 1,
          pageSize: 10,
          billNums: undefined,
          status: undefined,
          type: undefined,
          ecoperationId: undefined
        },
        treeQueryParams: {
          pageNum: 1,
          pageSize: 30,
          departmentName: ""
        },
      }
    },
    watch: {
      // 根据名称筛选部门树
      departmentName(val) {
        this.$refs.tree.filter(val);
      }
    },
    activated(){
      if (this.hasPermission('sys:link:list')){
        this.reset()
        this.getDeptTree()
        this.refreshList()
      }
    },
    methods: {
      // 节点单击事件
      async handleNodeClick(data) {

        this.queryParams.ecoperationId = data.id;
        //查询用户数据
        await this.refreshList();
      },
      filterNode(value, data) {

        if (!value) return true;
        return data.label.indexOf(value) !== -1;
      },
      reset() {
        this.queryParams={
          pageNum: 1,
          pageSize: 10,
          billNums: undefined,
          status: undefined,
          type: undefined,
          ecoperationId: undefined
        }
      },
      addButton(row){
        this.$refs.LinkFrom.init('add', JSON.parse(JSON.stringify(row)))
      },
      getDeptTree() { // 选择牵头部门事件
        this.treeLoading = true
        getDeptTreeRequest(this.treeQueryParams).then(resp=>{
          this.deptOptions = resp.data.treeSelects;
          this.treeTotal = resp.data.treeTotal;
          this.treeLoading = false
        })
      },
      handleCurrentChange(val) {
        console.log(`当前页: ${val}`);
        this.treeQueryParams= {
          pageNum: val,
          pageSize: 30,
          departmentName: ""
        },
            this.treeLoading = true
        getDeptTreeRequest(this.treeQueryParams).then(resp=>{
          this.deptOptions = resp.data.treeSelects;
          this.treeTotal = resp.data.treeTotal;
          this.treeLoading = false
        })
      },
      deleteButton(row){
        var ids = []
        ids.push(row.id)
        deleteLink('ids').then(resp=>{
          if (resp.success){
            this.$message({
              message: resp.msg,
              type: 'success'
            });
            this.refreshList()
          }
        })
      },
      refreshList(){
        this.loading = true;
        findLinkListByPage(this.queryParams).then(resp=>{
          this.tableData = resp.data.list;
          this.total = resp.data.total;
          this.loading = false;
        })
      },
    },
    }
</script>

<style>
    .homeHeader{
        background: #4ffcff;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 15px;
        box-sizing: border-box;
    }
    .homeHeader .title{
        font-size: 30px;
        font-family: 微软雅黑;
        color: white;
    }
    .homeHeader .userInfo{
        cursor: pointer;
    }
    .el-dropdown-link img{
        width: 48px;
        height: 48px;
        border-radius: 24px;
        margin-left: 8px;
    }
    .homeWelcome{
        text-align: center;
        font-size: 30px;
        font-family: 微软雅黑;
        color: #409eff;
        padding-top: 50px;
    }
    .homeRouterView{
        margin-top: 10px;
    }

</style>