<template>
  <div>
    <el-dialog :title="title" :visible.sync="visible" append-to-body @close="cancel()">
      <el-card v-model="activeName" class="box-card">
        <div slot="header" class="clearfix">
          <span>发起流程</span>
        </div>
        <el-col v-if="formData.fromStart == '1'" :span="18" :offset="3">
          <div class="form-conf">
            <parser :key="new Date().getTime()" :form-conf="formData" @submit="submit" ref="parser" @getData="getData"/>
          </div>
        </el-col>

        <el-col v-if="formData.fromStart == '0'" :span="18" :offset="3">
          <div class="form-conf">
            <component :is="currentComponent" ref="form"></component>
          </div>
        </el-col>
      </el-card>
      <div style="text-align: center">
        <el-button
            type="primary"
            v-show="formData.fromStart == '0'"
            @click="submit"
            v-noMoreClick
            style="margin-top: 20px;">提交</el-button>
      </div>

    </el-dialog>
  </div>
</template>

<script>
import {getProcessForm, startProcess} from "@/api/activityWorkFlow/createProcess/createProcess";
import Parser from '@/utils/generator/parser'
export default {
  name: "CreateProcessForm",
  components: {Parser},
  data() {
    return {
      activeName: "first",
      currentComponent: "",
      //菜单头
      title: "",
      //弹出框显示与隐藏
      visible: false,
      definitionId: null,
      deployId: null,
      procInsId: null,
      formData: {},
    }
  },
  methods: {
    init(method, row) {
      if (method === "start") {
        this.initData(row)
      }
      if (method === "reStart") {
        this.initReData(row)
      }
    },
    initData(row) {
      this.deployId = row.deploymentId;
      this.definitionId = row.definitionId;
      this.procInsId = row.procInsId;
      getProcessForm({
        definitionId: this.definitionId,
        deployId: this.deployId,
        procInsId: this.procInsId
      }).then(res => {
        if (res.data) {
          this.formData = res.data;
          if ( res.data.fromStart=='0'){
            this.currentComponent = () => import("@/views/"+res.data.formUrl);
          }
          this.visible = true;
        }
      })
    },
    initReData(row) {
      this.deployId = row.deployId;
      this.definitionId = row.procDefId;
      this.procInsId = row.procInsId;
      getProcessForm({
        definitionId: this.definitionId,
        deployId: this.deployId,
        procInsId: this.procInsId
      }).then(res => {
        if (res.data) {
          this.formData = res.data;
          this.visible = true;
        }
      })
    },
    /** 接收子组件传的值 */
    getData(data) {
      if (data) {
        const variables = [];
        data.fields.forEach(item => {
          let variableData = {};
          variableData.label = item.__config__.label
          // 表单值为多个选项时
          if (item.__config__.defaultValue instanceof Array) {
            const array = [];
            item.__config__.defaultValue.forEach(val => {
              array.push(val)
            })
            variableData.val = array;
          } else {
            variableData.val = item.__config__.defaultValue
          }
          variables.push(variableData)
        })
        this.variables = variables;
      }
    },
    submit(data) {
      if (data && this.definitionId) {
        if (this.formData.fromStart == '0') {
          //先保存业务数据，等待业务数据返回业务id进行保存
          //this.$refs.form.saveForm() 调用业务表单中的saveFrom方法，可以调用子组件的方法this.$refs.form.
          this.$refs.form.saveForm((businessId)=>{
            //如果是外置表单
            data.valData = {"businessId":businessId}
            // 启动流程并将表单数据加入流程变量
            startProcess(this.definitionId, JSON.stringify(data.valData)).then(resp => {

              if (resp.success) {
                this.$message({
                  message: resp.msg,
                  type: 'success'
                });
                this.cancel()
                this.$emit('refreshList')
              } else {
                this.$message.error(resp.msg);
              }
            })
          })
        } else {
          //是动态表单则执行else
          // 启动流程并将表单数据加入流程变量
          startProcess(this.definitionId, JSON.stringify(data.valData)).then(resp => {

            if (resp.success) {
              this.$message({
                message: resp.msg,
                type: 'success'
              });
              this.cancel()
              this.$emit('refreshList')
            } else {
              this.$message.error(resp.msg);
            }
          })
        }
      }
    },
    cancel() {
      this.visible = false
    },
  }
}
</script>

<style scoped>
</style>