<template>
  <div class="app-container">
    <el-form :model="queryParams" ref="queryForm" size="small" :inline="true" v-show="showSearch">
      <el-form-item label="部门名称" prop="departmentName">
        <el-input
            v-model="queryParams.departmentName"
            placeholder="请输入部门名称"
            clearable
            @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
        <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
      </el-form-item>
    </el-form>
    <el-row :gutter="10" class="mb8">
      <el-col :span="1.5">
        <el-button
            v-if="hasPermission('sys:departments:add')"
            type="primary"
            plain
            icon="el-icon-plus"
            size="mini"
            @click="addButton"
        >新增集团</el-button>
      </el-col>
      <el-col :span="1.5">
        <el-button
            v-if="hasPermission('sys:departments:add')"
            type="info"
            plain
            icon="el-icon-sort"
            size="mini"
            @click="toggleExpandAll"
        >展开/折叠</el-button>
      </el-col>
    </el-row>
    <el-table
        v-if="refreshTable"
        v-loading="loading"
        :data="tableData"
        row-key="id"
        :default-expand-all="isExpandAll"
        :tree-props="{children: 'children', hasChildren: 'hasChildren'}">
      <el-table-column prop="departmentName" label="部门名称"/>
      <el-table-column prop="orderId" label="集团订购ID"/>
      <el-table-column prop="referencePhone" label="推荐人手机号"/>
      <el-table-column prop="contactPhone" label="客户经理电话"/>
      <el-table-column prop="opType" label="部门状态"/>
      <el-table-column
          prop="videoRingFunc"
          label="彩铃播放功能">
        <template slot-scope="tableData">
          <el-switch
              v-if="hasPermission('sys:departments:start')"
              v-model="tableData.row.videoRingFunc"
              @change="clickStart(tableData.row.id,tableData.row.videoRingFunc)"
              active-color="#13ce66"
              inactive-color="#ff4949">
          </el-switch>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center" class-name="small-padding fixed-width">
        <template slot-scope="scope">
          <el-button
              v-if="hasPermission('sys:departments:ring')"
              size="mini"
              type="text"
              icon="el-icon-edit"
              @click="ringtoneSetting(scope.row)"
          >铃音设置</el-button>
          <el-button
              v-if="hasPermission('sys:departments:view')"
              size="mini"
              type="text"
              icon="el-icon-edit"
              @click="selectRingtoneSetting(scope.row)"
          >查看铃音</el-button>
          <el-button
              v-if="hasPermission('sys:departments:update')"
              size="mini"
              type="text"
              icon="el-icon-edit"
              @click="updateButton(scope.row)"
          >修改</el-button>
          <el-button
              v-if="hasPermission('sys:departments:delete')"
              size="mini"
              type="text"
              icon="el-icon-delete"
              @click="deleteButton(scope.row)"
          >删除</el-button>
        </template>
      </el-table-column>

    </el-table>
    <pagination
        style="float: right;"
        :total="total"
        :page.sync="queryParams.pageNum"
        :limit.sync="queryParams.pageSize"
        @pagination="refreshList"
    />
    <DepartmentsFrom ref="DepartmentsFrom" @refreshDataList="refreshList"></DepartmentsFrom>
    <RingtoneSettingFrom ref="RingtoneSettingFrom" @refreshDataList="refreshList"></RingtoneSettingFrom>
    <RingtoneDrawerViem ref="RingtoneDrawerViem"></RingtoneDrawerViem>
  </div>

</template>

<script>
import RingtoneDrawerViem from "./RingtoneDrawerViem";
import DepartmentsFrom from './DepartmentsFrom'
import RingtoneSettingFrom from './RingtoneSettingFrom'
import {deleteGroup, findGroupList, startOrCloseRingtone} from "@/api/business/ssjy";
export default {

  name: "Departments",
  components: {DepartmentsFrom,RingtoneSettingFrom,RingtoneDrawerViem},
  data() {
    return {
      total: 0,
      isExpandAll: true,
      loading: true,
      refreshTable: true,
      showSearch: true,
      tableData: [],
      multipleSelection: [],
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        departmentName: undefined
      },
    }
  },
  activated(){
    if (this.hasPermission('sys:departments:list')){
      this.refreshList()
    }else {
      this.$message({
        message: "无权访问",
        type: 'error'
      });
    }
  },
  methods: {
    clickStart(id,status){
      if (status==true){
        status=1
      }else {
        status=0
      }
      let parement={
        deptId: id,
        start: status
      }
      startOrCloseRingtone(parement).then(resp=>{
        if (resp.success){
          this.$message({
            message: resp.msg,
            type: 'success'
          });
          this.refreshList()
        }else {
          this.$message.error('修改失败');
        }

      })
    },
    toggleExpandAll() {
      this.refreshTable = false;
      this.isExpandAll = !this.isExpandAll;
      this.$nextTick(() => {
        this.refreshTable = true;
      });
    },
    resetQuery() {
      this.queryParams = {
        departmentName: undefined
      }
      this.refreshList();
    },
    handleQuery() {
      this.refreshList(this.queryParams);
    },

    //新增角色
    addButton(){
      this.$refs.DepartmentsFrom.init('add',null)
    },
    ringtoneSetting(row){
      this.$refs.RingtoneSettingFrom.init('setting', JSON.parse(JSON.stringify(row)))
    },
    selectRingtoneSetting(row){
      this.$refs.RingtoneDrawerViem.init('find', JSON.parse(JSON.stringify(row)))
    },
    updateButton(row){
      this.$refs.DepartmentsFrom.init('edit', JSON.parse(JSON.stringify(row)))
    },
    deleteButton(row){
      if (row.videoRingFunc===false){
        row.videoRingFunc=0
      }else {
        row.videoRingFunc=1
      }
      deleteGroup(row).then(resp=>{
        this.$message({
          message: resp.msg,
          type: 'success'
        });
        this.refreshList()
      })
    },
    refreshList(){
      this.loading = true;
      findGroupList(this.queryParams).then(resp=>{
        resp.data.list.forEach(function(data) {
          //修改类型
          if (data.opType==0){
            data.opType="新建"
          }else if(data.opType==1){
            data.opType="删除"
          }else if(data.opType==2){
            data.opType="名称变更"
          }

          if (data.videoRingFunc===1){
            data.videoRingFunc=true
          }else {
            data.videoRingFunc=false
          }
        })
        this.tableData = this.handleTree(resp.data.list, "id");
        this.total=resp.data.total
        this.loading = false;
      })
    },
  }
}
</script>

<style scoped>

</style>