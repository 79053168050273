<template>
  <div class="app-container">
    <el-form :model="queryParams" ref="queryForm" size="small" :inline="true" v-show="showSearch" label-width="100px">
      <el-form-item label="公司名称" prop="corporateName">
        <el-input
            v-model="queryParams.corporateName"
            placeholder="请输入公司名称"
            clearable
            style="width: 240px"
            @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item style="float: right;">
        <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
        <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
      </el-form-item>
    </el-form>
    <el-row :gutter="10" class="mb8">
      <el-col :span="1.5">
        <el-button
            v-if="hasPermission('sys:tripartite:add')"
            type="primary"
            plain
            icon="el-icon-plus"
            size="mini"
            @click="addButton"
        >新增</el-button>
      </el-col>
      <el-col :span="1.5">
        <el-button
              v-if="hasPermission('sys:tripartite:delete')"
            type="danger"
            plain
            icon="el-icon-delete"
            size="mini"
            @click="handleDelete"
        >删除</el-button>
      </el-col>
    </el-row>
    <el-table v-loading="loading" :data="tableData" @selection-change="handleSelectionChange">
      <el-table-column type="selection" width="50" align="center" />
      <el-table-column label="公司id" align="center" key="id" prop="id" />
      <el-table-column label="公司名称" align="center" key="corporateName" prop="corporateName" />
      <el-table-column label="公司联系电话" align="center" key="corporatePhone" prop="corporatePhone" />
      <el-table-column label="回调地址" align="center" key="callbackUrl" prop="callbackUrl" />
      <el-table-column label="操作" align="center" class-name="small-padding fixed-width">
        <template slot-scope="scope">
          <el-button
              v-if="hasPermission('sys:tripartite:update')"
              size="mini"
              type="text"
              icon="el-icon-edit"
              @click="handleModify(scope.row)"
          >修改</el-button>
          <el-button
              v-if="hasPermission('sys:tripartite:view')"
              size="mini"
              type="text"
              icon="el-icon-edit"
              @click="selectUser(scope.row)"
          >查看用户</el-button>
        </template>
      </el-table-column>
    </el-table>
    <pagination
        style="float: right;"
        :total="total"
        :page.sync="queryParams.pageNum"
        :limit.sync="queryParams.pageSize"
        @pagination="refreshList"
    />
    <TripartiteCompanyForm ref="TripartiteCompanyForm" @refreshDataList="refreshList"></TripartiteCompanyForm>
    <TripartiteCompanyDrawerViem ref="TripartiteCompanyDrawerViem" @refreshDataList="refreshList"></TripartiteCompanyDrawerViem>
  </div>
</template>

<script>
  import TripartiteCompanyForm from './TripartiteCompanyForm'
  import TripartiteCompanyDrawerViem from './TripartiteCompanyDrawerViem'
  import {deleteCorporate, findCorporateList} from "@/api/business/ssjy";
  export default {
    name: "GroupUser",
    components: {
      TripartiteCompanyForm,
      TripartiteCompanyDrawerViem
    },
    data(){
      return{
        total: 0,
        // 选中数组
        ids: [],
        // 遮罩层
        loading: true,
        // 非多个禁用
        tableData: [],
        // 显示搜索条件
        showSearch: true,
        // 查询参数
        queryParams: {
          pageNum: 1,
          pageSize: 10,
          corporateName: undefined
        },
      }
    },
    activated(){
      if (this.hasPermission('sys:tripartite:list')){
        this.reset()
        this.refreshList()
      }
    },
    methods: {
      addButton(){
        this.$refs.TripartiteCompanyForm.init('add',null)
      },
      selectUser(row){
        this.$refs.TripartiteCompanyDrawerViem.init('find', JSON.parse(JSON.stringify(row)))
      },
      handleModify(row){
        this.$refs.TripartiteCompanyForm.init('edit', JSON.parse(JSON.stringify(row)))
      },
      // 多选框选中数据
      handleSelectionChange(selection) {
        this.ids = selection.map(item => item.id);
      },
      handleDelete(){
        deleteCorporate(this.ids).then(resp=>{
          if (resp.success){
            this.$message({
              message: resp.msg,
              type: 'success'
            });
            this.refreshList()
          }else{
            this.$message.error(resp.msg);
          }
        })
      },
      reset() {
        this.queryParams={
          pageNum: 1,
          pageSize: 10,
          corporateName: undefined
        }
      },
      handleQuery() {
        this.refreshList();
      },
      resetQuery() {
        this.reset()
        this.refreshList();
      },
      refreshList(){
        this.loading = true;
        findCorporateList(this.queryParams).then(resp=>{
          this.tableData = resp.data.list;
          this.total = resp.data.total;
          this.loading = false;
        })
      },
    },
    }
</script>

<style>
    .homeHeader{
        background: #4ffcff;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 15px;
        box-sizing: border-box;
    }
    .homeHeader .title{
        font-size: 30px;
        font-family: 微软雅黑;
        color: white;
    }
    .homeHeader .userInfo{
        cursor: pointer;
    }
    .el-dropdown-link img{
        width: 48px;
        height: 48px;
        border-radius: 24px;
        margin-left: 8px;
    }
    .homeWelcome{
        text-align: center;
        font-size: 30px;
        font-family: 微软雅黑;
        color: #409eff;
        padding-top: 50px;
    }
    .homeRouterView{
        margin-top: 10px;
    }

</style>