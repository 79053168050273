import request from '@/utils/request'

// 我待办的流程
export function listTodoProcess(query) {
    return request({
        url: '/activity/workflow/process/todoList',
        method: 'get',
        params: query
    })
}

export function delegate(data) {
    return request({
        url: '/activity/workflow/task/delegate',
        method: 'post',
        data: data
    })
}

// 转办任务
export function transfer(data) {
    return request({
        url: '/activity/workflow/task/transfer',
        method: 'post',
        data: data
    })
}

// 拒绝任务
export function rejectTask(data) {
    return request({
        url: '/activity/workflow/task/reject',
        method: 'post',
        data: data
    })
}

// 可退回任务列表
export function returnList(data) {
    return request({
        url: '/activity/workflow/task/returnList',
        method: 'post',
        data: data
    })
}


export function complete(data) {
    return request({
        url: '/activity/workflow/task/complete',
        method: 'post',
        data: data
    })
}

// 退回任务
export function returnTask(data) {
    return request({
        url: '/activity/workflow/task/return',
        method: 'post',
        data: data
    })
}
