<template>
    <div>
        <el-dialog :title="title" :visible.sync="dialogVisible">
            <el-form :model="inputForm" :rules="rules" ref="inputForm" :class="method === 'view' ? 'readonly' : ''" :disabled="method === 'view'" label-width="120px">
                <el-row :gutter="15">
                    <el-col :span="12">
                        <el-form-item prop="username" label="用户名" :rules="[{required: true, message:'用户名不能为空', trigger:'blur'}]">
                            <el-input v-model="inputForm.username" maxlength="50" placeholder=""></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="手机号" prop="phone" :rules="[{required: true, message:'手机号不能为空', trigger:'blur'}]">
                            <el-input v-model="inputForm.phone" maxlength="50" placeholder=""></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="昵称" prop="nickName" :rules="[{required: true, message:'昵称不能为空', trigger:'blur'}]">
                            <el-input v-model="inputForm.nickName" maxlength="50" placeholder=""></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="真实姓名" prop="realName" :rules="[{required: true, message:'真实姓名不能为空', trigger:'blur'}]">
                            <el-input v-model="inputForm.realName" maxlength="50" placeholder=""></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="邮箱" prop="email" :rules="[{required: true, message:'邮箱不能为空', trigger:'blur'}]">
                            <el-input v-model="inputForm.email" maxlength="50" placeholder=""></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="身份证号" prop="identityNumber" :rules="[{required: true, message:'身份证号不能为空', trigger:'blur'}]">
                            <el-input v-model="inputForm.identityNumber" maxlength="50" placeholder=""></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="密码" prop="password" :rules="[{required: true, message:'密码不能为空', trigger:'blur'}]">
                            <el-input v-model="inputForm.password" maxlength="50" placeholder="" ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="性别" prop="sex">
                            <el-radio v-model="sex" label="1">男</el-radio>
                            <el-radio v-model="sex" label="2">女</el-radio>
                        </el-form-item>
                    </el-col>

                    <el-col :span="12">
                        <el-form-item label="所属公司" prop="deptId" :rules="[{required: true, message:'所属公司不能为空', trigger:'blur'}]">
                          <treeselect
                              v-model="inputForm.deptId"
                              :options="list"
                              selectable: false
                              @select="selectNode"
                              :normalizer="normalizer"
                              placeholder="选择部门"/>
<!--                          <userDeptTree :valuedata="{pId:inputForm.deptId,pidName:inputForm.deptName}"  :props="props" :options="list" :clearable="isClearable" :accordion="isAccordion" @getValue="getValue($event)"/>
                        --></el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="状态:" prop="menuStatus">
                            <el-switch
                                    v-model="menuStatus"
                                    active-color="#13ce66"
                                    inactive-color="#ff4949">
                            </el-switch>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="save">确 定</el-button>
        </span>
        </el-dialog>
    </div>
</template>

<script>

import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import {getDeptTreeAll} from "@/api/system/dept";
import {saveUser, updateUser} from "@/api/system/user";
export default {
  name: "UserForm",
  data(){
    return {
      //菜单头
      title: "",
      //弹出框显示与隐藏
      dialogVisible: false,
      menuStatus: true,
      method: "",
      inputForm: {},
      rules: {},
      sex: '1',
      list: [],
      value: true,
    }
  },
  components: {
    Treeselect
  },
  methods:{
    init(method, row) {
      this.method = method;
      this.reset();
      if (method === "add") {
        this.title = `新建用户`;
      } else if (method === "edit") {
        this.title = "修改用户";
        //修改类型
        if (row.sex=="男"){
          this.sex='1'
        }else if(row.sex=="女"){
          this.sex='2'
        }
        this.inputForm = row
        this.menuStatus = row.status
      } else if (method === "view") {
        this.title = "查看详细";
        //修改类型
        if (row.sex=="男"){
          this.sex='1'
        }else if(row.sex=="女"){
          this.sex='2'
        }
        this.inputForm = row
        this.menuStatus = row.status
      }
      this.dialogVisible = true;
      this.showMenu();
    },
    reset() {
      this.inputForm={};
      this.inputForm.deptName='';
      this.inputForm.password = '123456';
      this.menuStatus=true;
    },
    showMenu(){
      getDeptTreeAll().then(resp=>{
        this.list = resp.data
      })
    },
    save(){
        if (this.menuStatus!=true ){
            this.inputForm.status=2
        } else {
            this.inputForm.status=1
        }
        this.inputForm.sex = this.sex
        this.$refs['inputForm'].validate((valid) => {
          if (valid){
             if (this.menuStatus!=true ){
                 this.inputForm.status=2
             } else {
                 this.inputForm.status=1
             }
             this.inputForm.sex = this.sex
              if (this.method=='add'){
                saveUser(this.inputForm).then(resp=>{
                  if (resp.success){
                    this.dialogVisible = false
                    this.$message({
                      message: resp.msg,
                      type: 'success'
                    });
                    this.$emit('refreshDataList')
                  }else {
                    this.$message.error(resp.msg);
                  }
                })
              }else {
                //修改注册来源
                if(this.inputForm.createWhere === "Web"){
                  this.inputForm.createWhere = 1;
                }
                if(this.inputForm.createWhere ==="Android"){
                  this.inputForm.createWhere = 2;
                }
                if(this.inputForm.createWhere === "Ios"){
                  this.inputForm.createWhere = 3;
                }
                this.inputForm.createTime=undefined
                this.inputForm.updateTime=undefined
                updateUser(this.inputForm).then(resp=>{
                  this.dialogVisible = false
                  if (resp.success){
                    this.$message({
                      message: resp.msg,
                      type: 'success'
                    });
                    this.$emit('refreshDataList')
                  }else {
                    this.$message.error(resp.msg);
                  }
                })
              }

          }
        })

    },
    selectNode(node){
      this.inputForm.deptId=node.id
      this.inputForm.deptName=node.name
    },
    normalizer(node) {
      if (node.children && !node.children.length) {
        delete node.children;
      }
      return {
        id: node.id,
        label: node.name,
        children: node.children,
      };
    },

  }
}
</script>

<style scoped>

</style>