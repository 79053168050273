import request from '@/utils/request'


// 查询流程抄送列表
export function listCopyProcess(query) {
    return request({
        url: '/activity/workflow/process/copyList',
        method: 'get',
        params: query
    })
}
