<template>
  <div class="app-container">
    <el-form :model="queryParams" ref="queryForm" size="small" :inline="true" v-show="showSearch" label-width="68px">
      <el-form-item style="margin: 0 40px" label="手机号" prop="phoneNumber">
        <el-input
            v-model="queryParams.phoneNumber"
            placeholder="请输入订购手机号"
            clearable
            style="width: 240px"
            @keyup.enter.native="handleQuery"/>
      </el-form-item>
      <el-form-item style="margin: 0 40px" label="用户状态" prop="status">
        <el-select v-model="queryParams.status">
          <el-option label="新建状态" value="0" />
          <el-option label="订购成功" value="1" />
          <el-option label="订购失败" value="2" />
        </el-select>
      </el-form-item>
      <el-form-item style="margin: 0 40px" label="三方公司" prop="companyId">
        <el-select v-model="queryParams.companyId" placeholder="请选择公司">
          <el-option v-for="item in options" :key="item.id" :label="item.companyName" :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item style="float: right;">
        <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
        <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
      </el-form-item>
    </el-form>
    <el-table v-loading="loading" :data="tableData">
      <el-table-column label="订购手机号" align="center" key="phoneNumber" prop="phoneNumber"/>
      <el-table-column label="订购渠道" :formatter="fomatMethod" align="center" key="orderMethod" prop="orderMethod"/>
      <el-table-column label="订购状态" :formatter="fomatStatus" align="center" key="status" prop="status"/>
      <el-table-column label="接口返回码" align="center" key="returnCode" prop="returnCode"/>
      <el-table-column label="二次确认Url" align="center" key="url" prop="url" :show-overflow-tooltip="true"/>
    </el-table>
    <pagination
        style="float: right;"
        :total="total"
        :page.sync="queryParams.pageNum"
        :limit.sync="queryParams.pageSize"
        @pagination="refreshList"
    />
  </div>
</template>

<script>
import {findLtCompanyAll, findLtOrderListByPage} from "@/api/lt/lt";

export default {
  name: "OrderList",
  components: {},
  data() {
    return {
      total: 0,
      // 选中数组
      ids: [],
      // 非多个禁用
      multiple: true,
      options: [],
      tableData: [],
      // 显示搜索条件
      showSearch: true,
      loading: true,
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        phoneNumber: undefined,
        status: undefined,
        companyId: undefined,
      },
    }
  },
  activated() {
    //查询公司下拉数据
    this.findCorporate();
    this.refreshList();
  },
  methods: {
    fomatMethod(row, column, cellValue) {
      const status = row.orderMethod
      if (status=='01'){
        return 'WEB'
      }else if (status=='02'){
        return '短信'
      }else if (status=='01'){
        return '添加待归档'
      }else if (status=='03'){
        return 'WPA'
      }else if (status=='04'){
        return 'IVR'
      }else if (status=='05'){
        return 'APP'
      }
    },
    fomatStatus(row, column, cellValue){
      const status = row.status
      if (status=='0'){
        return '新建状态'
      }else if (status=='1'){
        return '订购成功'
      }else if (status=='2'){
        return '订购失败'
      }
    },
    handleQuery() {
      this.refreshList();
    },
    // 重置按钮操作
    resetQuery() {
      this.queryParams.phoneNumber=undefined;
      this.queryParams.status=undefined;
      this.queryParams.companyId=undefined;
      this.handleQuery();
    },
    refreshList(){
      this.loading = true;
      findLtOrderListByPage(this.queryParams).then(resp=>{
        this.tableData = resp.data.list;
        this.total = resp.data.total;
        this.loading = false;
      })
    },
    findCorporate() {
      findLtCompanyAll().then(resp=>{

        this.options = resp.data;
      })
    },
  }
}
</script>

<style>

</style>