<template>
  <div class="app-container">
    <el-form :model="queryParams" ref="queryForm" :inline="true" v-show="showSearch" label-width="68px">
      <el-form-item label="表单名称" prop="formName">
        <el-input
            v-model="queryParams.formName"
            placeholder="请输入表单名称"
            clearable
            size="small"
            @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
        <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
      </el-form-item>
    </el-form>
    <el-row :gutter="10" class="mb8">
      <el-col :span="1.5">
        <el-button
            type="primary"
            plain
            icon="el-icon-plus"
            size="mini"
            @click="handleAdd"
        >新增</el-button>
      </el-col>
      <el-col :span="1.5">
        <el-button
            type="danger"
            plain
            icon="el-icon-delete"
            size="mini"
            :disabled="multiple"
            @click="handleDelete"
        >删除</el-button>
      </el-col>
      <el-button-group class="pull-right">
        <el-tooltip class="item" effect="dark" content="搜索" placement="top">
          <el-button
              type="default"
              size="small"
              icon="el-icon-search"
              @click="showSearch = !showSearch">
          </el-button>
        </el-tooltip>
        <el-tooltip class="item" effect="dark" content="刷新" placement="top">
          <el-button
              type="default"
              size="small"
              icon="el-icon-refresh"
              @click="getList">
          </el-button>
        </el-tooltip>
      </el-button-group>
    </el-row>
    <el-table v-loading="loading" :data="formList" @selection-change="handleSelectionChange">
      <el-table-column type="selection" width="55" align="center" />
      <el-table-column label="表单主键" align="center" prop="formId" />
      <el-table-column label="表单名称" align="center" prop="formName" />
      <el-table-column label="备注" align="center" prop="remark" />
      <el-table-column label="操作" align="center" class-name="small-padding fixed-width">
        <template slot-scope="scope">
          <el-button
              size="mini"
              type="text"
              icon="el-icon-view"
              @click="handleDetail(scope.row)"
          >详情</el-button>
          <el-button
              size="mini"
              type="text"
              icon="el-icon-edit"
              @click="handleUpdate(scope.row)"
          >修改</el-button>
          <el-button
              size="mini"
              type="text"
              icon="el-icon-delete"
              @click="handleDelete(scope.row)"
          >删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <pagination
        style="float: right;"
        v-show="total>0"
        :total="total"
        :page.sync="queryParams.pageNum"
        :limit.sync="queryParams.pageSize"
        @pagination="getList"
    />
    <!--表单配置详情-->
    <el-dialog :title="formTitle" :visible.sync="formConfOpen" width="60%" append-to-body>
      <div class="test-form">
        <parser :key="new Date().getTime()"  :form-conf="formConf" />
      </div>
    </el-dialog>
    <MakeForm  ref="formForm" @refreshDataList="getList"></MakeForm>
  </div>
</template>

<script>
import MakeForm from './MakeForm'
import Parser from '@/utils/generator/parser'
import {deleteWorkflow, getFlowList} from "@/api/activityWorkFlow/activity/flow";
export default {
  name: "FormList",
  components: {MakeForm,Parser},
  data() {
    return {
      // 总条数
      total: 0,
      ids: [],
      // 遮罩层
      loading: true,
      single: true,
      // 非多个禁用
      multiple: true,
      // 流程表单表格数据
      formList: [],
      // 显示搜索条件
      showSearch: false,
      formConfOpen: false,
      formTitle: "",
      formConf: {}, // 默认表单数据
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        formName: null,
        content: null,
      },
    }
  },
  activated(){
    this.getList()
  },
  methods: {
    /** 查询流程表单列表 */
    getList() {
      this.loading = true;
      getFlowList(this.queryParams).then(resp=>{
        this.formList = resp.data.list;
        this.total = resp.data.total;
        this.loading = false;
      })
    },
    handleSelectionChange(selection) {
      this.ids = selection.map(item => item.formId)
      this.single = selection.length!==1
      this.multiple = !selection.length
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.pageNum = 1;
      this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.queryParams.pageNum=1;
      this.queryParams.pageSize=10;
      this.queryParams.formName=null;
      this.queryParams.content=null;
      this.handleQuery();
    },
    /** 新增按钮操作 */
    handleAdd() {
      this.$refs.formForm.init('add', '')
    },
    handleDetail(row){
      this.formConfOpen = true;
      this.formTitle = "流程表单配置详细";
      this.formConf = JSON.parse(row.content)
    },
    handleUpdate(row) {
      this.$refs.formForm.init('edit', JSON.parse(JSON.stringify(row)))
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      let formIds = []
      if (row.formId!=undefined && row.formId!=''){
        formIds.push(row.formId)
      }
      if (this.ids.length>0){
        formIds =this.ids;
      }
      deleteWorkflow(formIds).then(resp=>{
        if (resp.success){
          this.$message({
            message: resp.msg,
            type: 'success'
          });
          this.getList()
        }
      })
    },

  }
}
</script>

<style lang="scss" scoped>
.test-form {
  margin: 15px auto;
  width: 800px;
  padding: 15px;
}
</style>