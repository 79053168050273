<template>
  <div>
    <div class="content">
      <div title="默认查询当天的订购量" class="content-title">订购量统计</div>
      <div >
        <el-date-picker v-model="queryParams.dateList" style="left:130px;position: absolute;top: 20px;width: 240px;height: 32px" value-format="yyyy-MM-dd" type="daterange" range-separator="-" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
        <el-button style="position: absolute;top: 70px;left: 20px" type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
        <el-button style="position: absolute;top: 70px;left: 390px"  icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
        <div id="myChart" style="width: 100%;height: 80%"></div>
      </div>
    </div>
    <div class="content-vertical">
      <div title="默认查询当天往前一天的每个地区的退订率,也可以查看当天往前几天的退订率" class="content-title">退订率统计</div>
      <div >
        <el-input v-model="number" style="position: absolute;top: 20px;left: 220px;width: 100px" placeholder="前几天" size="mini"></el-input>
        <el-select size="mini" style="position: absolute;top: 20px;left: 20px" v-model="corporateId" placeholder="请选择公司">
          <el-option v-for="item in options" :key="item.id" :label="item.corporateName" :value="item.id">
          </el-option>
        </el-select>
        <el-button style="position: absolute;top: 20px;left: 340px" type="primary" icon="el-icon-search" size="mini" @click="handle">搜索</el-button>
        <el-button style="position: absolute;top: 20px;left: 420px"  icon="el-icon-refresh" size="mini" @click="reset">重置</el-button>
        <div id="myChartVertical" style="width: 100%;height: 85%;"></div>
      </div>
    </div>
  </div>

</template>

<script>
import {findCorporate, findCount, findUnsubscribeRate} from "@/api/business/ssjy";
import * as echarts from 'echarts';

  export default {
    name: 'statistics',
    data () {
      return {
        series: [],
        number: '',
        corporateId: '',
        options: [],
        tableData: [],
        tableVertical:[],
        title:['product'],
        list: [],
        loading: true,
        queryParams: {
          dateList: []
        },
      }
    },
    activated(){
      this.refreshList();
      this.refresUnsubscribeRate();
      //查询公司下拉数据
      this.findCorporate();
    },
    methods: {
      refreshList() {
        this.tableData = [];
        this.series=[];
        this.title=['product'],
        this.list=[];
        this.loading = true;
        findCount(this.queryParams).then(resp=>{
          debugger
          this.tableData = resp.data;
          this.tableData.forEach(item=>{
            this.title.push(item.name);
            this.series.push({
              type: 'bar',
              barWidth: 30, // 设置柱子宽度为20
              barGap: '20%', // 设置相邻柱子间距为两柱子宽度的50%
            })
          })

          this.list=this.convertData(this.tableData);
          this.drawLine();
        })
      },
      handleQuery() {
        this.refreshList()
      },
      handle() {
        this.refresUnsubscribeRate();
      },
      resetQuery() {
        this.queryParams.dateList=undefined;
        this.refreshList()
      },
      reset() {
        this.corporateId=undefined
        this.number=undefined
        this.refresUnsubscribeRate();
      },
      convertData(rawData) {
        const products = ['总量', '成功的', '失败的', '未处理'];
        const companies = rawData.map(item => item.name);
        const result = [];

        products.forEach(product => {
          const row = {};
          row['product'] = product;

          companies.forEach(company => {
            let value = 0;

            switch (product) {
              case '总量':
                value = rawData.find(item => item.name === company).map.count;
                break;
              case '成功的':
                value = rawData.find(item => item.name === company).map.secuss;
                break;
              case '失败的':
                value = rawData.find(item => item.name === company).map.fail;
                break;
              case '未处理':
                value = rawData.find(item => item.name === company).map.unprocessed;
                break;
              default:
                break;
            }

            row[company] = value;
          });

          result.push(row);
        });

        return result;
      },
      drawLine() {
        // 基于准备好的dom，初始化echarts实例
        var myChart = echarts.init(document.getElementById('myChart'))
        // 绘制
        myChart.setOption( {
          legend: {},
          tooltip: {},
          dataset: {
            dimensions: this.title,
            source: this.list,
          },
          xAxis: {
            type: 'category',
          },
          yAxis: {// 调整内边距
            grid: {
              left: '10%', // 或者具体的数值，如 '20px'
            }
          },
          series: this.series
        });
      },

      refresUnsubscribeRate() {
        findUnsubscribeRate({"corporateId":this.corporateId,"number":this.number}).then(resp=>{
          this.tableVertical = resp.data;
          this.drawVertical();
        })
      },

      drawVertical() {
        // 基于准备好的dom，初始化echarts实例
        var myChartVertical = echarts.init(document.getElementById('myChartVertical'))
        // 绘制
        myChartVertical.setOption( {
          tooltip: {
            trigger: 'item'
          },
          legend: {
            bottom: '0%',
            //itemWidth: 15,
            orient: 'horizontal',
            left: 'right'
          },
          series: [
            {
              name: 'Access From',
              type: 'pie',
              radius: '50%',
              data: this.tableVertical,
              label: {
                normal: {
                  show: true,
                  formatter: '{b}: {c}%' // 这里的{b}表示名称，{c}表示数值，%符号通过添加百分号实现
                }
              },
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: 'rgba(0, 0, 0, 0.5)'
                }
              }
            }
          ]
        });
      },
      findCorporate() {
        findCorporate(this.queryParams).then(resp=>{
          this.options = resp.data.data;
        })
      },
    }
  }
</script>

<style scoped lang="less">
#myChart{
  position: absolute;
  top: 120px;
  left: 0px;
}
#myChartVertical{
  position: absolute;
  top: 50px;
  left: 0px;
}
.content{
  display: flex;
  position: absolute;
  top: 150px;
  left: 400px;
  width: 520px;
  height: 400px;
  border: 1px solid #1a70ff; /* 外边框颜色和宽度 */
}
.content-vertical{
  display: flex;
  position: absolute;
  top: 150px;
  left: 1000px;
  width: 520px;
  height: 400px;
  border: 1px solid #1a70ff; /* 外边框颜色和宽度 */
}
.content-title{
  position: absolute;
  top: -40px;
  left: 200px;
  font-size: 26px;
  font-weight: bold;
  color: #1a70ff;
}
</style>
