import request from '@/utils/request'

export function delModel(modelIds) {
  return request({
    url: '/activity/workflow/model/' + modelIds,
    method: 'delete'
  })
}

// 获取流程模型流程图
export function getBpmnXml(modelId) {
  return request({
    url: '/activity/workflow/model/bpmnXml/',
    method: 'get',
    params: {modelId:modelId}
  })
}

export function historyModel(query) {
  return request({
    url: '/activity/workflow/model/historyList',
    method: 'get',
    params: query
  })
}


export function deployModel(params) {
  return request({
    url: '/activity/workflow/model/deploy',
    method: 'post',
    params: params
  })
}

export function latestModel(params) {
  return request({
    url: '/activity/workflow/model/latest',
    method: 'post',
    params: params
  })
}
