import request from '@/utils/request'


export function getElementList(params) {
  return request({
    url: '/activity/workflow/form/list',
    method: 'get',
    params: params
  })
}
export function getWorkFlowList(data) {
  return request({
    url: '/activity/workflow/model/findListByPage',
    method: 'post',
    data: data
  })
}
export function updateWorkFlow(data) {
  return request({
    url: '/activity/workflow/form/update',
    method: 'post',
    data: data
  })
}
export function saveWorkFlow(data) {
  return request({
    url: '/activity/workflow/form/save',
    method: 'post',
    data: data
  })
}

export function updateWork(data) {
  return request({
    url: '/activity/workflow/model/update',
    method: 'post',
    data: data
  })
}
export function saveWork(data) {
  return request({
    url: '/activity/workflow/model/save',
    method: 'post',
    data: data
  })
}
export function getFlowList(data) {
  return request({
    url: '/activity/workflow/form/findListByPage',
    method: 'post',
    data: data
  })
}

export function deleteWorkflow(data) {
  return request({
    url: '/activity/workflow/form/deleteById',
    method: 'post',
    data: data
  })
}


export function getBpmnXml(params) {
  return request({
    url: '/activity/workflow/model/bpmnXml',
    method: 'get',
    params: params
  })
}

export function saveModel(data) {
  return request({
    url: '/activity/workflow/model/workflowSave',
    method: 'post',
    data: data
  })
}


