import request from '@/utils/request'

export function getClientList(data) {
    return request({
        url: '/system/client/oauthClientDetails/findListByPage',
        method: 'post',
        data: data
    })
}

export function deleteClient(data) {
    return request({
        url: '/system/client/oauthClientDetails/deleteById/',
        method: 'post',
        data: data
    })
}

export function saveClient(data) {
    return request({
        url: '/system/client/oauthClientDetails/create',
        method: 'post',
        data: data
    })
}

export function updateClient(data) {
    return request({
        url: '/system/client/oauthClientDetails/update',
        method: 'post',
        data: data
    })
}