<template>
    <div>
        <el-dialog :title="title" :close-on-click-modal="false" :visible.sync="dialogVisible" width="800px" append-to-body>
          <el-form :model="inputForm" :rules="rules" ref="inputForm" label-width="140px">
            <el-row>
              <el-col :span="12">
                <el-form-item label="视频类型" prop="ringType">
                  <el-select v-model="inputForm.ringType"  @clear="selectClear" placeholder="视频类型" clearable>
                    <el-option
                        v-for="dict in typeOptions"
                        :key="dict.value"
                        :label="dict.label"
                        :value="dict.value"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="铃声" prop="adminMsisdn">
                  <el-button @click="ringtoneDrawerSelect" type="primary" style="margin-left: 16px;">选择铃声</el-button>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="12">
                <el-form-item label="播放开始时间" prop="startTime">
                  <el-time-picker
                        v-model="inputForm.startTime"  value-format="HH:mm:ss" placeholder="播放开始时间 HH:mm:ss">
                  </el-time-picker>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="播放结束时间" prop="endTime">
                  <el-time-picker
                      v-model="inputForm.endTime" value-format="HH:mm:ss"  placeholder="播放结束时间 HH:mm:ss ">
                  </el-time-picker>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button type="primary" @click="submitForm">确 定</el-button>
            <el-button @click="cancel">取 消</el-button>
          </div>
        </el-dialog>
      <RingtoneDrawer @childEvent="getChildIds" ref="RingtoneDrawer"></RingtoneDrawer>
    </div>
</template>

<script>
    import RingtoneDrawer from "./RingtoneDrawer";
    import {ringtoneSettings} from "@/api/business/ssjy";

    export default {
      name: "RingtoneSettingFrom",
      computed: {
        ringtoneDrawer() {
          return ringtoneDrawer
        }
      },
      data(){
          return {
            value1: new Date(2016, 9, 10, 18, 40),
            //菜单头
            title: "",
            //弹出框显示与隐藏
            dialogVisible: false,
            method: "",
            inputForm: {},
            rules: {
              ringType:[{ required: true, message: "铃音类型不能为空", trigger: "blur" }],
              startTime:[{ required: true, message: "播放开始时间不能为空", trigger: "blur" }],
              endTime:[{ required: true, message: "播放结束时间不能为空", trigger: "blur" }],
            },
            typeOptions: [{
              value: '1',
              label: '视频'
            },{
              value: '2',
              label: '音频'
            }],
          }
      },
      components: {
        RingtoneDrawer
      },
      methods:{
        getChildIds(data){
          this.inputForm.ringIds=data
        },
        selectClear(){
          this.inputForm.ringType=undefined
        },
        init(method, row) {
            this.reset();
            this.method = method;
            if (method === "setting") {
                this.title = `铃音设置`;
            }
            this.inputForm.ecoperationId=row.id
            this.dialogVisible = true;
        },
        /** 转换部门数据结构 */
        submitForm: function() {
          this.$refs['inputForm'].validate((valid) => {
            if (valid){
              if (undefined===this.inputForm.ringIds||""===this.inputForm.ringIds){
                this.$message({
                  message: "请选择铃音文件",
                  type: 'error'
                });
                return;
              }
              ringtoneSettings(this.inputForm).then(resp => {
                this.cancel()
                if (resp.success) {
                  this.$message({
                    message: resp.msg,
                    type: 'success'
                  });
                  this.$emit('refreshDataList')
                } else {
                  this.$message.error(resp.msg);
                }
              })
            }
          })
        },
        // 取消按钮
        cancel() {
          this.dialogVisible = false;
          this.reset();
        },
        reset() {
          this.inputForm = {};
        },
        ringtoneDrawerSelect(){
          if (this.inputForm.ringType!=undefined){
            this.$refs.RingtoneDrawer.init('select',this.inputForm.ringType,this.inputForm.ecoperationId)
          }else {
            this.$message({
              message: "请选择视频类型",
              type: 'success'
            });
          }

        },
      }
    }
</script>

<style scoped>

</style>