<template>
    <div>
        <el-dialog :title="title" :visible.sync="dialogVisible" width="800px" append-to-body>
          <el-form :model="inputForm" :rules="rules" ref="inputForm" :disabled="method === 'view'"  label-width="140px">
            <el-row>
              <el-col :span="12">
                <el-form-item label="公司名称" prop="companyName">
                  <el-input v-model="inputForm.companyName" placeholder="请输入公司名称" />
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="最大订单量" prop="maxCount">
                  <el-input v-model="inputForm.maxCount" placeholder="请输入最大订单量" />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item label="发送验证码接口地址" prop="smsUrl">
                  <el-input v-model="inputForm.smsUrl" placeholder="请输入发送验证码接口地址" />
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="检验验证码接口地址" prop="checkCodeUrl">
                  <el-input v-model="inputForm.checkCodeUrl" placeholder="请输入检验验证码接口地址" />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item label="订购的接口地址" prop="orderUrl">
                  <el-input v-model="inputForm.orderUrl" placeholder="请输入订购的接口地址" />
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="发送随机码" prop="methodInfoSendCode">
                  <el-input v-model="inputForm.methodInfoSendCode" placeholder="请输入发送随机码" />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item label="校验随机码" prop="methodInfoCheckCode">
                  <el-input v-model="inputForm.methodInfoCheckCode" placeholder="请输入校验随机码" />
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="办理业务随机码" prop="methodInfoOrderCode">
                  <el-input v-model="inputForm.methodInfoOrderCode" placeholder="请输入办理业务随机码" />
                </el-form-item>
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="12">
                <el-form-item label="请求方式" prop="requestMethod">
                  <el-input v-model="inputForm.requestMethod" placeholder="请求方式" />
                </el-form-item>
              </el-col>

            </el-row>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button type="primary" @click="submitForm">确 定</el-button>
            <el-button @click="cancel">取 消</el-button>
          </div>
        </el-dialog>
    </div>
</template>

<script>
    import {insertBusinessList, updateBusinessList} from "@/api/yd/businessList";
    import {insertFusionList, updateFusionList} from "@/api/yd/fusion";

    export default {
        name: "CompanyForm",
        data(){
          return {
              //菜单头
            title: "",
            //弹出框显示与隐藏
            dialogVisible: false,
            method: "",
            inputForm: {},
            list: [],
            rules: {},
          }
        },
        methods:{
          init(method, row) {
            this.method = method;
            this.dialogVisible = true;
            if (method === "add") {
              this.inputForm={}
              this.title = `新建主体`;
            } else if (method === "edit") {
              this.title = "修改主体";
              this.inputForm = row
            }
          },
          submitForm: function() {
            this.$refs['inputForm'].validate((valid) => {
              if (valid){
                if (this.method=='add') {
                  insertFusionList(this.inputForm).then(resp => {
                    this.cancel()
                    if (resp.success) {
                      this.$message({
                        message: resp.msg,
                        type: 'success'
                      });
                      this.$emit('refreshDataList')
                    } else {
                      this.$message.error(resp.msg);
                    }
                  })
                }else {
                  updateFusionList(this.inputForm).then(resp => {
                    this.cancel()
                    if (resp.success) {
                      this.$message({
                        message: resp.msg,
                        type: 'success'
                      });
                      this.$emit('refreshDataList')
                    } else {
                      this.$message.error(resp.msg);
                    }
                  })
                }
              }
            })
          },
          // 取消按钮
          cancel() {
            this.dialogVisible = false;
          },
        }
    }
</script>

<style scoped>

</style>