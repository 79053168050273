import request from '@/utils/request'

export function listFinishedProcess(query) {
    return request({
        url: '/activity/workflow/process/finishedList',
        method: 'get',
        params: query
    })
}


// 撤回任务
export function revokeProcess(data) {
    return request({
        url: '/activity/workflow/task/revokeProcess',
        method: 'post',
        data: data
    })
}
