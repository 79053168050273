<template>
    <div>
        <el-dialog :title="title" :visible.sync="visible" :class="method === 'view' ? 'readonly' : ''" :disabled="method === 'view'" width="30%" append-to-body @close="cancel()">
            <el-form ref="form" :model="form" :rules="rules" label-width="80px">
              <el-form-item label="分类名称" prop="categoryName">
                <el-input v-model="form.categoryName" placeholder="请输入分类名称" />
              </el-form-item>
              <el-form-item label="分类编码" prop="code">
                <el-input v-model="form.code" placeholder="请输入分类编码" />
              </el-form-item>
              <el-form-item label="备注" prop="remark">
                <el-input v-model="form.remark" type="textarea" placeholder="请输入内容" />
              </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
              <el-button type="primary" @click="submitForm">确 定</el-button>
              <el-button @click="cancel()">取 消</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import {addCategory, updateCategory} from "@/api/activityWorkFlow/activity/category";

  export default {
    name: "DesignForm",
    data(){
        return {
          //菜单头
          title: "",
          //弹出框显示与隐藏
          visible: false,
          form: {},
          method: "",
          rules: {
            categoryName: [
              { required: true, message: "分类名称不能为空", trigger: "blur" }
            ],
            code: [
              { required: true, message: "分类编码不能为空", trigger: "blur" }
            ],
            remark: [
              { required: true, message: "内容不能为空", trigger: "change" }
            ],
          },
        }
    },
    methods:{
      init(method, row) {
        this.visible = true;
        this.method = method;
        if (method === "add") {
          this.title = `添加流程分类`;
        } else if (method === "edit") {
          this.form = row;
          this.title = "修改流程分类";
        }
      },
      reset() {
        this.form = {
          modelId: undefined,
          modelKey: undefined,
          modelName: undefined,
          category: undefined,
          description: undefined
        };
      },
      submitForm() {
        this.$refs["form"].validate(valid => {
          if (valid) {
            if (this.form.categoryId !== undefined) {
              updateCategory(this.form).then(response => {
                this.$modal.msgSuccess("修改成功");
                this.cancel()
                this.$emit('refreshDataList')
              })
            } else {
              addCategory(this.form).then(response => {
                this.$modal.msgSuccess("新增成功");
                this.cancel()
                this.$emit('refreshDataList')
              });
            }
          }
        });
      },
      cancel() {
        this.reset();
        // 关闭dialog
        this.visible = false
      },
    }
  }
</script>

<style scoped>

</style>