import request from '@/utils/request'

export function getDeptList(params) {
  return request({
    url: '/system/dept/sysDept/findListByPage',
    method: 'get',
    params: params
  })
}

export function deleteDept(data) {
  return request({
    url: '/system/dept/sysDept/deleteById',
    method: 'post',
    data: data
  })
}
export function getDeptTreeAll(params) {
  return request({
    url: '/system/dept/sysDept/tree',
    method: 'get',
    params: params
  })
}

export function submitDept(data) {
  return request({
    url: '/system/dept/sysDept/create',
    method: 'post',
    data: data
  })
}

export function updateDept(data) {
  return request({
    url: '/system/dept/sysDept/update',
    method: 'post',
    data: data
  })
}

export function getTreeListFlow(params) {
  return request({
    url: '/system/dept/sysDept/treeListFlow',
    method: 'get',
    params: params
  })
}


