<template>
  <div class="app-container">
    <el-dialog :modal-append-to-body="false" :visible.sync="visible" append-to-body @close="cancel()">
      <el-tabs tab-position="top" :value="processed === true ? 'approval' : 'form'">
        <el-tab-pane label="表单信息" name="form">
          <div v-if="formOpen">
            <el-card class="box-card" shadow="never" v-for="(formInfo, index) in processFormList" :key="index">
              <div v-if="formInfo.fromStart == '1'" slot-scope="scope">
                <div slot="header" class="clearfix">
                  <span>{{ formInfo.title }}</span>
                </div>
                <!--流程处理表单模块-->
                <el-col :span="20" :offset="2">
                  <parser :form-conf="formInfo"/>
                </el-col>
              </div>
              <div v-else="formInfo.fromStart == '0'" slot-scope="scope">
                <component :is="dynamicComponent(formInfo.formUrl)" :someProp="formInfo.fields" ref="form"></component>
              </div>
            </el-card>
          </div>
        </el-tab-pane >
        <el-tab-pane label="流转记录" name="record">
          <el-card class="box-card" shadow="never">
            <el-col :span="20" :offset="2">
              <div class="block">
                <el-timeline>
                  <el-timeline-item v-for="(item,index) in historyProcNodeList" :key="index" :icon="setIcon(item.endTime)" :color="setColor(item.endTime)">
                    <p style="font-weight: 700">{{ item.activityName }}</p>
                    <el-card v-if="item.activityType === 'startEvent'" class="box-card" shadow="hover">
                      用户 {{ item.assigneeName }} 在 {{ item.createTime }} 发起流程
                    </el-card>
                    <el-card v-if="item.activityType === 'userTask'" class="box-card" shadow="hover">
                        <div>实际办理: {{ item.assigneeName}}</div>
                        <div>候选办理:{{ item.candidate}}</div>
                        <div>接收时间：{{ item.createTime}}</div>
                        <div>办结时间：{{ item.endTime }}</div>
                        <div>耗时: {{ item.duration}}</div>
                      <div v-if="item.commentList && item.commentList.length > 0">
                        <div v-for="(comment, index) in item.commentList" :key="index">
                          <el-divider content-position="left">
                            <el-tag :type="approveTypeTag(comment.type)" size="mini">{{ commentType(comment.type) }}</el-tag>
                            <el-tag type="info" effect="plain" size="mini">{{ comment.time }}</el-tag>
                          </el-divider>
                          <span>{{ comment.fullMessage }}</span>
                        </div>
                      </div>
                    </el-card>
                    <el-card v-if="item.activityType === 'endEvent'" class="box-card" shadow="hover">
                      {{ item.createTime }} 结束流程
                    </el-card>
                  </el-timeline-item>
                </el-timeline>
              </div>
            </el-col>
          </el-card>
        </el-tab-pane>
        <el-tab-pane label="流程跟踪" name="track">
          <el-card class="box-card" shadow="never">
            <process-viewer :key="`designer-${loadIndex}`" :style="'height:' + height" :xml="xmlData" :finishedInfo="finishedInfo" :allCommentList="historyProcNodeList"/>
          </el-card>
        </el-tab-pane>
      </el-tabs>
    </el-dialog>
  </div>
</template>

<script>
import ProcessViewer from '@/components/ProcessViewer'
import Parser from '@/utils/generator/parser'
import {detailProcess} from "@/api/activityWorkFlow/meProcess/meProcess";

export default {
  name: "CompletedForm",
  components: {Parser,ProcessViewer},
  computed: {
    commentType() {
      return val => {
        switch (val) {
          case '1': return '通过'
          case '2': return '退回'
          case '3': return '驳回'
          case '4': return '委派'
          case '5': return '转办'
          case '6': return '终止'
          case '7': return '撤回'
        }
      }
    },
    approveTypeTag() {
      return val => {
        switch (val) {
          case '1': return 'success'
          case '2': return 'warning'
          case '3': return 'danger'
          case '4': return 'primary'
          case '5': return 'success'
          case '6': return 'danger'
          case '7': return 'info'
        }
      }
    }
  },
  data() {
    return {
      height: document.documentElement.clientHeight - 205 + 'px;',
      //弹出框显示与隐藏
      visible: false,
      activeName: 'form',
      // 模型xml数据
      loadIndex: 0,
      processed: false,
      formOpen: false, // 是否加载流程变量数据
      xmlData: undefined,
      taskFormOpen: false,
      taskFormData: {}, // 流程变量数据
      processFormList: [], // 流程变量数据
      historyProcNodeList: [],
      finishedInfo: {
        finishedSequenceFlowSet: [],
        finishedTaskSet: [],
        unfinishedTaskSet: [],
        rejectedTaskSet: []
      },
      taskForm:{
        comment:"", // 意见内容
        procInsId: "", // 流程实例编号
        taskId: "" ,// 流程任务编号
        copyUserIds: "", // 抄送人Id
        vars: "",
        targetKey:""
      },
    };
  },
  methods: {
    init(method, row, processed) {
      this.visible = true;
      if (method === "view") {
        this.taskForm.procInsId = row.procInsId;
        this.taskForm.taskId  = row.taskId;
        this.processed = processed;
        // 流程任务重获取变量表单
        this.getProcessDetails(row.procInsId, row.taskId);
        this.loadIndex = this.taskForm.procInsId;
      }
    },
    dynamicComponent(componentName) {
      const component = require(`@/views/${componentName}`).default;
      return component;
    },
    getProcessDetails(procInsId, taskId) {
      const params = {procInsId: procInsId, taskId: taskId}
      detailProcess(params).then(res => {
        const data = res.data;
        this.xmlData = data.bpmnXml;
        this.processFormList = data.processFormList;
        this.taskFormOpen = data.existTaskForm;
        if (this.taskFormOpen) {
          this.taskFormData = data.taskFormData;
        }
        this.historyProcNodeList = data.historyProcNodeList;
        this.finishedInfo = data.flowViewer;
        this.formOpen = true
      })
    },
    handleClick(tab, event) {
      console.log(tab, event);
    },
    setIcon(val) {
      if (val) {
        return "el-icon-check";
      } else {
        return "el-icon-time";
      }
    },
    setColor(val) {
      if (val) {
        return "#2bc418";
      } else {
        return "#b3bdbb";
      }
    },
    cancel() {
      this.xmlData = undefined;
      this.processFormList = undefined;
      this.taskFormOpen = undefined;
      this.taskFormData = undefined;
      this.historyProcNodeList = undefined;
      this.finishedInfo = undefined;
      this.formOpen = false
      this.visible = false
    },
  }
};
</script>
