import request from '@/utils/request'

export function getMenuList(params) {
  return request({
    url: '/system/permission/sysPermission/findList',
    method: 'get',
    params: params
  })
}
export function updataStatus(data) {
  return request({
    url: '/system/permission/sysPermission/updataStatus',
    method: 'post',
    data: data
  })
}

export function deleteMenu(id) {
  return request({
    url: '/system/permission/sysPermission/permission/' + id,
    method: 'delete'
  })
}

export function getMenuTree(params) {
  return request({
    url: '/system/permission/sysPermission/tree',
    method: 'get',
    params: params
  })
}

export function createMenu(data) {
  return request({
    url: '/system/permission/sysPermission/create',
    method: 'post',
    data: data
  })
}
