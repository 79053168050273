<template>
    <div>
        <el-dialog :title="title" :visible.sync="dialogVisible" width="800px" append-to-body>
          <el-form :model="inputForm" :rules="rules" ref="inputForm" :disabled="method === 'view'"  label-width="140px">
            <el-row>
              <el-col :span="12">
                <el-form-item label="所属运营商" prop="businessId">
                  <treeselect
                      v-model="inputForm.businessId"
                      :options="list"
                      selectable: false
                      @select="selectNode"
                      :normalizer="normalizer"
                      placeholder="选择部门"/>
                  </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="业务名称" prop="name">
                  <el-input v-model="inputForm.name" placeholder="请输入业务名称" />
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button type="primary" @click="submitForm">确 定</el-button>
            <el-button @click="cancel">取 消</el-button>
          </div>
        </el-dialog>
    </div>
</template>

<script>
    import Treeselect from "@riophae/vue-treeselect";
    import "@riophae/vue-treeselect/dist/vue-treeselect.css";
    import {findListAll} from "@/api/yd/businessOperators";
    import {insertBusinessList, updateBusinessList} from "@/api/yd/businessList";

    export default {
        name: "BusinessListForm",
        data(){
          return {
              //菜单头
            title: "",
            //弹出框显示与隐藏
            dialogVisible: false,
            method: "",
            inputForm: {},
            list: [],
            rules: {
              businessId:[
                { required: true, message: "所属运营商不能为空", trigger: "blur" }
              ],
              name:[
                { required: true, message: "名称不能为空", trigger: "blur" }
              ],
              mobile:[
                { required: true, message: "手机号不能为空", trigger: "blur" }
              ],
            },
          }
        },
        components: {
          Treeselect
        },
        methods:{
          init(method, row) {
            this.method = method;
            this.dialogVisible = true;
            this.showMenu();
            if (method === "add") {
              this.inputForm={}
              this.title = `新建业务列表`;
            } else if (method === "edit") {
              this.title = "修改业务列表";
              this.inputForm = row
            }
          },
          showMenu(){
            findListAll().then(resp=>{
              this.list = resp.data
            })
          },
          selectNode(node){

            this.inputForm.businessId=node.id
            this.inputForm.businessName=node.businessName
          },
          normalizer(node) {

            if (node.children && !node.children.length) {
              delete node.children;
            }
            return {
              id: node.id,
              label: node.businessName,
              children: node.children
            };
          },
          submitForm: function() {
            this.$refs['inputForm'].validate((valid) => {
              if (valid){
                if (this.method=='add') {
                  insertBusinessList(this.inputForm).then(resp => {
                    this.cancel()
                    if (resp.success) {
                      this.$message({
                        message: resp.msg,
                        type: 'success'
                      });
                      this.$emit('refreshDataList')
                    } else {
                      this.$message.error(resp.msg);
                    }
                  })
                }else {
                  updateBusinessList(this.inputForm).then(resp => {
                    this.cancel()
                    if (resp.success) {
                      this.$message({
                        message: resp.msg,
                        type: 'success'
                      });
                      this.$emit('refreshDataList')
                    } else {
                      this.$message.error(resp.msg);
                    }
                  })
                }
              }
            })
          },
          // 取消按钮
          cancel() {
            this.dialogVisible = false;
          },
        }
    }
</script>

<style scoped>

</style>