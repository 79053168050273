import request from '@/utils/request'

export function getRoleList(data) {
  return request({
    url: '/system/role/sysRole/findListByPage',
    method: 'post',
    data: data
  })
}

export function updateStatus(data) {
  return request({
    url: '/system/role/sysRole/updataRoleStatus',
    method: 'post',
    data: data
  })
}

export function deleteRole(id) {
  return request({
    url: '/system/role/sysRole/deleteRole/' + id,
    method: 'delete'
  })
}


export function createRoles(data) {
  return request({
    url: '/system/role/sysRole/create',
    method: 'post',
    data: data
  })
}


export function getMenuTreeAll(params) {
  return request({
    url: '/system/permission/sysPermission/treeAll',
    method: 'get',
    params: params
  })
}

export function submitRolePermission(data) {
  return request({
    url: '/system/role/sysRole/addRolePermission',
    method: 'post',
    data: data
  })
}

export function getUserByRoles(id) {
  return request({
    url: '/system/user/sysUser/getUserByRoles?userId='+id,
    method: 'get'
  })
}

export function saveUserRole(data) {
  return request({
    url: '/system/user/sysUser/roles',
    method: 'post',
    data: data
  })
}
export function findRoleAllList(params) {
  return request({
    url: '/system/role/sysRole/findAllList',
    method: 'get',
    params: params
  })
}



