<template>
    <div>
        <el-row class="top">
            <el-button size="small" type="primary" @click="addButton">添加</el-button>
        </el-row>
        <el-table
                :data="tableData"
                v-loading="loading"
                style="width: 100%;margin-bottom: 20px;"
                row-key="id"
                :tree-props="{children: 'children', hasChildren: 'hasChildren'}">
            <el-table-column
                    prop="name"
                    :show-overflow-tooltip="true"
                    label="菜单名称">
            </el-table-column>
            <el-table-column
                    prop="component"
                    :show-overflow-tooltip="true"
                    label="组件">
            </el-table-column>
            <el-table-column
                    prop="iconCls"
                    :show-overflow-tooltip="true"
                    label="图标">
            </el-table-column>
            <el-table-column
                    prop="url"
                    :show-overflow-tooltip="true"
                    label="url">
            </el-table-column>
            <el-table-column
                    prop="method"
                    label="请求方式">
            </el-table-column>
            <el-table-column
                    prop="type"
                    label="类型">
            </el-table-column>
            <el-table-column
                    prop="pidName"
                    :show-overflow-tooltip="true"
                    label="父级名称">
            </el-table-column>
            <el-table-column
                    prop="orderNum"
                    sortable
                    label="排序">
            </el-table-column>
            <el-table-column
                    prop="perms"
                    :show-overflow-tooltip="true"
                    label="授权标识">
            </el-table-column>
            <el-table-column
                    prop="code"
                    :show-overflow-tooltip="true"
                    label="按钮控制标识">
            </el-table-column>
            <el-table-column
                    prop="createTime"
                    :show-overflow-tooltip="true"
                    label="创建时间">
            </el-table-column>
            <el-table-column
                    prop="status"
                    label="状态">
                <template slot-scope="tableData">
                    <el-switch
                            v-model="tableData.row.status"
                            @change="clickStart(tableData.row.id,tableData.row.status)"
                            active-color="#13ce66"
                            inactive-color="#ff4949">
                    </el-switch>
                </template>
            </el-table-column>
            <el-table-column label="操作"  width="150">
                <template slot-scope="scope">
                    <el-button size="mini" @click="handleEdit(scope.row)">编辑</el-button>
                    <el-button size="mini" type="danger" @click="handleDelete(scope.row)">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
        <!-- 弹窗, 新增 / 修改 -->
        <MenuForm ref="MenuForm" @refreshDataList="refreshList"></MenuForm>
    </div>
</template>

<script>
    import MenuForm from './MenuForm'
    import {deleteMenu, getMenuList, updataStatus} from "@/api/system/menu";
    export default {
        name: "Menu",
        data() {
          return {
            loading: true,
            tableData: []
          }
        },
        activated(){
          if (this.hasPermission('sys:permission:list')) {
            this.refreshList()
          }
        },
        components: {
            MenuForm
        },
        methods:{
            refreshList(){
              this.loading = true;
              //递归构造菜单列表树
              getMenuList().then(resp=>{
                let parent = [];
                var _self = this;
                //修改滑块的状态
                resp.data.forEach(function(data) {
                  //修改类型
                  if (data.type==1){
                    data.type="目录"
                  }else if(data.type==2){
                    data.type="菜单"
                  }else if(data.type==3){
                    data.type="按钮"
                  }
                  if (data.pid==""||data.pid==undefined){
                    data.children = _self.$options.methods.getChildrenss(data.id,resp.data);
                    parent.push(data)
                  }
                })
                this.tableData=parent
                this.loading = false;
              })
            },
            getChildrenss(pid,dataList){
                var _self = this;
                let children = [];
                dataList.forEach(function(data) {
                    if (pid === data.pid){
                        data.children = _self.getChildrenss(data.id,dataList);
                        children.push(data)
                    }
                })
                return children
            },
            //控制滑块的方法
            clickStart(id,status){
              this.loading = true;
                if (status==true){
                    status=1
                }else {
                    status=0
                }
                let parement={
                    id: id,
                    status: status
                }
              updataStatus(parement).then(resp=>{
                if (resp.success){
                  this.refreshList()
                  this.$message({
                    message: resp.msg,
                    type: 'success'
                  });

                }else {
                  this.$message.error('修改失败');
                }
              })
            },
            addButton(){
                this.$refs.MenuForm.init('add', '')
            },
            handleEdit(row){
                this.$refs.MenuForm.init('edit',  JSON.parse(JSON.stringify(row)))
            },
            handleDelete(row){
              deleteMenu(row.id).then(resp=>{
                if (resp.success){
                  this.$message({
                    message: resp.msg,
                    type: 'success'
                  });
                  this.refreshList()
                }
              })
            }
        }
    }
</script>

<style>
.addButton{
    margin:5px 0px 5px 0px;
}
    .top{
        margin: 20px 0px 5px 0px;;
    }
</style>